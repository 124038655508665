import React from 'react';
import { css } from '@emotion/core';
import { colors } from '../../styles/tokens';
import fluid from '../../styles/fluid';

export default ({ children }) => (
  <div
    css={css`
      position: relative;
      height: 100%;
      width: 100%;
      max-width: 30rem;
      padding: 0 ${fluid(2, 3)};
      margin: ${fluid(3, 4)} 0;
    `}
  >
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;
        height: 100%;
        padding: 0 ${fluid(2, 3)};
      `}
    >
      {children}
    </div>
    <svg
      x="0"
      y="0"
      viewBox="0 0 1600 1940.7"
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      <g>
        <path
          style={{ fill: colors.mapFill }}
          d="M694.6,611.9c3.1,4.8,5.8,9,8.8,13.6c-2.2,1.3-4,2.4-5.9,3.5c-0.6,1.1-1.2,2.3-1.9,3.6
		c-0.7-0.1-1.4-0.3-2.2-0.4c-2.9,4.2-2.1,8.1,2.3,10.5c0.8-0.9,1.7-1.9,2.8-3.2c1,1.8,1.7,3.1,2.5,4.6c1,0.4,2.1,0.9,3.3,1.4
		c0.2,1.6,0.4,3,0.6,4.6c6.6,3.2,9.9-1.8,13.4-5.2c1.8,0.4,3.2,0.8,5.1,1.3c-0.9,1.2-1.4,2-2.1,2.6c-2.2,1.8-4.5,3.6-6.9,5
		c-3,1.8-2.1,5.3-3.8,7.6c-0.4,0.5-0.5,1.4-0.9,1.6c-2.2,1.2-2.5,3.5-3.3,5.5c-0.3,0.7-0.7,1.3-1.1,2.1c-0.8,0-1.4,0.1-2,0.1
		c-5.2,0.3-7.8,2.8-8.4,7.9c-0.2,1.6-1,3.2-1.6,5.1c1.6,2.5,3.6,4.8,7,5.6c0.7,0.2,1.5,0.6,2.1,1.2c4.9,4.4,6.1,4.4,11.1,0.3
		c1.3-1,2.7-1.8,4.8-3.2c-0.7,5.4-5.8,8.9-3.3,13.8c-2.5,1.9-4.8,3.5-7,5.4c-2.6,2.3-4.9,4.7-7.4,7.1c-0.9,0.9-1.8,2-2.9,2.4
		c-2.9,0.9-4.4,2.9-5.5,5.9c1.2,2.1,2.5,4.4,3.6,6.3c4.9-1.3,7.8,2.1,11,2.5c2.1,3,3,6.1,6.2,8.1c3,1.8,6.2,3.9,8,7.4
		c0.6,1.1,2,2,3.2,2.6c1.7,0.9,3.5,1.6,5.4,2.4c-2.4,2.7-2.4,3-0.2,6.5c1.4-0.3,3-0.6,5-1c0.6,1.2,1.4,2.5,2.1,3.8
		c1.6,3.2,5.3,4.5,8.5,2.9c1.1-0.6,2.1-1.3,2.2-1.3c6.9,0.5,12.2-1,17.2-3.9c1.5,0,3.1,0.1,4.5-0.5c2.4-1,5-1.4,6.9-3.6
		c1.2-1.4,4-1.2,5.6-2.3c2.1-1.6,3.7-3.8,5-6.2c1.3-2.3,4.1-3.7,6.2-5.5c0.2-0.2,0-0.7,0.2-0.9c0.9-1.1,1.6-2.6,2.8-3.2
		c3.9-2,6-5.6,8.6-8.9c1.2-1.5,2.4-3,3.9-4.2c2.5-2,5.1-3.7,6.8-4.9c3.1-1.3,5.6-1.8,7.3-3.2c2.6-2.2,5.1-4.1,8.6-4.9
		c1.3-0.3,2.4-2,3.4-3.2c1-1.3,1.8-2.8,3.2-5c0-1.7-0.9-4.8,0.2-7.1c1.8-3.8,1.7-7.4,1.6-10c-1.5-4.3-2.5-7.6-3.8-11.4
		c1.7-0.3,3.1-0.5,4.7-0.8c-1.1,3-0.9,5.1-0.1,7.5c1.9,5.7,4.5,11.2,4.2,16.7c2.2,2.9,4,5.2,5.7,7.6c-0.1,0.1-0.2,0.2-0.3,0.2
		c2,1.6,4,3.2,5.3,4.3c3.8,0.8,6.9,1.5,10.4,2.3c0.3-1.4,0.9-2.9,1-4.4c0.3-4.6,0.8-9,6.2-11.4c-0.4-4-0.9-7.9-1.2-11.9
		c-0.1-1.4,0-2.9,0.1-4.3c0.2-1.6,0.7-3.1,0.7-4.7c0.1-5.6,1.2-10.8,5.3-15.2c4.6,0,7.7-2.7,10.2-6.4c0-0.9,0-1.7,0-2.5
		c0.4-6.3-0.4-12.6,0.4-18.9c0.6-4.6-0.2-10.1-4-13.8c-1.9-1.8-2.7-3.9-3.5-6c-1.8-4.5,0.5-8.4,2.4-12.3c0.3-0.6,1.3-1.2,2-1.3
		c5.7-0.6,7.8-4.3,8.6-9.1c-0.5-3.1-2.4-4.6-4.9-5.7c-2.8-1.2-5.4-2.6-8.2-4c-1.8-0.9-2.8-2.3-3.6-4.2c-1.6-3.8-1.1-7.7-0.6-11.4
		c0.5-4.1-0.4-7.8-0.9-11.7c-0.3-2.5-0.8-5.4,0-7.6c1.3-3.4,0.6-6.4-0.8-9.3c-3.8-7.5-2.8-15.5-1.9-23.1c0.8-6.4,0.4-12.4-1.8-18.3
		c-2.3-6.5-2.5-12.4,2.5-17.8c1.1-1.2,1.8-2.7,2.6-3.9c0.4-4.2,0.5-8.5,3.1-11.9c1.5-1.9,3.2-3.6,5-5.3c1.2-1.2,2.4-2.3,3.2-3.9
		c2.5-4.5,5.9-7.3,11.8-7.3c5.2,0.1,10.7-1,15.6-3.9c0.6-2.7-0.7-4.8-1.8-6.9c-1.3-2.5-3.3-4.8-4.2-7.5c-1.4-4-2.9-8.2,0.1-12.3
		c1.9-2.7,3.5-5.7,5.2-8.6c1.2-2.2,2.9-4.2,2.7-7c-0.5-9.1,1-17.8,4.4-26.3c0.4-0.9,0.4-1.9,0.6-2.8c1.7-6.9,0.1-13.6-1-20.4
		c-0.6-3.8-0.9-7.6-1.2-10.4c2.3-4.2,4.6-5.7,8.4-4.4c1.5,0.5,3.4,0.1,5,0.1c0.2-0.6,0.4-0.9,0.4-1.2c-0.7-5.1,0.3-9.2,5.4-11.6
		c1.1-0.5,1.8-1.8,3.1-3.3c0.3-1.2,0.6-3.2,1.2-5c1.9-5.4,3.8-10.8,5.8-16.2c0.9-2.5,2.4-5,2.7-7.6c0.3-2.2,1-4.1,2.3-5.6
		c1.7-2.1,2-4.3,1.8-6.1c-2.1-3.9-3.9-7.3-5.7-10.7c-1.5-2.8-1.7-5.5,0.3-7.7c2.1-0.8,3.8-1.4,5.7-2.1c-0.3-2.8-0.6-5.2-0.9-7.3
		c0.7-2.6,1.4-5,2.2-7.7c2.6-2.1,5.3-4.4,8.2-6.4c1.8-1.2,4.2-1.4,6-2.6c3.8-2.6,7.5-5.5,11.2-8.3c0-1.3,0-2.6,0-3.3
		c-2.5-3.2-4.7-5.9-6.6-8.4c0-2,0-3.6,0-5.2c2.9-3.4,6.8-4.9,10.7-6.4c2.6-1.1,5.7-1.1,8.2-2.3c3.6-1.7,6.8-1.8,10.5-0.1
		c3.9,1.8,8,3.1,12.7,0.5c-0.5-1.6-0.9-3.3-1.7-4.8c-2-4-2-8.1-1.2-12.2c0.5-2.9,1.4-5.8,2.5-10c1.4-0.9,3.8-2.5,6.6-4.3
		c-0.1-0.6-0.4-1.8-0.5-2.6c1.3-2.2,2.3-3.9,3.7-6.2c1.8-1.2,3.9-3.1,6.3-4.2c1.9-0.9,4.5-1.6,6.4-1.2c8.6,2.2,16.2,5.9,18.4,15.7
		c0.9,4,3.4,5.8,7.2,6.5c3.1,0.6,5.9,1.6,8.4,4.1c1.8,1.8,4.5,2.7,7.1,4.2c1.5-2.5,2.8-4.4,3.9-6.5c3.3-5.9,7.8-7.8,14.2-5.6
		c1.6,0.6,3.2,1.5,4.8,2.1c1.2,0.4,2.6,0.5,4.3,0.8c0.6-2.4,1.1-4.2,1.6-6c0.7-2.7,1.7-4.6,4.7-6c3-1.3,6.9-1.8,8.6-5.2
		c-0.8-2.7-1.8-5.1-2.3-7.6c-0.6-3.7-1.8-7.7-1.1-11.3c0.9-4.3,0.6-8.2-0.6-12.2c-0.7-2.6-1.7-5.3-1.5-7.9
		c0.3-5.6,1.6-10.8,8.1-12.6c3.6-1,6.2-3.2,8-6.5c0.5-0.9,1.3-1.8,2.2-2.4c4.4-3.3,13.1-3.3,18.4,0c6.8,0.1,13.7,0.1,17.5,7.4
		c0.7,0.1,1.3,0.2,2,0.4c5.4,1.2,6.7,2.7,6.6,8.2c-0.1,4.2-0.6,8.3-0.8,12.5c-0.1,1.2,0.1,2.5,0.4,3.8c1.2,4.6,2.6,9.2,4.1,14.6
		c0.5-1.7,1-2.7,1-3.7c0.2-2.2,0.3-4.5,0.2-6.8c-0.1-3.7,0.8-6.8,3.7-9.3c3.8-3.5,7.6-7,10.4-11.5c1.6-2.6,3.4-5.2,6.7-6.3
		c1-0.3,2.1-2.2,2.2-3.5c0.5-4.1,1.3-8.5-3.1-11.4c-0.8-0.5-1.3-1.4-2-2.1c-2.5-2.2-5.5-3-8.4-1.3c-6.1,3.7-12.1,2-17.9-0.3
		c-1.8-0.7-3.4-1.3-5.3-1.3c-3-0.1-5.4-1.3-6.9-5c3.7-0.2,7-0.5,10.2-0.7c0.6,0.3,1.2,0.1,1.8-0.2c3.4-1.7,7.9-1.6,10.2-4.7
		c2.5-3.3,5.8-4.9,9.4-5.9c2.7-0.8,4.1-2.1,4.5-4.7c-0.5-1.7-1-3.4-1.4-4.8c-6.5-5.6-12.7-11.2-20.5-10.1c-4.8-1.9-8.3-4.1-11.9-4.5
		c-6.3-0.8-12.8-0.5-19.3-0.6c-0.1-2-0.2-3.9-0.4-6.6c-3-2.1-6.2-4.3-9.5-6.5c-1,0-1.9,0-2.9,0c-0.5,0.6-0.9,1.6-1.5,1.7
		c-5.4,0.9-7.9,4.5-9.3,9.4c-0.6,2.3-1.7,4.4-2.6,6.6c1,2,1.9,4,2.9,6.1c-0.1,0.5,0,1.3-0.3,1.8c-2.5,4.5-4.1,9.3-4.2,14.4
		c0,2.5-1.4,2.7-2.9,3.5c-0.2-2.1-1-4,0.2-6c1.5-2.3,1.4-4.9,1.3-7.5c-0.3-6.8-4.6-10.3-11.9-9.2c-1.7,5.6-2.9,11.8-5.5,17.2
		c-2.7,5.4-3.6,11.5-6.6,16.8c-0.1-2.4-0.2-4.9,0.7-7c1.6-3.7,1.4-7.1-0.7-10.5c3.7-4.6,5.9-11.5,5.2-16.7
		c-4.1-5.1-7.1-5.1-13.6-1.3c-2.6,1.6-3.7,4.2-5.6,6.2c-3.5,3.8-5.3,8.4-7.7,12.8c-1.6,3-4,5.8-6.5,8c-2.2,1.9-5.1,3-7.7,4.4
		c0.1,0.2,0.2,0.4,0.2,0.6c-1.1,0-2.2,0-3.4,0l0.1-0.1c0,0,0,0-0.1,0c0,0,0,0,0,0.1c-3.1,2.4-3,6.2-4.4,9.6c-2.7,0-4.8,1.2-6.8,2.9
		c-1.5,1.3-3.5,2.1-5.2,3.2c-3.5,2.2-8,2.8-10.1,7.2c-1.9,3.8-2.2,3.9,1.2,7c1.6,0.1,3.2,0.2,5.1,0.3c1.5,2,3.1,4,4.7,6.2
		c-4.3,0.2-8,0.4-11.7,0.4c-3.3,0-5.3,1.7-6.6,4.5c-1,2.3-2.1,4.7-3.6,7.8c-0.4-2.6-0.6-4.2-0.8-5.5c-2.7-2.3-5.5-2.4-8.7-1.6
		c-0.8,2.6-1.9,5.3-2.4,8c-0.3,1.8-0.8,3-2.7,3.4c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.5-0.1-1,0.1-1.4c1.3-3.1,1.1-5.5-1.7-8
		c-1.8-1.5-2.5-4.2-4.2-7.2c-1.5-0.3-3.7-0.7-6.3-1.2c-2.1,2.4-5,4.3-5.8,6.8c-1.4,4.3-0.4,8.7,2.7,12.3c-4.5,0.7-8.5,1.4-12.4,2.1
		c-0.8,3.5-2.6,6.4,0.5,9.1c2.9,0.3,6.1-0.3,8.1,3.2c-2.8,1.2-5.4,2.3-7.6,3.2c-4.6-0.6-9-1.2-12.9-1.8c-5.9,5.5-9.9,15.1-9.4,22.5
		c1.2,0.7,2.5,1.4,4.2,2.4c2.4-1.8,4.8-3.5,7.2-5.3c0.5,2.1,0.5,2.1-2.9,9.6c0.6,1.9,1.2,3.8,1.9,5.9c-0.9,1.6-2.1,3.1-2.6,4.8
		c-0.7,3-2.6,5-4.8,6.7c-5.8,4.2-11.1,8.7-12.5,15.3c-2.2,1.9-3.8,3.2-5.2,4.7c-1.6,1.7-2.8,3.8-4.6,5.2c-2.7,2.1-3.9,4.8-4.8,7.9
		c-0.4,1.4-0.7,2.7-1.1,4.1c-0.2-0.1-0.4-0.3-0.6-0.4c-3.2,3.9-6.4,7.8-9.4,11.4c-0.2,8.8,0,9.8,2.5,13.6c-5.5,7.9-7.2,12-7.8,17.6
		c-5.1,2-7.8,6.1-8.3,10.1c-2.9,3-4.8,5.1-6.8,7.1c0,0-0.1-0.1-0.1-0.1c-2.3,2.3-4.5,4.7-6.9,7.1c1.4,4.2-2.6,8.1,0.4,12.6
		c-1.6,1.9-3.3,3.6-4.8,5.6c-2.1,2.9-3.5,6.2-3.1,9.8c0.6,4.2-0.5,7.7-3.3,10.9c-2.6,2.8-3.8,6.2-3.7,10.1c0,1.3,0,2.6-0.1,3.9
		c-0.2,3.2-0.3,6.3-0.5,9.5c0.3,0.1,0.6,0.2,1,0.3c-1.4,1.6-2.9,3.2-5.1,5.6c-0.5,1.6-1.8,4.3-2,7c-0.4,8.2-4.5,14.6-9.2,20.8
		c-0.8,1-1.7,1.9-2.5,2.6c-0.1,1.6-0.2,2.8-0.3,4c-4,1.7-5.3,3.4-6.5,8.6c-0.3,1.4-0.1,3-2.3,3.5c-0.4-0.4-1-0.9-1.6-1.3
		c-4.5-2.5-9.4-2.3-11.8,1.1c-2.1,2.9-4.1,6.1-5.4,9.5c-1,2.6-2.6,4.3-4.6,6c-1.9,1.6-3.9,3-5.4,4.2c0,6.8-1.3,9.8-3.8,13.9
		c-1.9,3.2-2.9,6.6-1.5,10c1.2,2.8,1.3,6.1,4.6,8c1.1,0.6,2.3,2.6,1.4,4.5c-1.1-1.3-2.3-2.6-2.9-3.3c-5.3-0.7-9.9,1-13.8-1.9
		c-0.8-1.8-1.6-4.1-3.1-4.8c-3.2-1.6-6.6-3.3-11-1.8c-1.1,2.7-2.4,5.7-3.7,8.6c0.2,0.1,0.4,0.2,0.5,0.3c-1.2,0.9-2.5,1.9-3.6,2.7
		c0.1,5.1,4.2,2.7,6.4,3.7c-0.4,1.3-0.7,2.3-0.8,2.8c-1.7,1.5-3,2.7-3.9,3.5c-1.6,5.1-3,9.7-4.5,14.5c-1.4-1.2-2.4-2.2-3.2-2.9
		c-4.3,1-3.4,4.4-4.1,7.1c-6.9-4.5-14.3-3.4-21.8-3c-1.1,2.3-2.2,4.4-3,5.9c1.3,5.1,6.4,2.4,8.2,5c-2.7,1-5.4,2-8.8,3.2
		c-3.8-1.4-8-1-11.5,1.7c-1.2,1.9-2.1,3.3-3.1,5.1c1.9,2.2,0.2,4.2-1.6,4.8c-3.8,1.2-5.3,3.7-5.9,7.5c-3.7-1-7.2-1.5-10.3,0.7
		c-1.2,0.9-2.2,2.1-3.5,2.8c-6.8,3.4-10.6,9.2-13.8,15.8c2.1,3.3,0.9,5.8-2,8.2c1.3,2.1,2.5,4.1,3.8,6.3c-1.2,2.6-2.5,5.4-4,8.8
		c0.8,1.2,1.8,2.7,2.9,4.3c0.5,1.9,1.7,3.6,2,5.4c0.4,2-0.1,4.2-0.2,6.5c-2.4-0.9-4.6-0.8-6.5,2c0.3,1.2,0.7,2.9,1.1,4.7
		c-1,0.9-2.4,2.2-3.8,3.5c0.3,1.1,0.6,1.9,0.9,2.9c-1,1.2-2,2.4-2.8,3.3C690.1,612.7,693.6,611,694.6,611.9z M827.8,472.4
		c2.6-0.9,6.1-2,9.5-3.3c0.9-0.4,1.5-1.5,2.4-2.2c1.2-1,2.3-2.3,3.6-3c4.4-2.5,5.8-6.4,5.7-11.2c0-0.8,0.1-1.6,0.2-3
		c1.5-0.3,2.7-0.6,4.6-1.1c-0.7,6.4-3.1,11.8-5.6,16.7c-2.5,4.8-6.3,9.3-11.9,11.3c-2.8,0.4-5.6,1.1-8.5,1.2c-1.4,0.1-2.9-0.6-4.4-1
		C825,475.3,826.2,474.1,827.8,472.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M112.9,1731.1c2-1.4,3.7-3.1,6.2-5.2c-1.8-0.3-2.6-0.3-3.3-0.6c-2.4-0.9-4.8-2.2-5.2-5c-0.4-2.3-0.1-4.7-0.2-7
		c-0.1-2.1,0.1-4.2-0.4-6.2c-1.2-4.8-0.3-9.2,2.3-13.3c3.3-1,6.5-1.9,9.6-2.9c1.1-2.3,2.1-4.4,3.2-6.6c1.1-2.2,2.4-4.4,3.8-7
		c-2.7-1.2-5.6-2.5-8.9-4c-0.6-4.4-1.5-9-1.6-13.6c-0.1-3.1-1-5.9-2.7-8c-2.2-2.7-2.7-5.3-2.1-8.4c2.5-3.3,5-6.6,10-4.9
		c9.4,8.1,17,4.1,19.8-5.6c-3.5-7.2-3.9-12.4,1.3-18.7c2-0.6,4.1-1.2,6.2-1.8c0.2-2.4,0.5-4.6,0.6-6.8c0.1-2.2,0-4.4,0.1-6.5
		c0.1-3.3,0-6.7,0.4-10c0.3-3.4,1.3-6.8,1.6-10.2c0.5-5.6,2.4-10,8.2-12c0.9-0.3,1.7-0.9,2.5-1.4c3.6-2.1,7.1-4.4,10.8-6.1
		c2.8-1.3,5.9-2,9-3c0.3-3.7-2.9-4.4-4.8-5.8c-1.8-1.3-3.3-2.4-3.7-4.7c1.2-2,2.5-4.1,4-6.6c-9,0.3-16-3.7-22.8-8
		c-5.2,2.7-9.2,7.2-15.8,6.7c-3.5-2.3-7.3-4.9-11.1-7.4c-6.1-0.4-13.1,1.5-16.6-6c1-4.4,5.6-8.3,1.4-13c-1.9,0.3-3.8,0.6-5.6,0.9
		c-4.1,1.6-3.7,7.8-9.2,8.4c-2.2-1-4.8-2.2-7.7-3.6c-1.2,1-2.6,2.1-4.1,3.3c0.3,0.2,0.6,0.3,0.9,0.5c-0.2,2.4-0.4,4.9-0.8,7.3
		c-0.5,4.2-1.3,8.5-1.7,12.7c-0.5,4.4-0.8,8.9-1.1,13.3c-0.2,3,0.3,6-0.2,8.9c-0.6,3-1.5,6.2-3.2,8.8c-1.9,2.9-3.7,5.7-4,9.2
		c-0.5,4.5-2.5,8.4-5.6,11.7c-1,2.5-3,4.7-3.4,7.2c-0.6,4.9-3.4,8.4-5.9,12.2c-4.4,6.5-8.9,13.1-13.1,19.2
		c-10.3,4.7-15.8,12.6-17.9,23.3c0.5,1.5,1.1,3.1,1.7,4.7c-1.6,3.4-3.4,6.6-2.5,10.3c2.6,2.9,5.2,5.1,10,3.5
		c1.5-2.1,3.4-4.6,5.2-7.3c0.9-1.3,1.7-2.3,3.5-1.5c-1.6,6.2-2.4,7.8-4.8,10c-3,0.5-5.6,0.9-8.1,1.4c-0.7,4.9-0.1,8.8,2.4,12.7
		c1.8,0,3.7,0,5.8,0c0.7,3.8,0.4,7.2-1,10.5c-1.3,3.1-1.7,6.1-0.9,9.7c0.9,4,1.1,8.5-1.1,12.3c-2.5,4.2-2.6,8.5-1.7,13.8
		c-1.5,1.5-3.4,3.4-5.2,5.3c-0.5,0.6-1,1.3-1.2,2c-1.6,5.2-2.9,7.1-7.1,10.4c0,2.6,0,5.2,0,7.8c1.2,1.4,2.4,2.8,3.1,3.5
		c3.5-0.6,6-1.5,8.7-1.5c3,0,6,0.2,8.1-2c5,3.4,9.4,6.9,14.4,9.6c1.9,1.1,3.3,2.2,4.5,3.9c1.1,1.5,2.5,2.8,3.5,4c3.1,0,5.7,0,8.2,0
		c3.2-4,7.8-5.6,11.7-7.8c1.6,0.4,2.9,0.7,4.8,1.1c-1.6-5-0.9-9.3,0-13.7c0.6-2.6,0.9-5.4,1.8-7.9
		C98.8,1740.9,106,1736.2,112.9,1731.1z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M733.1,1491.9c-1.8,0.3-3.5,0.7-5.2,0.9c-4,0.5-7.4-1.5-11-2.4c-5.1-1.2-8.6-3.7-11.1-8
		c-0.2-1.6,0.1-3.5-0.6-4.8c-2.6-4.5-1.3-9.1-0.4-13.6c0.4-2,1.6-3.8,2.2-5.8c1.5-4.7,0.3-7.8-4.1-9.9c-2.5-1.2-4.7-2.6-6-5.2
		c0.1-0.7,0-1.4,0.2-2c2.1-6.8,7.9-9.3,13.8-11.3c3.7-1.3,5.3-3.7,4.6-7.5c-0.9-4.9-3-8.9-6.9-12.2c-4.3-3.6-4-9.1,0.4-12.8
		c-2.3-4.1-2.3-8.6-2.4-13.4c-3.4-3.7-1-8.8-2.6-13.8c-1.9,1.1-3.8,1.6-5,2.9c-2.3,2.4-4.2,5.1-6.2,7.8c-4.1,5.6-9.6,6.5-15,2.4
		c-0.8-3.4-0.3-6.4,2.4-8.9c0.7-0.7,1.1-1.9,1.3-2.9c0.5-3.3,0.9-6.6,1.4-10.5c1.4-1.2,3.2-2.7,5.2-4.3c0.1-0.7,0.1-1.5,0.3-2.3
		c0.8-4.2,2.2-7.9,6.3-10.1c1.7-0.9,3.1-2.2,4.5-3.5c3.2-3.2,6.1-6.5,9.3-9.7c1.7-1.8,3.5-3.4,5.4-5.2c-0.8-1.7-1.4-3.1-2.1-4.6
		c0.8-2.5,1.6-4.9,2.3-6.9c4.6-3.5,8.8-1.4,13.1,0.3c2.2-2.9,4.4-5.7,8.5-5.1c-3.8-7.8-3.5-12-0.2-21.6c1.1-3.3,2.2-6.4,1.3-10
		c-0.6-2.8-0.5-6.1,1.3-8.4c3.2-4,4.5-8.8,6.6-13.2c1.6-3.4,3-6.8,6.3-9.3c2.2-1.7,5.1-4,5-7.9c-6.1-3-13.6-2.3-18.9-6.9
		c-1.8-0.2-3.4-0.1-5-0.5c-2.7-0.7-5.4-1.6-8.1-2.4c-0.1,0.2-0.2,0.5-0.3,0.7c-2.1-1.9-4.2-3.9-5.6-5.2c-3.9,0.2-7.1,0.4-9.6,0.5
		c-4.3-3.6-3.9-8.4-5.4-11.5c-6.9-5.7-15.5-3.6-22.7-6.8c-6.9,1.9-13.1,1.5-18-4.9c-1.8-2.4-4.5-4.1-6.9-5.9
		c-3.9-3-7.9-6.1-8.9-11.3c-0.6-3.1-1-6.3-1.5-9.4c0,0,0,0,0,0c0,0,0,0,0,0c-1,2.9-1.5,4.1-2.9,5.2c-0.1,0.1-0.3,0.2-0.4,0.3
		c-0.6,0.4-1.3,0.9-2.3,1.5c-4.4-1.5-8.7-3-13.3-4.6c-2.6-4.2-0.5-9-0.7-13.9c-1.6-3.8-6.1-2.3-9.2-4.3c-0.7-1.2-1.4-2.5-1.7-3
		c-2.1-1.5-3.3-2.4-4.6-3.2c-1.6-1-3.2-2.3-4.9-2.7c-3.7-0.9-6.5-2.5-8.5-6.7c0-1.8,0-4.3,0-6.9c-1.1,0-1.8-0.2-2.3,0
		c-3.9,1.6-7.2,0.8-10.7-1.7c-3.6-2.6-7.2-7.8-6.4-12c0.8-4,0.8-7.7-1.4-12c-4.1,0.5-8.4,0.9-12.7,1.5c-6.2,0.8-12,2.9-16.5,6.4
		c-1.3,2.1-2.6,3.7-3.4,5.5c-0.8,1.8-1.2,3.8-1.4,5.7c-0.5,4-0.4,8-1.2,11.9c-0.5,2.9-0.8,5.9-3.1,8.3c-1.1,1.2-1.5,3.1-2.3,4.8
		c-1.6,1-3.3,2.5-5.2,2.9c-5.4,1.3-10.3,3.9-15,6.5c-4.2-1.7-7.6-0.9-9.6,1.4c-4.5,1.1-7.8,1.9-9.6,2.3c-3.6,4.5-6.3,7.8-9.5,11.8
		c0.3,1.3,0.8,3.6,1.3,5.7c-2.3,1.5-5,3.1-7.4,4.6c-1.3-0.5-2.5-1.4-3.6-1.3c-5,0.2-9.5-1.8-13.7-3.5c-4.7-1.9-8.2-1.5-12.1,1.4
		c-0.4-1.4-0.9-2.6-1-3.8c-0.2-3.4-1.3-6.1-4-8.3c-1-0.8-2.1-2.8-1.7-3.7c1.4-3.3-1-5.4-1.5-8c-2.6-0.8-4.9-2-7.1-2
		c-5.2,0-10.3,0.6-15.9,1c-0.5,0.8-1.3,1.8-1.9,2.9c-1.4,2.6-0.9,5.2-0.3,7.9c0.8,3.5-0.1,7.2,1.9,10.6c1,1.7,1.8,4.4,1.1,5.9
		c-2.6,6.2,0.9,12.3,0,18.4c-0.5,3.7,2,6.5,4.2,9.2c-3,2.5-5.4,2.8-8.8,0.6c-6.5-4.4-12.9-2.5-19.6,0.1c-3.5-6.9-9.6-4.5-15.6-3.9
		c-2.1-4.1-4.2-8.1-6.3-12.3c-6.7-4.8-13.9-4.2-21.2-1.5c-5,1.9-9.9,3.7-15,5.6c-1.6-1.4-3-2.7-4.5-4.1c-4.7,0.7-8.9-3.7-13.9-1.3
		c-4.3,2.1-9.7,2.2-12.4,7.1c0,2.3,0,4.7,0,6.9c3.1,2.6,5.9,5,9.2,7.8c-1.3,1.5-2.4,2.7-3.6,4.1c0.3,1.1,0.6,2.3,1,3.6
		c-3.7,4.2-1.2,8.8,0.5,12.5c1.4,3.1,5,5.4,8.1,7.5c3.9,2.8,8.3,0.2,12.3,0.2c1.9,1.8,3.3,4,5.3,4.7c4,1.3,6.8,3.9,9.8,6.2
		c3.5,1.7,6.9,3.4,10.5,5.2c0.6,1.2,1.3,2.7,2.1,4.1c0.6,0.9,1.2,2.1,2.1,2.5c2.5,1.2,5.2,2.1,7,2.8c2.1,2.2,3.6,3.8,5.4,5.8
		c0,1.1,0.1,2.6,0.2,3.4c2.4,4.1,4.4,7.4,6.6,11.1c1.9-0.3,3.7-0.5,5.4-0.8c0.5,1.9,1,3.6,1.6,5.6c-0.7,1.9-1.6,4-2.5,6.5
		c-2.4,0.5-3.7,2.2-3.3,4.8c2.5,3.9,4.8,7.5,7,11.2c0.2-0.1,0.4-0.2,0.6-0.3c0.1,1.3,0.2,2.5,0.2,3.7c3.4,3.6,7.3,6.6,9.3,11.1
		c0.5,1.1,1.7,2,2.7,2.7c2.9,2,6,3.8,8.6,5.4c1.4,0,2.3,0,3.8,0c-1.1,1.3-1.7,2.1-2.2,2.6c1.5,3.8,2.8,7.4,3.9,10.2
		c-1,3-1.8,5.3-2.7,7.9c-1.9,0.1-4.2,0.3-5,3c0.4,1.3,1.4,2.8,1.1,4c-0.8,4.1,2.3,5.9,4.5,8.2c1,1,2.3,1.8,3.6,2.3
		c2.4,0.9,3.2,3.1,3.9,5c1.5,3.7,2.4,7.5,3.5,11.4c0.3,0.9,0.2,1.9,0.2,2.9c-0.4,0.1-0.7,0.2-1.1,0.3c-2.9-6.1-3.6-13.4-9-18.4
		c-1.5,0-3,0-4.3,0c-4.1,6-4.6,12.3-3.1,19.2c-1.7,2.4-3.5,5-4.9,6.9c-0.8,3.8-1.5,6.9-2.2,10.2c2.3,3,0.7,6.4,0.5,9.8
		c-3,0.3-3.3,2.4-3.7,4.4c-0.3,1.5-0.2,3.5-1.1,4.5c-2.6,2.8-3.5,6.4-4.7,9.8c-1.6,4.6-3.3,9.2-2.9,14.1c0.2,3.1-0.9,5.8-2.5,8.1
		c-2.2,3.2-4.5,6.1-5.7,10.1c-0.8,2.8-2.9,5.7-6.1,7c-0.4,1.6-0.8,3.1-1.2,4.7c0.3,0.6,0.7,1.3,1.1,2.1c2,0.9,4.4,1.7,6.3,3
		c3.7,2.5,7.3,5.3,7.1,10.6c0,1,0.7,2,1.2,3.3c5.4-0.3,7.8,3.4,9.6,7.7c2.5,0.4,4.9,0.7,7.2,1c3,0.4,6.1,0.4,9,1.2
		c2.5,0.7,5,2,7.1,3.7c1.8,1.5,2.9,3.7,4.5,5.8c7.7,0.3,16.2-1.7,21.8,5.6c3.7,0.8,6.3-1,8.8-2.7c2.7-1.9,5.3-2.6,8.5-1.3
		c3.5,1.4,7,2.8,10.6,3.8c3.6,1,6.8,2.2,8.9,5.8c1.5,2.6,4.3,3.6,7.5,3.3c4.1-0.3,8.4-0.6,11.4,1c3.4,2.9,3.4,5.8,2.1,8.4
		c1.6,2,3,3.7,4.1,5.1c4.3-0.4,6.8-4.2,11.2-2.3c3.6,1.6,4.8,5.3,7.6,7.1c2.5-1.4,4.7-3.6,7-3.6c7-0.1,14.2-1.2,21.1,0.8
		c2.1,0.6,4.1,1.6,6.3,2.5c-1.9-4.2-3.7-8.2-5.4-12c1.2-1.9,2.2-3.4,3.2-5c0-2.6,0-5.4,0-8c-0.8-1.2-1.5-2.2-2.1-3.4
		c-1.6-3.1-0.3-6.8,2.8-8c4-1.5,8.1-2.7,12.1-4.2c1.3-0.5,2.5-1.4,3.6-2.3c1.8-1.5,3.4-3.1,5.1-4.6c2.3-3.7,5.7-5.6,9.7-6.4
		c4.1,2.3,6,7,10.7,7.5c1.2-1.3,2.1-2.3,2.7-3c2.1,1.1,4,2.1,6.2,3.3c0.6,0.9,1.4,2.2,2.1,3.2c5.6,1.3,10.2-0.5,14.6-3.6
		c1,2.5,1.8,4.7,2.8,7.4c2.5,0,5,0,7.8,0c2.7,4.6,6.3,8.3,12.7,7.7c1.8-0.2,3,1.2,3.9,2.9c0.6,1.3,2,2.3,3.3,3.1
		c1.8,1.2,3.9,2.1,6.5,3.5c3.2-0.4,7.1-0.8,11.1-1.3c0-0.2-0.1-0.3-0.1-0.5c2.3-0.6,4.6-1.3,7.9-2.2c0.6-0.7,2-2.2,3.4-3.7
		c3.9,0.8,3.9,0.8,6.8-0.9c2.4-4,4.7-7.9,6.4-10.7c7.7-4.3,15.3-6.7,20.1-13.4c0.7,0,1.3,0.1,2.5,0.1c-0.2-5.9,2.6-10.2,5.7-14.5
		C733.3,1496,734.8,1494,733.1,1491.9z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M547.6,1569.2c-2.4-0.2-4.7-0.7-6.8-1c-4,3.9-8.3,5-13.5,3.8c-1.9-2-3.9-4.2-6-6.4c-4.2,2.4-8.6,2.7-13,1.3
		c-1.4-1.6-2.8-3.3-4.5-4.2c-2.1-1.1-4.7-1.1-6.7-2.2c-3.5-1.9-8.2-3-7.9-7.4c-2.7-2.3-4.8-4.2-7-6c-1-0.8-2-1.7-3.2-2.1
		c-4.9-1.7-9.9-3.2-14.7-4.7c-4.7,3.9-9.8,5.4-15.8,4.8c-3.6-4.4-8.4-6-14-5.8c-3.2,0.1-6.4,0-9.9,0c-1-1.4-2-2.8-3-4.3
		c-1-1.4-1.9-2.9-2.3-3.6c-7.8-3.3-15.9-0.2-22.2-4.8c-1.5-2.2-3-4.4-4.5-6.6c-2.3-0.2-4.7-0.7-5.7-2.1c-1.5-1.9-3.1-4-3.2-6.9
		c-0.1-2.3-1.5-4.2-3.9-5c-5.8-1.9-9.3-5.8-10-12.1c-3,0-5.7,0-8.4,0c-2.8-1.9-5.3-4-8.1-5.5c-4.2-2.1-8.6-3.2-12.7-1.4
		c-3.8-3-7.1-5.6-10.3-8.2c-4.3,2.3-8.4,3.1-13,2.9c0.3-5.1-2.9-6.6-6.7-7.4c-3.9-0.9-7.7-1.7-12.1-2.7c-1.9,0.6-4.3,1.2-6.6,2.2
		c-1.9,0.8-3.5,0.5-5-0.5c-1.2-0.8-2.3-1.7-4-3.1c-2,0.2-4.5,0.4-6.9,0.9c-2.9,0.6-5.7,0.6-8.3-1.1c-6.3-4.3-13.3-6.1-20.8-6.5
		c-1.2-0.8-2.6-1.7-4-2.6c-0.6-1.9-1.3-3.9-2.2-6.5c-2.6-1-5.7-2.3-8.8-3.3c-4.6-1.5-9.4-2.7-14-4.5c-2.5-1-4.6-1.5-7.3-0.5
		c-2.5,0.9-5.4,1.3-8,1c-5.3-0.6-10.1-3.8-15.6-3.8c-0.4,0-0.8-0.5-1.2-0.8c-2.9-2.4-6.1-2.5-9.5-1.4c-0.8,0.3-1.8,0-3.1,0
		c-0.7-2.1-1.4-4.1-2.3-6.7c-5.9-4.8-12.7-8.9-20.4-10.5c-2.6,1.9-4.8,3.5-6.8,5c-9.8,0-13.1,2.5-15.2,11.7
		c-1.9,1.8-4.3,0.9-6.4,0.7c-3-0.3-6-0.9-8.8-2c-5.3-1.9-9,0.9-13.3,3.9c-2.1,0.7-4.8,1.6-7.5,2.5c0.2,0.2,0.3,0.4,0.5,0.6
		c-1.3,1.4-2.7,2.8-4.4,4.6c0,3.3,0,7.2,0,11.5c1.3,1.4,2.9,3.1,4.5,4.9c-1.2,2.5-2.4,5.1-3.3,7c0.5,3.8,2.2,5.5,5.2,6.3
		c1.9,0.5,3.4,2,5.3,3.2c-1.5,3.6-2.4,7.4-4.4,10.4c-3.1,4.6-5,9.5-6.7,14.6c-0.6,1.7-0.6,3.7-0.8,4.9c4,0,7.5,0,11,0
		c2.7-2.5,5.5-5.1,7.8-7.2c4.7,0,8.5,0,12,0c1.8,2.8,3.4,5.1,5.4,8.2c-1.1,2.5-2.5,5.5-4.2,9.2c4.6,0.4,8.9,0.7,13.3,1.1
		c2,1.6,4.1,3.4,6.2,5c3,2.2,4.8,2.1,7.8-0.2c0.8-0.6,1.4-1.3,2.2-1.9c3.7-3,11-3.1,13.6-0.9c4.5,3.7,9.6,5.3,15.5,5.7
		c5.5,0.3,8.1,3.8,7.4,8.6c-0.2,1.5-1.2,2.8-1.9,4.1c7,6.6,7,6.6,6.4,13.6c-1.2,1.3-2.8,3-4.4,4.8c-0.2-0.3-0.5-0.6-0.7-0.9
		c-2.6,0.6-5.5,0.8-7.8,2c-4.7,2.4-9,5.4-13.5,8c-2.2,1.2-3.4,2.8-3.3,5.4c0,0.9-0.3,1.9-0.4,2.8c-0.8,4.5-2.2,9-2.3,13.6
		c-0.2,6.9,0.3,13.8-1.8,21.7c-0.3,0.3-1.4,1.2-2.3,2c-2,0.3-3.7,0.6-6,1c0.3,3.3,0.7,6.3,0.9,9.3c0.1,2.5,0.2,5.1-0.2,7.6
		c-0.9,5.4-4.2,8.7-9,11.5c-5.2,2.9-9.6,2.5-14.4-0.2c-1.5-0.9-3-1.7-4.5-2.7c-0.4,3.4-0.5,3.5,1.2,5.9c2.4,3.3,3.3,6.8,3.2,10.8
		c-0.1,2.4,0,4.8,0,7c3.9,2.3,8.2,3.1,10.8,7.7c-0.3,1.1-0.2,2.9-1.1,4c-3.8,4.5-5.2,10.1-8.3,15c-0.4,0.7-1.1,1.2-1.3,1.5
		c-3.2,0.7-6,1.2-9,1.9c0,6.7,0,13.2,0,18.8c3.5,1.9,6.2,3.4,8.8,4.8c0.1,3.6,0.9,6.9-1.8,9.1c-3.9,3.3-7.9,6.6-12.3,9.2
		c-6.2,3.6-9.2,8.9-10,15.7c-0.1,1.1-0.6,2.2-0.7,3.3c-0.1,4.3-1.4,8.7,0.4,13c1.7,0.2,3.1,0.4,3.7,0.4c1.8,1.2,2.8,2.3,4.1,2.6
		c0.9,0.2,2.1-0.7,3.5-1.3c5.5,5.8,11.1,11.8,17.5,18.6c0.2,3.5,0.8,8,0.4,12.4c-0.4,4.8,1.7,8.2,4.8,11.1c0.4,2.2,0.7,4.3,1.2,6.2
		c0.5,2.1,1.4,4.2,1.9,6.4c1.3,6.2,4.2,10.8,10.4,13.1c2.6,1,4.9,2.5,8.1,4.2c4.6-0.7,10.3-0.8,15.2-4.9c0.2-1.2,0.4-2.6,0.7-4.9
		c6.7-2.8,14.6-3.3,20.2-9c2.1,1.2,3.9,2.3,5.8,3.4c1.5,0,3.2,0.2,4.7-0.1c1.6-0.3,3-1.3,4.6-1.7c2.2-0.6,4-1.3,5.4-3.5
		c1-1.6,3.1-2.5,4.7-3.7c1.5,0.9,2.6,1.5,3.2,1.9c2.1-0.2,3.7-0.6,5.2-0.4c8.3,1.3,16.7,2.5,24.8,4.8c1.7,0.5,3.7,0.1,5.5,0.2
		c2.9,0,5.7-0.1,8.6,0.2c2.6,0.3,5.2,1.3,8.6,2.2c10,8.6,13.9,7.6,20.6,2c1.7,0.1,3.4,0.2,5,0.3c3.9,5.2,5,6.9,10.6,7.5
		c2.6-2,5.8-3.9,8.1-6.6c4.4-4.9,9.5-9.4,11.6-16.1c0.7-2.2,3.5-3.8,3.9-5.9c0.8-5,4.4-7.6,7.3-10.7c1.3-0.2,2.7-0.1,3.4-0.7
		c4.5-4,10.1-3.2,14.7-2.1c4.5,1,8.8,0.3,13.1,0.6c0.4,0,0.9-0.3,1.2-0.3c0.9-1.3,1.6-2.5,2.4-3.7c0-2.5,0.1-5,1.3-6.9
		c1.5-2.3,2.7-4.6,3.6-7.2c1.2-3.4,2.7-6.8,4.4-10c0.9-1.7,3-3,3.6-4.7c1.9-6,6.4-8.3,11.9-9.7c1.7-0.4,3.5-1.1,4.7-2.2
		c4-3.6,8.4-6.3,13.2-8.6c2.3-1.1,4.2-2.9,4.9-6.3c-1.3-0.5-2.5-1.1-3.8-1.3c-3.7-0.7-11-7.2-11.8-10.9c-0.2-0.9,0-1.9-0.1-2.9
		c0-0.9,0.3-2.1-0.2-2.8c-3.3-5.5-1.6-11.3-1.4-17c0.1-2.5,1-5,2.7-7c1.6-1.9,3.1-4,4.2-6.2c2.6-5.4,7.7-8.7,11.4-13.1
		c0.3-0.4,0.6-0.9,1-1c3.6-0.9,4.9-3.9,6.3-6.2c1.5-0.9,3.2-1.2,3.9-2.3c2.7-3.7,5.2-7.6,7.5-11.6c1.8-3,4-5.4,7.4-6.3
		c2.3-0.6,4.7-0.9,7.2-1.4c1.3-1.5,3.5-2.7,2.7-6.4c-0.5-0.3-1.7-1.1-3.2-2c3.6-6.4,9.1-9.6,15.4-10.5c8.7-1.2,17.2-3,25.8-5
		c2.3-0.5,4.8-0.5,7.2-0.7c0.8,0.3,1.5,0,2.2-0.4c3.9-2.2,7.9-4,11.5-6.6c2.6-1.8,5-3.8,7.9-5.2c5.6-2.8,11-5.7,17.1-7.2
		c2.8-0.7,6.2-1.2,6.9-4.6c2.3-0.7,4.2-1.2,6.4-1.9c2.7-6.7,8.3-12.3,6.6-20.4c2.5-1.7,3.8-3.9,3.9-6.8c-4.8-0.9-9.4-1.9-14-2.6
		C551.7,1568.9,549.6,1569.4,547.6,1569.2z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M864,719.5c-0.8-0.5-1.5-1.2-2.4-1.6c-2.8-1.5-5.7-3-10-2.6c3.4,4.3,2.7,8.5,1.3,12.6c-1.2,3.5-1.7,6.9-0.7,10
		c1.5,1.3,2.5,2.8,3.9,3.4c4.6,2.1,4.6,6,4,9.9c-0.3,2.3,0.3,4,1.5,5.6c2.1,2.6,4.3,5.1,6.8,8c0,2.1-0.2,4.6,0,6.9
		c0.2,2.4,0.8,4.7,1.3,7c0.3-0.2,0.6-0.3,0.9-0.5c1.5,2.1,3.3,4.1,4.6,6.3c1.7,3,2.7,6.4,4.4,9.4c1.5,2.4,2.4,4.8,2.3,7.6
		c-0.1,2.3,0.5,4.4,1.6,6.4c0.6,1.1,1.1,2.3,1.4,3.5c0.6,3,2.5,4.6,5.7,5.3c0.8,3.1,1.6,6.3,2.4,9.2c1.7,1.1,3.4,2.5,4.9,3.9
		c3.7,3.6,6.4,7.7,6.4,10.1c0,0.5-0.1,0.9-0.3,1.2c-1.1,0.5-2.2,1.1-3.9,1.8c0.6,2.4,1.1,4.6,1.7,7.2c-2.9,4-2.5,7,1.4,11.4
		c0.5,0.6,0.9,1.3,1.2,2c2.1,6,6.2,10.7,10.9,15.4c-4,5.5-5.7,11.7-5.9,18.7c1.3,2.6,4.2,3.6,7.2,3.9c3.5,0.4,7,0.7,10.5,0.9
		c0.9,0.1,2-0.2,2.7-0.6c1.8-1.2,3.5-2.6,6-4.4c3.3,0.8,7.2,1.8,11,2.7c3.9-3.9,7.1-7.6,8.8-12.6c-5.9-5.7-3.3-13.1-3.9-20.5
		c4.3-2.3,8.5-4.5,13.2-7.1c0.6-1.7,1.3-3.7,2.1-6c1.1,0.2,2.3,0.2,3.2,0.7c3.5,1.9,7,2.6,10.8,1.4c0.3-0.1,0.6,0,1,0
		c3.4,0.1,6.8,0,10.2,0.2c5.5,0.3,11.3-2.5,11.5-8.7c0.1-5.6,3.2-10.8,1.7-16.5c-0.2-0.8,0.5-1.8,1-2.6c0.4-0.8,1.3-1.5,1.6-2.4
		c2.1-7.3,5-14.5,5.8-22.1c0.2-1.6-0.2-3.5-1-5c-1-2.1-1.1-3.9,0.4-5.8c1.5-2,2.3-4.2,0.9-6.7c-0.5-0.9-0.3-2.2-0.3-3.3
		c-0.1-4.8,0-9.7-0.2-14.5c-0.1-2.6-0.8-5.2-1.3-8.4c0.5-0.6,1.4-1.7,2.2-2.6c0-1.7,0.4-3.4-0.1-4.7c-0.9-2.2,0-4,0.5-6
		c1.1-4.4,1.7-9,2.5-13.5c0-0.2-0.4-0.6-0.4-0.8c-0.2-3.8-1.1-7.2-4.3-10.1c1.8-0.1,3.1-0.2,4.2-0.2c5.6-4.4,8.5-8.1,12-14.1
		c0.8-1.4,2.3-2.5,3.3-3.6c6.1,2.6,6.1,2.6,11.3,0.7c3.5-4,6.7-7.7,9.7-11.1c1.4-4.4-1.4-7.6-2.4-11.5c1.1-0.6,2.2-1.1,3.4-1.7
		c0.6-2.5,3.8-4.7,1.1-7.8c-1.2-0.2-2.3-0.4-4.3-0.8c2.1-2.1,3.3-4.3,5.1-5.2c5.4-2.6,8.4-7,10.6-11.4c-2-7-4.7-12.8-10-17
		c-0.2-0.2-0.5-0.5-0.6-0.7c-1.3-3.8-4.2-5.9-7.2-6.5c-4-3.9-4.5-9.4-8.9-12c-1.5-0.1-3-0.2-4.3-0.2c-3.1-5.5-8.5-6.2-13.9-6.8
		c-1-2.2-1.9-4.2-2.9-6.4c-2-0.9-4.1-1.8-6.3-2.8c0.4-6.1,0.5-11.9-3.2-15.9c0.5-2.7,1.5-4.7,1.2-6.5c-0.3-1.9-1.8-3.6-2.5-4.9
		c0.7-2,1.9-3.7,1.5-4.7c-1.7-3.8,1.5-5.5,2.7-7.2c0.5-2.8,0.5-5.2,1.5-7.1c1.5-3.2,1.4-6.4,0.3-9.3c-1.2-3.2-1.5-5.9-0.7-9.3
		c1-4,0.5-7.9-3.5-10.6c-1.2-0.8-2.7-2-1.5-3.9c0.4-0.1,0.7-0.3,1-0.3c6.3,1.4,8.6,0.1,10.2-5.9c0.2-0.9,0-1.9,0.2-2.9
		c0.6-4,1.8-7.6,4.2-11c2.3-3.2,4.2-6.8,2.7-11.1c-0.4-1.1,0.2-2.4,0.3-3.8c6.9-1.8,10.6-7,13.7-12.5c2.6-4.6,5.1-9,9.5-12.3
		c4.4-3.3,8.3-7.3,13.2-11.7c1.1-2.7,3.1-5.7,7.3-7c2.7-0.8,4.2-3.5,5-6c1.5-4.7,2.7-9.5,3.8-14.3c0.4-1.8,0.1-3.7,0.1-5.6
		c0.1-1.3,0.7-2.8,1.8-3.6c4.9-3.5,7.1-8.1,6.1-13.8c-1-1.4-2.4-2.5-2.6-3.7c-0.7-4.3-3-7.2-7.2-9.3c1.1-4.2,2-8.3,3.2-12.3
		c1.2-4.1,2.6-8.1,3.9-12.2c-0.7-1.8-1.3-3.6-2-5.4c0.4,0.3,0.7,0.6,1.1,0.9c1.5-1.6,2.9-3.3,4.4-4.9c-0.2-0.2-0.4-0.5-0.6-0.7
		c0-1.6,0-3.2,0-5.3c4.7-2.1,3.7-6.4,4.2-9.9c1.8-1.2,3.4-2.2,4.8-3.2c2.2,0,4.1,0,6.4,0c0.9-1.4,1.9-3,2.6-4.1
		c6.9,0.8,12.9-1.5,18.5-3.8c1.2-2.9-0.5-4.1-1.9-5.5c-3.2-3.1-4.7-6.8-4.5-11.2c0.1-2.7,0.4-5.4,0.7-8.1c-1.8-4.2-4.4-8.2-5.2-12.6
		c-0.8-4.6,0.3-9.5,0.6-14c-2.6-2.2-4.6-4.6-7.2-6.1c-4.5-2.6-5.7-7-7.2-11.3c-0.5-1.5-1.2-3.3-0.8-4.6c0.9-2.5-0.2-4.3-1.2-6.3
		c-0.4-0.7-0.9-1.4-1-2.2c-0.4-4.1-1-8.3-1.1-12.4c-0.1-2.6-0.8-4.7-2.6-6.5c-1.6-1.6-3.2-3.1-5-4.8c-0.3-1.6-0.7-3.4-1.1-5.3
		c-1.3-5.8-3-7.3-8.9-8.1c-0.8-0.1-1.6-0.2-1.8-0.2c-2.4-2.2-4.3-4.1-6.4-5.9c-1.1-1-2.4-2-3.8-2.6c-3.2-1.4-6.5-2.6-9.7-3.7
		c-1.3-0.5-2.7-0.8-4.1-1.1c-4.1-0.9-7.9-2.7-10.7-5.8c-4.6-5.1-9-10.3-13.5-15.4c-0.5-0.5-1.4-0.8-2.2-1.2c-1,0.9-2,1.7-2.2,1.9
		c-0.6,4.8-1.7,8.9-1.5,12.9c0.2,4.2,1.7,8.3,2.7,13c-1,1.3-2.2,2.9-3.1,4.2c-7.7,0.3-14.8,0.6-20.9-2.6c-2.3,1-4.3,2.5-6.2,2.4
		c-4.7-0.2-8,2.9-12.2,4.3c1,1.5,1.6,3.1,2.7,4.1c2.5,2.3,3.5,5.3,4.3,8.4c0.9,3.2,0.4,4.8-2,6.8c-1,0.8-1.9,1.7-3,2.4
		c-3.7,2.5-7.2,5.7-11.2,7.3c-5.1,2-8.7,5.1-11.4,9.8c0,4.1,0,8.2,0,12.3c-1,0.8-1.6,1.5-2.5,1.9c-0.9,0.4-2,0.6-3.5,0.9
		c1.5,2.7,2.9,5,4,7.5c2.2,4.9,3.6,9.9-0.1,14.9c-1.1,1.5-2,3.3-2.6,5c-1.8,5.4-3.2,11-4.9,16.4c-0.9,2.7-2.2,5.3-2.9,8.1
		c-0.9,3.8-2.1,7.2-5.5,9.7c-1.7,1.2-3,2.9-4,4c-0.5,4,0.9,7.8-1.4,10.6c-3.4,2.4-6.9,1.5-10.2,0.8c-1.5,1.6-1,3.1-0.7,4.7
		c0.6,3.4,1,6.9,1.4,10.4c0.2,1.3,0.1,2.6,0.4,3.8c1.5,5.2,0.4,10.4-1.3,15c-2.7,7.3-4.5,14.6-4,22.4c0.3,4.4-0.9,8.4-3.8,12
		c-2,2.4-3.2,5.5-4.4,8.5c-0.9,2.1-0.5,4.2,0.8,6.4c2.1,3.6,3.9,7.4,5.2,11.3c1,3.1,1.8,7.2-1.1,9.6c-2.3,2-4.9,4.1-8.5,4.4
		c-5.2,0.4-10.3,1.7-14.4,2.4c-3.5,3.9-6.2,7.2-9.2,10.3c-2.4,2.5-4.2,5.1-4.3,8.8c0,1.8-0.6,3.8-1.5,5.4c-1.5,2.8-3.3,5.4-5.2,8.4
		c0,1.5,0,3.4,0,4.5c3,9,5.6,17,3.2,25.9c-1.2,4.3-2.2,8.7-0.3,13.2c1.2,2.9,1.9,6,3,9c0.9,2.5,0.9,4.9,0.4,7.5
		c-0.5,2.8-0.5,5.8-0.4,8.6c0.2,3.8,0.7,7.6,0.9,11.5c0.1,2.7,0,5.4,0,8.3c1.4,0.7,2.6,1.4,3.8,2.1c2.2,1.2,4.6,2.2,6.7,3.6
		c5.8,3.6,6.8,9.1,5.6,15.1c-0.5,2.4-1.8,4.5-3.7,6.5c-2.4,2.4-5.6,3-8.5,5c-0.4,1.9-0.9,4-1.4,6.1c1.5,1.1,2.7,2,3.4,2.5
		c1.2,2.9,2.2,5.2,3,7.6c0.7,2.1,1.6,4.3,1.7,6.4c0.2,8.2,0.2,16.3-0.1,24.5c-0.1,2.1-0.8,4.7-2.1,6.2c-2.6,2.9-4.8,6.4-9.3,6.9
		c-1.1,0.1-2,1.4-2.9,2c-0.1,2.1-0.1,3.9-0.3,5.6c-0.3,1.9-0.9,3.7-1.1,5.6c-0.3,2.7-0.5,5.4-0.4,8.1c0.1,2.7,0.6,5.4,0.8,8.1
		c0.2,2.7-0.5,5.1-3.3,6.4c-2.4,1.1-3.6,3.9-3.1,5.5c0.9,3.1-0.3,5.7-0.4,8.5C872,719.6,867.9,721.6,864,719.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M739.9,1301.8c3.8-1.5,5.6,1.6,7.9,2.9c3.3,0.9,6-0.4,8.2-2.3c2.1-1.8,4-1.7,6.1-0.2c0.8,0.5,1.5,1.1,2.3,1.7
		c4.1-4,7.6-8.8,14.1-7.5c3.2,2.7,6.3,5.3,9.3,7.8c6-4.2,6.4-4.2,11.7,0c3.3,2.6,6.7,5.2,10,7.7c2-0.9,3.6-1.6,5.8-2.6
		c3.7,2.8,7.5,5.7,11.3,8.6c2.4,1.8,4.8,3.9,8.1,3.2c3.5-3.7,4.6-9.2,9.7-11.4c7.1-0.3,13.9,0,17.6,7.8c1.9-0.8,3.5-1.4,5-2.2
		c3.6-1.8,6.9-4.4,10.7-5.3c4-0.9,8.3-0.3,12.5-0.3c0.3,0,0.6-0.2,0.9-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c2.7-1.8,5.4-3.7,9.2-6.3
		c0.9-2.6,3.9-3.7,7.8-2.5c1,1.3,2,2.5,2.9,3.5c4-0.4,7.6-0.8,11.6-1.3c1.7,2.4,3.3,4.7,5,7.2c2-3.2,1.6-6-0.2-8.4
		c-1.8-2.3-1.7-4.3-0.5-6.7c0.4-0.7,0.6-1.4,1.1-2.6c-2.3-3.5-4.7-7.2-6.5-9.9c-1.6-5.2,1.2-7.9,3.3-10.6c3.4-4.5,8.9-4.4,13.6-5
		c0.4-0.5,0.7-0.8,0.7-1.1c0.7-6.6,4.5-9.8,10.9-10.5c2-0.2,3.9-1.3,5.7-1.9c0.1-0.8,0.2-1.3,0.1-1.8c-0.1-0.6-0.3-1.3-0.5-1.8
		c-2.9-6-7.3-10.5-13.4-13.1c-2-0.9-3.6-1.8-4.7-3.7c-2.1-3.6-4.9-6.2-9.4-6.6c-1.2-0.1-2.3-1-3.2-1.5c-3.2-7-3.2-7-10.6-9.8
		c-2.5-4.1-3.4-9.1-6.5-13c-2.1-2.5-2.4-5.6-1.9-8.7c0.4-2.6,1-5.2,1.6-7.9c-2.2-0.7-4.1-1.3-5-1.6c-4.6-3.9-8.4-7.2-12.6-10.7
		c0.5-2.4,1-4.8,1.4-6.5c4.5-3.7,7.6-1.4,10.7,1c3-3.9,7.4-3.6,10.5-4.2c2.8-3,5.1-5.4,7.2-7.6c5,0.7,8.3-0.4,9.2-3.8
		c0.8-2.9,2.5-4.1,4.7-4.9c5.8-2,10.7-5.6,16.1-8.1c1.7-0.8,3.4-1.7,5.1-2.4c4-1.5,8-3,12.1-4.4c0-2.3,0-4.2,0-6.5
		c1.8-1.4,3.7-2.8,4.9-3.7c5.2,0.3,6.8,3.1,7.5,6.9c0.2,1.3,1,2.4,1.7,4.1c4.6-4.4,7.3-8.6,6.2-14.5c-0.9-4.9-1.7-9.7-2.6-14.9
		c-4.3-1-7.9-3.2-7.6-8.4c-1.9-1.7-3.6-3.3-5.8-5.2c1.2-3,2.4-5.9,3.8-9.4c-0.9-1-2-2.2-3.7-4c0.6-2.9,1.2-6.3,2-10.3
		c-1.1-2.1-2.3-4.9-4.1-7.3c-2-2.8-1.9-5.3-0.3-8.1c0.8-1.4,1.6-2.8,2.7-4.6c-1.1-2.2-2.2-4.8-3.3-6.9c-10.2-4.5-11.5-6.8-11-18.6
		c0.2-0.3,0.5-0.7,0.8-1.1c3.1-2.8,3.4-6,1.7-9.6c-0.8-1.9-1.3-3.9-2-5.9c1.3-3.1,1.9-6.3,2.1-9.4c0.1-2.2-0.4-4.5-0.9-6.6
		c-0.6-2.3-1.6-4.4-2.4-6.5c-1.8-0.3-3.1-0.6-4.3-0.8c-0.7-1.2-1.1-2.6-1.9-3c-4-2.1-6-5.5-7-9.7c-0.1-0.4-0.5-0.8-0.8-1.2
		c-1.9-2.3-3.8-4.7-5.2-6.3c0.6-3,1.2-5.4,1.5-7.8c0.3-2.2,0.2-4.5,0.5-6.7c0.6-5.7-3.7-10.4-8.3-10.3c-4.5,2.2-8,3.9-11,5.4
		c-0.5,1-0.5,2-0.3,2.9c0.4,2.1,1.6,4-0.6,5.7c-3.1,0.6-6.3,0.9-9.4,0.9c-1.9,0-3.8,0-5.8-0.2c-5.2-0.4-11,1-13.3,7.3
		c-0.5,1.3-1.5,2.4-2.5,4c-2.2,0-4.4,0.2-6.6,0c-3.4-0.3-6.1,1.1-7.9,3.8c-3,4.7-7.4,6.7-12.7,6.9c-2.2,0.1-4.6,1.3-6.7-1.3
		c1.6-1.3,3.1-2.4,4.9-3.9c0.2-1.3,0-2.7,0.5-3.8c1.5-3.6,0.1-6.8-2.5-8.6c-3.1-2.2-6.4-5.4-11.1-2.8c-1.8,1-4,1.5-6,2.2
		c-1.8-3-3.2-5.4-4.9-8.3c-2.1,0.7-4.1,1.5-6.2,2.2c-2.2-1-3-2.8-2.7-5.1c0.6-4.5-0.6-8.5-3-12.8c-1.7-0.7-3.7-1.5-5.2-2.1
		c-3,2.1-5.6,3.9-8,5.5c-8.1,0.1-14.5-1.5-17.5-4.6c-2.2,0-4.3,0-6.2,0c-1.2,0.9-2.2,1.7-3.2,2.4c-0.1,0.1,0,0.3,0,0.7
		c5.2,2.2,4.6,7.9,7,11.7c-1.6,2.6-2.8,5-6.1,5.9c-1.7,0.5-3.6,2.5-4.2,5.3c1.6,1.7,2.8,4,6,3.9c1.1,0,2.2,1.1,3.3,1.8
		c0.2,0.1,0.2,0.5,0.3,0.9c-1.2,0.8-2.3,1.5-3.5,2.4c0.1,1.1,0.1,2,0.2,3.1c1.5,0.7,2.9,1.3,4.3,1.9c-0.4,0.9-0.9,1.9-1.5,3.1
		c1.1,1.6,2.2,3.2,3.5,5.1c-3.8-0.4-7.2-0.7-10.8-1.1c-1.4,1.2-2.6,2.3-3.8,3.3c0.3,0.2,0.6,0.4,0.8,0.6c-0.9,4.5-1.9,8.9-2.8,13.3
		c-5,1-5,1.6-7.2,6.1c-0.7-0.7-1.4-1.3-2.1-1.9c1.3-4.3-0.7-7.1-4.1-9.8c-2.1,0.3-4.4,0.7-7,1.1c-3.6-2.8-8.2-1.8-12.3-1.1
		c-5.1,0.9-10,3.2-11.4,9.2c-0.2,0.8-0.7,1.5-1,2.1c0.8,2.5,1.4,4.9,4.4,5.8c0.8,0.2,1.6,1.3,1.9,2.1c1.5,4.2,2.9,8.5,4.3,12.6
		c-1.5,3.8-2.9,7.3-4.5,11.1c2,4.9-3.1,7.7-4.2,11.8c-2.8,1.2-5.4,2.3-8.7,3.6c1.1,1.4,1.8,2.6,2.8,3.5c1.2,1.1,2.6,1.8,3.9,2.8
		c4,3.3,4.5,7.7,3.5,12.3c-0.7,3.2-3.1,5.2-6.5,5.6c-1.1,0.1-2.1,0.3-3.4,0.5c-0.4,1.6-0.7,2.9-0.8,3.7c-2,2.4-3.5,4.5-5.2,6.4
		c-3.6,4-7.4,7-13.4,5.1c-1.7-0.6-3.8-0.1-5.7-0.1c-0.5,0.9-1,1.8-1.6,2.8c2.8,6,3.8,12.5,2.8,19.2c-0.2,1.3,0.1,2.6,0.2,3.9
		c0.1,3.2-0.7,6-2.8,8.5c-1.4,1.7-2.8,3.4-4.3,5.3c2.8,3.2,4,6.8,2.7,11.1c-0.4,1.3,0.4,3,0.5,3.7c2.7,2.4,4.8,4.2,6.9,6.1
		c-0.2,1.6-0.5,3-0.7,4.4c-0.2,1.4-0.4,2.8-0.5,4.1c3.1,3.1,4.5,6.5,3.9,10.6c-0.6,4.6-4.7,6-7.9,8.6c0.5,3.2,1,6.3,1.4,9.1
		c1,0.6,1.5,1,2,1.3c6.9,4.5,7.2,5.9,4.4,13.3c-1,2.8-2,5.6-3.1,8.7c4.6,1.8,6.9,5.1,7.2,9.9c0.1,1.2,1.4,2.3,2.1,3.4
		c3.3-0.8,5.9-3.4,9.7-1.5c0.9,1.3,2.1,3.1,3.4,5.1c1.6,0.1,3.3,0.2,5.2,0.3c1.2,1,2.5,1.9,3.7,2.9c1.3-0.4,2.3-0.8,3.9-1.3
		c5.6,5.7,13.5,5.4,21.3,7.7c1.4,1.7,3.2,3.9,5,6c-1.5,4.3-4.5,7.1-6.9,10.3c-2,2.6-4.1,5.3-5.4,8.3c-2.4,5.1-4.3,10.3-7.8,14.8
		c-1.1,1.3-1.6,3.7-1.3,5.4c0.7,4.1-0.4,7.8-1.5,11.5C739.8,1293.1,738.7,1297.3,739.9,1301.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1203.6,180.7c-1.4-0.7-2.7-1.7-4.2-2c-2.9-0.7-5.9-1-8.8-1.5c-0.3-0.9-0.4-1.7-0.7-2.4
		c-2-4.5-4.2-8.8-4.7-13.9c-0.3-2.6-1.4-5.2-3.6-7.5c-2.1-2.1-3.8-5-4-8.5c-0.2-3.3-0.7-6.8-2.1-9.7c-2.7-5.3-2-10.3-0.8-15.7
		c0.6-2.6,0.1-5.4,0.1-8.5c-4.8-0.8-8.3-3.1-10.7-6.8c-7.9-1.5-15.6-4-24.2-3.1c-0.3,0.4-1.1,1.1-1.5,2c-2.2,4.6-5.9,7.3-10.8,8.6
		c-1.2,0.3-2.2,1.6-3.1,2.3c0,3.7-0.8,7.2,0.2,10.1c2,5.8,1.3,11.3-0.3,16.6c1.6,5.5,3.2,10.7,4.8,16.4c-0.7,1.5-1.1,3.8-2.5,5.1
		c-3.1,2.7-6.5,5.2-10.7,6c-0.7,2.2-1.4,4.3-2.2,6.4c-1.8,5-6.1,7-11.1,5.1c-2.7-1-5.3-2.1-7.9-3.1c-2.7,0.8-4.5,2.1-5.6,4.9
		c-1.2,3-3,5.9-6.3,7.7c-1.6,0-3.4,0-5.5,0c-3.1-2.2-6.5-4.6-10.7-7.6c-1-0.2-3.1-0.5-5.1-1.1c-3.5-0.9-6.7-2.2-7.4-6.5
		c-0.3-1.8-0.9-3.8-1.9-5.4c-1.2-2-2.7-4-4.4-5.5c-1.6-1.5-3.7-2.4-6.2-3.9c-1.2,0-3.1,0-4.9,0c-1.8,1.6-3.5,3-5.3,4.7
		c-0.3,0.9-0.6,2.2-1.2,3.9c1.4,1.6,2.8,3.4,4.3,5.1c3.2,3.5,6.8,6.6,9.5,10.4c2.4,3.4,5.5,5,9.2,6.3c5.1,1.8,10.2,3.6,15.2,5.6
		c1.6,0.6,3.2,1.7,4.2,3c2.9,3.4,6.5,5.9,10.8,6.7c5,0.9,6.7,4.3,7.9,8.5c0.5,1.8,0.9,3.7,1.5,6.2c8.2,4.8,9.2,13,7.9,21.5
		c0.3,0.6,0.3,0.8,0.4,0.9c1.6,4.4,5,8.3,3,13.5c-0.1,0.3,0.6,0.8,0.6,1.3c0.5,5,3.4,8.7,7.2,11.4c3.1,2.2,5.3,4.8,7.4,8.2
		c0,1.8,0.4,3.6-0.1,5c-2,6.2-0.1,11.6,2.9,16.9c1.1,2,2.2,4.7,1.7,6.7c-1.6,6.9-0.7,12.7,4.9,17.2c0.6,4.6,1.4,8.9,7.3,9.5
		c2.4-1.2,5-2.5,7.9-4c0.8,0.9,2.2,1.8,2.5,3c1.4,5.2,4.3,8.8,9.4,10.6c0.6,0.2,1,0.6,1.5,1c-0.5,4.7,0.1,9,1.9,13.3
		c1.2,2.9,1.5,6.2-0.8,9c-6.9,0.5-10.8,5.4-11.1,13.2c-1.5,3.2-2.7,5.6-3.9,8c-0.4,0.8-0.9,1.8-1.6,2.4c-3,2.4-3.8,5.7-4.3,9.3
		c-0.5,3.9-0.9,7.9-4.1,11c-2,2-1.9,4.6-0.4,7.3c-2.1,2.8-4.1,5.4-6.1,8.1c-1.9,2.7-3.8,5.4-6.7,7.3c-4.7,3.1-7.5,7.3-6.7,13.2
		c0.4,3.4-0.7,6-3.8,7.6c-2.8,1.4-4.7,3.8-4.9,6.7c-0.4,6.7-4.9,10.4-9.9,14.6c-0.4,3.5-0.8,7.4-1.2,10.9c-1.6,1.5-3.1,2.7-4.2,3.7
		c-0.4,2.5-0.8,4.5-1.2,6.8c1.1,1.7,2.3,3.6,3.3,5.1c0.5,2.5,0.5,4.9,1.3,6.9c1.4,3.4,3.2,6.6,4.8,9.7c-2,6.5-1.3,9.5,2.7,11.7
		c-1.3,5.7-0.3,7.6,5.4,9.8c0.9,1.3,1.7,2.6,2.7,3.9c-2.9,4.6,1.2,7,2.9,9.9c0,1.5,0.3,2.9-0.1,4.1c-1.4,4.4-1,8.8-0.1,13.2
		c0.4,2.1-0.2,4.5-0.3,6.7c0,3,0.1,6,0.1,9c-0.1,3.1-0.5,6.3-0.5,9.4c0,1.1,0.7,2.1,0.9,3.2c0.4,2.2,0.7,4.4,0.9,6.6
		c0.1,1.4,0,2.9,0,3.9c5.6,5.1,10.8,2.4,15.9-0.1c2.1,2.5,4.1,4.8,5.9,7c2.6,0,4.7,0,6.9,0c-0.1,2.6-0.3,5-0.4,7.8
		c2,1.6,3.9,3.3,5.9,4.9c0.1-0.2,0.2-0.3,0.3-0.5c0.7,1.4,1.3,2.8,1.8,3.9c1.9,0.5,3.4,0.8,4.9,1.2c1.1-0.6,2.1-1.2,3.2-1.8
		c0.4-1.3,0.5-3.1,2.5-3.8c1.9,6.1,2.1,6.5,4.8,9.5c1.5,0,3.1,0,4.5,0c2.1-1.8,4-3.5,5.8-5.1c1.5,0.5,2.7,0.9,4.3,1.4
		c4.6-2.1,9.4-4.2,15.1-6.7c6.3,0.8,11.5-2.6,15.4-8.2c1.7-1,3.2-1.8,4.7-2.8c2.4-1.7,4.6-4.2,7.2-5.2c4.3-1.5,7.4-4.2,9.7-8.1
		c3.3,0,6.5,0,10.2,0c2.2-3.2,4.4-6.4,6.7-9.6c2.4-3.3,4.3-7.4,9.5-6.9c0.5,0,1.1-0.6,1.6-0.9c3.8-2,7.4-4.1,11.3-5.9
		c5-2.3,9.9-4.5,12.6-9.7c0.4-0.8,1.2-1.5,2-2c6.6-3.6,10.1-9.8,13.4-16.2c0.3-0.6,0.2-1.5,0.6-1.8c3.4-2.1,3.8-5.7,4.4-9.1
		c0.7-3.8,1.7-7.5,4.3-10.4c1.6-1.8,2.5-3.7,2.8-5.9c0.6-5.2,2.8-9.8,5.5-14.1c2.3-3.7,3.4-7.6,2.7-12c1.5-2.5,3.1-5,4.6-7.5
		c1.1-4,2.1-8,3.2-12c0.5-1.8,1.4-3.5,1.9-5.3c0.7-2.4,2.3-5.1,1.7-7.2c-0.9-3.4,0.3-5.9,1.5-8.6c1-2.3,2-4.7,2.6-7.1
		c1-3.8,1.3-7.9,2.6-11.6c0.5-1.5,0.9-3,1-4.4c0.2-2.4-0.1-4.7-1.2-7.1c-3.8-3.4-7.5-6.7-11.8-10.6c-0.4-3-3.1-4.4-5.7-4.8
		c-2.7,0.4-4.7,0.6-6.7,0.9c-0.7-1.9-1.3-3.4-1.7-4.4c-3.9-2-7.5-3.8-11-5.6c-2.2-1.1-3.4-3.6-6.1-4.4c-1.9-0.6-2-2.8-1.7-5
		c0.5-3.7,3.1-6.2,4.6-9c-1.5-5.6-3-11-4.4-16.1c-3.4-2.3-6.7-4.5-9.9-6.7c-1.7-0.4-3.4-0.8-5-1.2c0-1.1-0.1-1.7,0-2.1
		c1.9-4.8,1.2-9-2.6-12.8c-1.7-1.7-3-3.1-5.7-2.1c-1.2,0.5-2.7,0.2-4.4,0.3c0.4-5.3-1.7-9.2-4.6-12.1c-0.8-7.8-1.5-15.1-2.2-22.6
		c0.2-0.2,1.2-0.5,1.4-1.2c2.2-5.9,0.5-11.7-0.7-17.6c-0.1-0.5-0.8-1-1.2-1.4c-3.9-3.6-5.9-8.3-7.4-13.2c-0.9-3-2.2-6.4-5-7.7
		c-4.3-1.9-6.7-5.2-9.5-8.6c-4.1-5.1-8.1-10.2-11.2-16.1c-1-2-2.1-3.9-3.2-6c0.5-1.2,1.4-2.5,1.6-3.8c0.7-5.7,2.8-11.2,2.1-17.1
		c-0.2-1.8,0.4-3.8,0.7-5.7c0.7-3.9,1.6-7.7,2.3-11.3c-2.3-3.1-5-4.5-8.1-4.7c-2.7-0.2-4.5-1.3-5.5-3.9c-0.4-0.9-1.1-2.1-1.7-2.2
		C1208.4,186.5,1206.8,182.4,1203.6,180.7z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1285.5,1129.8c2.7-1.4,4-3.7,5.3-6c2-3.6,3.7-7.4,6-12.1c-0.2-1.3-1-3.6-1-5.9c0.1-5.3-2.3-9.2-5.9-12.7
		c-2.9-2.9-5.3-6.4-5.6-10.3c-0.5-5.4-3.5-9-6.9-12.5c-0.3-5.5-3.3-10.9-7.7-14.3c0-2.2-0.6-4.7,0.1-6.7c1.2-3.7,0.6-6.9-1-10.5
		c-2.7-1.7-4.2-4.5-5.6-7.7c-1.8-4.1-4.8-7.6-10.5-8.6c1.3-1.4,2.3-2.3,3.1-3.4c3.9-5.4,7.9-10.8,11.6-16.3c1.3-2,2.1-4.4,2.1-7.1
		c0.1-6.9-3.5-12.5-6.4-18.3c-1.5-3-3.6-5.6-3.8-9.2c-0.2-4.2-1.5-8.1-4-11.6c-0.4-0.6-0.5-1.5-0.6-1.9c-2.7-2.4-5.1-4.5-7.5-6.7
		c-1.2-1.1-2-2.6-3.3-3.4c-3.8-2.4-6.5-5.6-8.9-9.2c-3.3-5-7.4-9.1-13.3-10.8c-1.9-0.6-4.5-0.9-6,0c-4.4,2.3-8.6,1.5-13,0.4
		c-1.5-0.4-3.2-1-4.6-0.7c-7,1.6-14,3.1-20.8,5.3c-5.6,1.9-11.1,1.1-16.6,1c-5.4-0.1-10.8-0.1-16.2,0c-4.3,0-8.1,3.1-12.6,2.3
		c-3.5-0.6-7-0.9-10.4-1.4c-4.4-0.7-8.4,0.1-11.3,3.7c-0.6-0.7-1.3-1.4-1.8-2.2c-2.1-3.3-4.6-4.9-8.8-3.3c-1.7,0.7-3.8,0.1-6,0.1
		c-1.8-1.8-3.9-3.5-5.5-5.5c-1.8-2.2-3.1-4.8-4.4-7c-2.6-1.3-5-2.4-7.4-3.6c-2.2-1.1-4.6-1.5-7-0.7c-2.5,0.8-5,1.9-7.6,2.6
		c-2.7,0.8-5,1.8-7,3.9c-2.3,2.4-5,4.4-7.3,6.3c-2.4-0.5-4.4-0.7-6.4-1.3c-4.3-1.3-7.5,0.2-10.1,3.6c-0.8,1-1.6,2.4-2.6,2.6
		c-2.2,0.5-2.9,2.2-4.1,3.7c-1.4,1.7-3.2,3-4.9,4.4c-1.1,0.9-2.4,1.5-3.5,2.4c-3.2,2.6-6.3,5.3-9.6,8c-0.5,0-1.2,0.2-1.8,0
		c-4.7-2.2-8.2,0.3-11.8,2.9c-1.7,1.2-4.1,2.8-5.9,2.6c-3.6-0.5-5,1-6.9,3.6c-2.4,3.3-5,7.1-9.6,7.4c-5.2,0.4-8.4,3.1-10.7,7.2
		c1.5,4.6,3,8.9,4.3,13.2c0.6,2.1,0.4,4.5,1.1,6.6c1,3.5,0.9,6.9-0.1,10.4c-0.4,1.3-0.6,3-0.2,4.2c1.9,5.8,1.6,11.3-2.2,16.3
		c-1.3,1.7-1.1,3.5,0.3,4.8c2.3,2.3,4.9,4.4,7.4,6.5c2.3,2.7,5.3,5.3,6,9.2c0.9,4.4-1.7,7.6-3.5,10.5c2,3.4,3.8,6.5,5.7,9.4
		c2.4,3.8-1.1,7-0.9,10.7c0.9,1,1.9,2.1,3.2,3.5c-0.6,3.3-1.3,6.7-1.9,9.5c2,3.5,3.8,6.7,5.9,10.4c4.4,1.6,5.6,3,6.2,7.8
		c0.7,5.5,1.4,11,2.2,16.7c1.6,0.1,3,0.2,4.5,0.3c1.2,1.3,2.6,2.5,3.7,3.9c0.9,1.3,1.5,2.8,2.3,4.1c6.9,2.2,8.2,2.7,10.9,5.4
		c10.2,0.3,10.2,0.3,14.4,5.1c2.1-0.5,4.5-0.8,6.7-1.6c2.8-1,5.1-0.6,7,1.5c2.1,2.3,2.3,4.9,0.7,7.5c-1,1.7-2,3.4-3,5
		c3.8,3.6,7.5,6.9,11.6,10.8c1.2-1.1,2.7-2.5,4.2-3.8c-1.6-3.3-3-6.1-4.5-9.1c1.9-1.5,3.5-2.7,4.8-3.7c2.5,0,4.8,0,7,0
		c1.3,0.8,2.4,1.8,3.8,2.3c2.8,1,5.9,2.6,8.7,2.2c5.1-0.7,8.3,2.1,11.8,4.3c0.2,3.1,0.3,5.8,0.4,8.8c3.9,0,7.4-0.1,11,0
		c3.8,0.1,6.6,2.2,8.2,5.1c4.5,1.4,8.5,2.7,12.6,4c4.4,5,6.8,10.9,7.6,17.2c5.8,3.6,5.8,3.6,10.4,3c2.2-6.8,6.4-11.4,14.6-9.9
		c2.5,2.5,5.9,4.3,5.5,7.9c3.2,2.6,5.9,4.8,8.8,7.2c0.1-0.1,0.5-0.3,0.9-0.6c3.2-3.1,6.3-6.4,9.7-9.1c1.8-1.4,4.3-2.5,6.5-2.5
		c3.6-0.1,7.2,0.7,11.3,1.2c0.6-0.8,1.6-2.2,2.9-3.4c1.1-1.1,2.6-2.5,3.9-2.5c3.5-0.2,7.1-0.1,10.4,0.7c2.7,0.6,5.1,1.5,7.8,0.2
		c3.2-1.5,6.5-1.1,9.8-0.1c1.3,0.4,2.5,1.5,3.8,1.7c4.7,0.7,8.7,2.8,12.3,5.8c1.8,1.5,3.8,2.1,6.2,2.1c2.5,0.1,5.2,0.4,7.5,1.3
		c3.5,1.4,6.7,3.3,10,5.1c1.2-5.6-0.6-10-4.5-13.6c-3.2-3-3.1-7-3-10.9c0-2.5,0.7-4.8,0.3-7.4c-0.4-2.2-0.1-5.2,1.2-6.8
		c2.8-3.5,4.4-7.4,6.7-11.1c3.2-5.1,6.5-10.1,10.1-15C1281.5,1132.9,1283.4,1130.9,1285.5,1129.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1167,1676.8c-2.8-8.4-7.4-15-16.4-17.7c-0.8-0.2-1.5-0.6-2.2-1c-4-2.3-7.9-4.8-11.9-7c-1.4-0.7-3-1.2-4.6-1.3
		c-3.6,0-6.4-1.9-9.1-3.9c-2.9-2.2-5.5-4.5-8.4-6.7c-2.3-1.7-4.5-3.8-7.1-4.7c-5.1-1.7-10.4-3.4-16.1-1.1c-2.2-1.2-4.8-1.9-6.7-3.6
		c-3.2-2.8-7.1-3.6-10.8-5.1c-2.1-0.8-4.3-1.1-6.5-1.8c-8.2-2.5-9.7-5-8.1-12.9c0.1-0.3,0.3-0.5,0.2-0.3c1.3-1.1,2.1-1.8,3-2.5
		c1-0.8,2-1.6,3.1-2.3c3-2,4-4,3.3-7.2c-0.7-3.1-2.8-5.7-5.7-5.8c-4.6-0.2-9.8-1.6-12.9,2c-3.5,0.6-6.2,1.1-8.8,1.6
		c-1.4,0.3-2.8,0.9-4.1,0.7c-5.3-0.8-10.6-2-15.2-1.5c-1.4-1.6-3.1-2.8-4.9-3.9c-5.4-3.3-10.8-6.7-16.2-10c-0.5-0.3-1.2-0.6-1.5-1.1
		c-2-3.4-5.6-5-8.6-7.2c-1.5-1.2-2-2.4-1.8-4.4c0.4-3.8-0.9-7.8-3.8-9.8c-5.1-3.5-6.4-8.4-7.4-13.8c-0.3-1.6-0.6-3.3-1.4-4.5
		c-3.8-5.7-4.4-12.4-6.5-17.3c-3.4-4.6-6.1-8.2-9-12.1c-4.2-0.5-7.7-3-11-5.9c-1.1-1-2.6-1.7-4-2.2c-0.5-0.2-1.1-0.3-1.6-0.4
		c-1.6-0.4-3.3-0.7-4.9-1c-1.9-2.5-4-4.8-6.7-6.2c-1.2-0.6-2.6-1-4.1-1.1c-6.4-0.5-11.2-4-14-10.1c-0.4-1-1.4-1.7-2.5-3
		c-0.9-4.6-2.8-9.8-2.1-15.4c0.1-0.4,0-1.1-0.3-1.4c-2.7-2.8-1.4-6-1.3-9.7c2.1-0.6,4.1-1.3,6.9-2.1c0.4-0.5,1.4-1.5,2.3-2.5
		c-1-4.5-0.3-9.7-6.6-10.6c-0.4-2.2-0.9-4.2-1.3-6.3c-1-0.6-2.1-1.3-3-2c-0.9-3-3.1-5.8,0.4-8.7c1.2-0.1,2.6-0.3,4.2-0.5
		c1.4-1.5,2.7-3.1,4.4-4.9c1.4,0.7,2.7,1.3,4.1,1.9c5.9-2.8,12-5.8,17.8-8.5c0.4-1.9,0.7-3.1,0.9-3.9c2.8-0.9,5.2-1.6,7.7-2.4
		c1.4,0.9,3.1,1.9,5.6,3.5c0-4.6,0-8.1,0-11c-1.2-2.6-2.1-4.6-2.9-6.2c0.5-2,0.8-3.5,1.2-5.1c-0.9-1-1.8-1.7-2.5-2.6
		c-3.4-4.5-3.5-6.8-0.4-11.7c0.5-0.8,1.1-1.6,1.6-2.4c0.7-1.3,1.4-2.7,2.3-4.5c-1.9-0.4-3.1-0.7-4.3-0.9c-1.7-0.3-3.6-0.3-5.2-1
		c-3.8-1.6-7.6-2-11.7-2c-1.9,0-4-0.1-5.6-1c-4.2-2.2-8.6-3.3-13.4-2.9c-5,0.4-8.1-2.5-10-6.8c-1-2.1-1.4-4.5-2.3-7.2
		c-2.3-0.4-4.7-0.9-7.2-1.3c-2.3-0.3-4.2,0.5-6.4,1.4c-3.5,1.4-7.4,1.7-11.1,2.4c-4.5,0.8-8.1,2.9-10.3,7.1c-0.8,1.5-1.8,3-2.6,4.2
		c-3.4,0.5-6.3,0.2-8.6-1.8c-2.6-2.2-5.8-2.8-8.6-3.7c0,3.5,0,6.7,0,9.7c-1.7,5.4-6.3,6.2-11.1,6.8c0.8,4.2,5.2,8,0.8,12.3
		c-8.7,0-17.3,0-26.2,0c-1.4-2.3-2.9-4.7-4.3-6.9c-1.7,2.2-3.5,4.4-5,6.4c-1.2,5.7-2.2,11.2-3.6,16.6c-1.4,5.1-4.3,6.7-10.6,6.1
		c-1.1-1.7-2.3-3.9-3.9-5.9c-2.5-3.2-4.8-7-8.1-9.2c-3.9-2.5-6.1-5.7-8-10.5c-0.9,2.2-1.8,3.8-2.1,5.4c-0.7,4.3-2.4,7.9-6,10.5
		c-1.5,1.2-3.1,2.2-4.6,3.5c-4.4,3.8-9.1,5.9-14.8,3c-5.1-2.6-10-1.6-14.5,1.3c-2.4,1.6-4.6,2.7-8.1,0.9c-0.2,2.5-0.4,4.2-0.6,6
		c4.9,2.7,6.3,7.5,7.9,12c0.9,2.6,1.4,5.9,0.7,8.4c-1.2,4.3-3.6,8-9,8.8c-3.4,0.5-6.1,2.9-8.9,5.6c1.9,1.6,3.3,2.8,4.8,3.9
		c3,2.3,4.7,5.1,4.7,9c-0.1,4.1-1.5,7.8-2.9,11.5c-0.8,2-1.4,4.4-0.9,6.5c0.7,3.5,2.3,6.9,3.5,10.2c3.9,1.5,7.5,2.9,12.3,4.7
		c1.8-0.2,4.8-0.8,7.9-0.8c2.8,0,5.8,0.3,7.2,3.3c1.1,8.1-5.4,13.3-7.6,20.2c1.6,0.1,3.4-0.1,5.2-0.5c0.7-0.2,1.5-0.4,2.2-0.7
		c0.4-0.1,0.7-0.3,1.1-0.4c9-3.8,19.1-13.6,24.7-24.7c3.6-1.6,7.3-3.1,10.9-4.7c1.5,2.4,4,0.7,6.3,1.9c3,1.5,6.1,2.7,8.9,4.4
		c3,1.8,5.7,4.2,8.7,6.2c1.2,0.8,2.7,1.3,4,1.3c2.7,0.1,5.5-0.3,7.7-0.4c2.7,2.2,4.8,5.3,7.5,6c4,0.9,7.4,2.6,10.8,5.4
		c-1.6,4,0.5,7.8,2.5,11.1c2.4,4,4.8,7.6,3.9,12.1c5.1,9.3,3.8,19,4.2,28.4c0.4,0.5,0.7,0.9,1.2,1.3c2.9,2.4,6.8,1.4,9.9,2.7
		c0.7,2.9,2.1,6.1,4,9c1.8,2.7,3.9,5.2,6,6.8c0.4,1.9,0.8,3.7,1.2,5.7c5.2,0,10.3-0.4,14.6,3.5c4.3-2.8,6.5,1.5,9.6,2.5
		c0,2.9,0,5.4,0,8.4c3.3,2.4,7.1,4.7,10.2,7.6c2.8,2.7,6.7,4,8.1,8.4c1.1,3.3,4.9,4.6,8.7,4.5c0.3,3.4,1.4,6.4,4.9,7.6
		c6.4,2.1,10.6,7.9,16.8,10.3c0.5,0.2,0.7,1,1,1.5c0.8,1.2,1.6,2.4,2.5,3.6c3.3,4.1,7.5,4.6,11.9,1.7c0.8-0.5,1.6-0.9,2.6-1.5
		c4.8,5.4,10.1,3.4,15.9,0.8c1.3,2,2.3,4,3.7,5.5c1.6,1.7,3.6,3.1,5.4,4.6c1.4,3.1,0.5,7.8,4.9,9.2c3.4,1.1,7,1.6,10.7,2.4
		c0.7,3.7,4.2,5.5,6.9,8c3.7,0,7.4,0,11,0c2.6,3,5.1,5.9,7.2,8.4c-0.4,3.3-0.7,5.9-0.9,8c4.3,5.7,9.1,9.1,15.4,9.2
		c1.5,1.8,2.7,3.2,3.6,4.2c4.1-0.5,7.6-0.8,11.4-1.3c0.5,0.7,1.3,1.6,1.7,2.5c1.1,3,1.7,6.1,3,9c2.2,5.1,4,10.6,9.3,13.2
		c0.7,3,0.8,6.1,2.1,8.5c3.5,6.9,7.5,13.6,11.3,20.4c0.6,1,0.5,2.4,0.7,3.3c-0.7,0.8-1,1.1-1.3,1.5c-2.2,3.5-6.6,4-9.4,7
		c-3.1,3.3-3.6,6.5-2.5,10c0.9,2.8,0.5,5-0.7,7.4c-0.8,1.6-1.5,3.1-2.2,4.5c-1.1,0.6-2.1,1.2-3.5,1.9c0.4,4.6,0.7,9.2,1.1,14
		c2.6,1.6,5.1,3.2,7.7,4.8c1.5-0.3,3.1-0.2,4.2-0.9c2.6-1.7,5.1-3.7,7.4-5.9c1.8-1.7-0.6-3.9,0.3-5.8c3.6-7.5,12.2-10.5,16-17.2
		c0.4-3.6,1.5-6.9,0.9-9.9c-0.8-4-0.7-7.6,0.5-10.7c2.4-2.1,4.3-3.8,5.9-5.2c6.4-1.8,13.8,1.3,18.3-5.3c-3.1-5.5-3.8-7.4-3.9-12.5
		c-0.1-1.6,0-3.2-0.2-4.8c-0.7-5.6-1.4-11.3-7.9-13.7c-3.8,0.1-7.4-0.4-11.2-2.6c-2.2-1.3-4.1-1.5-6.3-0.3c-1-0.9-1.8-1.6-2.7-2.5
		c2.2-5.6,4.3-11,6.4-16.5c0.4-1.3,0.3-2.7,0.2-4.2c-0.2-4.3,1.1-8.1,3.6-11.6c2-2.7,3.7-5.6,5.6-8.4c0.4-0.6,1.1-1,1.7-1.5
		c1.8,1.2,3.3,3.2,4.9,3.2c4,0,6.6,2.9,10.1,3.7c2.3,0.6,4.7,0.9,6.9,1.7c3.6,1.4,7.2-0.5,10.4,0.2c4.5,3.2,6.9,7.5,8.4,12.3
		c1,3,2.4,5.3,5.9,6.1c2.3,0.5,4.4,1.9,6.4,3.2c5,3,6.5,2.3,8.3-3.5c0.6-2,1.9-3.8,2.9-5.6c0.8-2,1-4.1-0.8-6.1
		C1168.1,1680.3,1167.6,1678.5,1167,1676.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M357.9,801.5c5.5-1.1,10.2-2.1,14.9-3.1c2.8-2.7,5.5-5.4,8.2-8.1c1.4-1.4,2.8-1.8,4.6-0.7
		c-1.8,5.8-5.9,9-10.3,10.5c-1.9,2.7-3.3,4.6-4.6,6.5c-0.8,1.2-2,2.3-2.3,3.6c-0.9,4-3.1,7.3-5.1,10.8c0,0,0,0,0,0c0,0,0,0,0,0
		l0.1-0.1c-0.4,1.5-0.6,3.1-1.3,4.5c-1.1,2.1-2.5,3.9-3.7,5.7c2.1,3.9,0.9,7.2-0.6,10.4c-1.3,2.9-2.8,5.7-3.6,8.8
		c-0.5,1.8,0.2,4,0.8,6c0.2,0.8,1.7,1.2,2.4,1.6c7-5.3,8.2-13.6,12.2-21.1c0.8,5.6,1.4,10.2,2.1,15.7c2.3-1.7,3.9-2.8,5-3.7
		c0-4.4,0-8.1,0-11.9c-1.6-0.7-3.1-1.4-5.2-2.3c2.2-2,4.1-3.8,5.2-4.9c2.4-0.4,4.1-0.2,5.3-1c1.8-1.2,3.4-1.3,4.8-0.9
		c-1,4.2-1.9,8.2-2.6,11.3c1,2.5,1.8,4.4,2.6,6.4c-1.3,1.9-2.3,4-3.8,5.5c-4.1,4.1-7.2,8.5-5.9,14.7c-3.8,3.3-6.3,7.1-6.1,12.1
		c-0.9,0.6-1.7,1.2-2.4,1.8c0,1.4,0,2.8,0,4.7c3.4,1,6.7,2,11,3.4c0.9,1.6,2.3,4,3.7,6.5c3,1.9,5.6,1.1,8.2-1.1
		c1.7-1.4,3.7-2.4,5.7-3.7c8.2,7.1,16,3.7,24.1-0.4c-1.1,1.8-2.2,3.7-3.8,4.4c-3.4,1.4-5.3,3.9-6.5,7.1c-0.4,1-0.5,2.3-1.2,3.1
		c-2.6,2.6-2.4,5.8-2.6,9.5c2.6,1.4,5.1,2.8,7.6,4.2c-1.5,5.5-3,10.8,3,15.3c2.5-0.7,5.3-1.5,8.2-2.4c0.2,0.4,0.6,1.1,0.4,1.3
		c-2.2,3.3-4.4,6.6-6.7,10.1c-0.2,3.9,0.6,8-1.6,12c-1.1,2-0.6,4.7,1.4,6.3c-1.5,4.2-0.4,7.8,1.4,11.3c0.6,1.3,1.4,2.5,0.9,2.9
		c-2.4-1.8-4.5-3.4-6.8-5c-1.5,0.2-3.1,0.3-4.7,0.5c-5.1-2.7-9.9-6.7-17.1-7.2c-1.4,0.9-4.2,1.7-5.1,3.5c-2.1,4.1-5.5,5.8-9.7,7.3
		c-3.4-2.2-6.3-0.3-9.1,1.5c-2,1.3-3.8,2.8-5.7,4.3c-1.6,0-3,0-4.3,0c-4.2,4.3-5.1,7.8-2.9,11.5c2.7,0,5.9,0.7,8.5-0.2
		c4.3-1.4,8.1-0.6,12.3,0.8c0.9,3.7-0.1,7.5-2,10.2c-2.5,3.6-3.9,7.2-4.1,11.1c-2,1.7-4.2,2.9-5.2,4.7c-1.3,2.2-2.9,3.3-5.2,3.5
		c-7.1,0.8-14.3,1.5-21.7,2.4c-2.7,1.3-5.9,2.8-8.7,4.2c-3.7,7.9-3.5,9.5,2.1,14.7c1.4,0,3,0,4.6,0c-0.6,3.5,2.1,4.7,3.8,6.6
		c3.4,0.4,6.6,0.8,10,0.4c2.5-0.3,5.2,1.2,7.8,1.8c0.2,0.6,0.4,1.2,0.6,1.8s0.5,1,0.8,1.5c0.2,0.2,0.3,0.4,0.5,0.6
		c1.9,2.2,4.7,3.1,7.7,3.7c5.2-1.8,6.4-1.6,8.6,4c0.3,0.8,0.6,1.7,1,2.8c2.2,1.7,4.1,3.3,6.6,5.3c3.4-3.4,6.8,0.4,10.7,0.7
		c3.2-4.5,8.4-4.8,12.9-6.7c1.6-0.7,3.6-0.4,4,2c-5.7,4.3-14.1,4.9-16.4,13.1c-3.9,0.6-7.1-0.3-10.3-1.9c-1.2-0.6-2.8-0.5-4.3-0.7
		c-2.1-4.7-6.1-7.3-10.7-6.8c-1.6,0.2-3.1,0.8-4.7,0.8c-4.2,0.1-7.8,1.2-10.2,5c-4.2-0.6-7.8,0.5-9.5,4.2c-1.2,2.5-2.7,4.2-5.2,5.5
		c0.1,4.2-3.4,6-5.9,8.6c-6.7-0.2-9.7,4.8-13,9.6c-4.9,0.1-8.4,2.4-10.2,7.1c-1.9,0-3.7-0.3-5.4,0.1c-5.8,1.1-12.4,1-13.9,8.8
		c-0.1,0.3-0.1,0.6-0.2,0.9c1,1.5,1.9,2.9,2.9,4.3c0.1-0.2,0.2-0.3,0.4-0.5c3.6,1.9,7.1,3.8,10.8,5.8c2.4-1.1,4.8-2.1,7.4,0.3
		c0.8,0.8,3.1,0.5,4.5-0.1c1.6-0.6,2.8-2,4.2-3.1c2.8-2.3,5-5.4,9.1-5.3c0.8-1,1.5-1.9,1.9-2.3c4.2-1.4,7.9,1.3,11.7-0.3
		c0.4-0.2,1.1,0.4,1.7,0.6c1.5,0.4,3,1.1,4.5,1.2c3.4,0.1,5.7,1.9,7.6,4.4c0.5,0.7,1.2,1.4,2,1.8c4.2,2,9.5,1.2,12.6-2.1
		c1.3-1.4,2.2-3.4,3-5.2c1-2.3,1.7-4.7,2.3-6.5c3.7-1.9,6.7-3.4,9.9-5c2.1,0.2,4.4,0.4,7.3,0.6c2.7,2.5,6.4,4.3,10.3,6.1
		c4.3,2,8,3.9,12.8,2.2c2.1-0.8,4.2-1.3,6.5-2c3.8,1.9,7.6,1.1,11.1-0.9c1.4,0.5,2.5,1,3.6,1.4c-0.4,5.1,0.2,5.9,6,8.1
		c4.6-1.2,9.5-1.6,11.4-6.5c0.7-0.1,1-0.1,1.3-0.1c3,0.6,5.7,2.4,9,1.1c0.8-0.3,2.3,0.4,3,1c2.8,2.4,6,2.1,9.1,1.3
		c2.9-0.7,5.4-1,8.2,0.7c1.6,1,4.3,0.5,6.5,0.2c3.9-0.6,7.6-2.5,11.6-0.6c0.7,0.3,2,0,2.8-0.4c3.3-1.8,6.5-3.7,9.8-5.6
		c0.9-0.6,2-0.7,3.1-0.6c4,0.4,7.3-1.4,9.9-4c3.5-3.4,7.1-6.4,11.6-7.8c0.9-1.4,1.7-2.6,2.5-3.8c0-1.9,0-3.9,0-5.9
		c-0.4-0.5-0.7-1.1-1.2-1.5c-4.3-2.8-8.5-5.7-14.1-5.3c-3.1,0.2-6.2,0.2-8.8-2.4c2.5-1.7,4.7-3.2,6.6-4.5c0.1-2.2,0.2-3.8,0.3-5.9
		c2.5,0,4.7,0,7.2,0c1.7-1.6,3.6-3.4,5.6-5.4c3.3,0.3,5.1-2.3,7.2-4.6c2.5-2.8,3.7-7.1,8.5-7.6c0.7-0.1,1.4-1.2,2-2
		c2.3-3.3,3.3-7.2,2.1-10.9c-1.1-3.6-0.4-6.5,1-9.6c0.6-1.3,1.2-2.8,1-4.1c-0.4-1.7-0.8-3.2,0-5c0.3-0.7,0-2-0.4-2.8
		c-1.9-3.5-4.1-7.1-7.9-8.5c-3.7-1.4-7-4.2-11.3-3.4c-0.6,0.1-1.4-0.6-2.1-0.9c-3.5-1.4-7-2.8-10.5-4.1c-4.3-1.5-5.8-0.9-8.3,2.9
		c-0.9,1.3-1.6,2.7-2.3,3.9c-2.2,0.6-4.1,1.2-6.3,1.8c-0.7-1-1.4-2-2-2.9c2-1.8,4.4-3.1,5.3-5.1c1.9-4.1,3.8-8.5,4.3-13
		c0.5-5-0.3-10.2-1.3-15.1c-0.5-2.7-2.6-5.1-4-7.6c0.5-1.2,1-2.5,1.5-3.8c-2.7-5.2-2-11-3.6-16.1c1-3.5,3.6-6.4,2.6-9.6
		c-1.4-1.3-2.2-2.4-3.2-3.1c-1.3-0.9-2.8-1.6-4.6-2.6c0.9-5.8-3.6-9.7-6.1-14.5c-2.9-5.6-8.6-7.1-14-8.4c-1.3-2.1-2.6-3.9-3.6-5.9
		c-1.7-3.3-3-6.6-0.9-10.2c0.4-0.7,0.8-1.7,0.6-2.3c-1.8-4.1,0.2-8.2,0.1-12.2c-0.1-3.5,0.4-7-0.2-10.3c-0.7-4-1.6-8.2-3.6-11.7
		c-1.3-2.2-2.6-4.4-2.9-6.8c-0.3-3.3-1.8-5.8-3.9-8c-1.8-2-4.1-3.5-5.9-5.5c-3.3-3.4-6.4-7.1-11.9-6.4c-1,0.1-2.1-0.5-3.2-0.9
		c-4-1.5-7.8-1.7-11.4,1.2c-1.2-0.6-2.3-1.1-3.3-1.6c0.5-0.9,0.8-1.6,1.2-2.2c1.5-0.2,2.9-0.3,4.3-0.7c3.6-1.1,7.2-2.4,10.9-3.4
		c2-0.6,3.5-1.5,4-3.6c0.3-1.4,0.6-2.8,0.8-4.2c-1-1.2-2-2.2-3-3.3c0.8-0.5,1.6-0.9,2.2-1.4c2.9-2.8,7.8-4,7.8-9.3
		c0-0.7,1.1-1.7,1.9-2c3.2-1.5,4.7-4.3,5.9-7.3c2.1-5.4,5.2-10.2,8.9-14.6c1.5-1.8,3.8-3.1,4.8-5.1c3.7-6.9,7.5-13.8,6-22.2
		c-3.2-3.5-10.2-5.8-14.6-4.9c-1.4,0.3-2.9,0.3-4.2,0.1c-3.1-0.6-6.1-1.5-9.1-2.3c-3.6-1.6-7-3.2-11.3-1.5c-1.4,0.5-3.5-0.1-5-0.8
		c-2.7-1.1-4.8-1.1-7.7,0.1c-3.2,1.3-6.4,4.4-10.8,2.2c0.7-0.5,1.2-1.2,1.6-1.2c2.8,0.1,4.4-1.3,5.5-3.8c1.7-4,3.6-8,5.4-12
		c2.8-1.6,5.9-3.1,8.8-4.9c4.3-2.8,7.9-6.3,9.6-11.3c0.2-0.6,0.6-1.3,1.1-1.5c4.2-2.2,6-6.2,7.8-10.9c-1.8-2-3.8-4.1-5.3-5.8
		c-3.7-0.5-6.7-1.2-9.7-1.2c-3.2,0-6.3,0.5-9.5,0.8c-0.8,0.1-1.5,0.5-2.2,0.8c-5.1,2.4-10.3,3.1-15.7,0.9
		c-2.5-4.8-4.6-10.2-12.8-9.1c-1.8,1.7-4.3,4-6.7,6.2c0.2,0.3,0.4,0.6,0.6,0.9c0.3,1.8,0.5,3.7,0.8,5.6c-3.2,1.6-7,2.7-6.7,7.5
		c0,0.4-0.5,0.8-0.8,1.1c-3.8,3.7-5.1,8.3-4.9,13.3c-3.1,3.1-6.9,1-10.5,2c-1.8,2.3-3.6,4.8-5.8,7.6c-0.1,1.2-0.2,2.7-0.3,4.2
		c-5.9,3.2-6.3,10.3,0.3,15.9c-0.6,3.9-1,8-3.4,11.4c-2.6,0.8-5.3,1.4-6.3,4.1c-0.7,1.8-0.7,3.8-1.1,6.4c-1.4,1.1-3.4,2.6-5.7,4.4
		c-0.9,0-2.1-0.3-3.2,0.1c-2.2,0.7-4.9,1-5.8,3.6c-1.1,3.3-3.1,5.8-5.2,8.5c-1.8,2.2-1.9,4.7,0,7.5
		C353.2,799,355.6,800.5,357.9,801.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1548.3,1345.6c-0.8-2.4-2.2-4.7-0.5-7c-2.6-5-3.1-10.7-7-14.1c-5.8-1.4-10.7-1.3-13.9,3.8
		c-7.2-1.6-11.1,3-15.1,7.2c-2,2.1-3.7,4.6-6,7.5c-2.8-4.4-7.3-5.8-11.8-7.3c-1.2-0.4-2.2-1.2-3.4-1.8c-2-0.9-2.9-2.4-2.9-4.6
		c0-0.9-0.1-1.9-0.2-3c-2.5-2.6-4.9-5.2-7.4-7.8c1.2-2.2,3.7-4.7,3.3-6.6c-0.6-3.4,1.1-6.2,0.5-9.2c4-3,3.4-6.8,2.4-10.6
		c-1-4.2-3-8.1-2.5-12.6c0.1-0.9-0.4-1.9-0.9-2.7c-2.7-4.8-5.3-9.7-8.1-14.4c-2.8-4.7-7-7.7-12.3-9c-3.9-0.9-7-2.9-8.7-6.7
		c-1.1-2.4-2.6-4.6-4-6.9c-4.5,0.7-6.8-1.6-8.7-4.5c-0.8-1.2-1.6-2.4-2.5-3.5c-4.8-5.5-9.6-11.1-14.7-16.3c-1.7-1.7-4.2-2.7-6.6-3.6
		c-1.9-0.8-4-1-6-1.5c-2.5,0.1-4.9-0.1-7.4-0.3c-1.4-0.1-3-0.4-4.2,0.2c-4.5,2.2-8.5,5.1-10.2,9.6c-1.6,1-3.2,1.5-3.9,2.6
		c-2.1,3.1-5.2,3.8-8.4,3.7c-3.8,0-6.5,1.6-8.9,4c-4.1,4.1-9,6.9-14,9.6c-2.4,1.3-4.8,2.8-7.6,4.5c-0.6-0.7-1.6-1.9-2.5-3.1
		c-1.9-2.8-5.4-4.2-8.3-3c-2.1,0.8-4.3,1.7-5.9,3.2c-5.1,4.6-10.5,5.7-15.6,3c-4.3,0-7.4,0-10.7,0c-1.3-1.1-2.6-2.3-4.2-3.7
		c-2,0.3-4.2,0.6-5.5,0.8c-3.4-0.8-6.1-1.4-8.3-1.9c-3.6,6.6-6.5,12.2-9.7,17.7c-3.4,5.7-9,9-14.6,12c-2.4,1.3-4.1,2.6-4.8,5.4
		c-1.1,4.4-3.4,8.6-4.2,13.1c-1.1,6.5-5.1,11.5-7.9,17.2c0,0.4-0.1,1,0.1,1.4c1.7,3.7,0.8,6.7-1.9,9.7c-1.9,2.1-3.8,4.7-4.4,7.4
		c-1.1,4.9-3,9.8-2.6,15c0.1,1.9-0.3,4.3-1.4,5.9c-1.5,2.3-4,4.1-5.8,6.2c-3.2,3.6-6.5,6.7-11.7,5.8c-1.7,5-5.1,7.3-9.9,7.9
		c2.2,3.6,5.8,5.2,9.3,6.4c1.8,0.7,3,1.5,4,3c3.2,4.5,5.9,9.2,4.9,15.2c1.7,0.5,3.1,0.8,4.4,1.3c2.5,1,5,2,7.3,3.3
		c3.2,1.8,6.2,3.9,9.2,5.9c2.5,1.6,5.2,3.1,7.4,5.1c1.1,0.9,1.8,2.8,1.7,4.3c-0.1,2.9-0.8,5.9-1.2,8.7c4.8,2.9,9.9,6.1,15.3,9.4
		c2.4,0,5.1,0,8,0c1.2-1.5,2.4-3,3.8-4.8c2.1,0,4.5,0,6.9,0c5.4-0.1,9.3,3.3,13.5,5.9c0.7,0.4,1,1.6,1.2,2.5c0.2,1.2,0,2.6,0,3.5
		c-1.8,2.1-3.3,3.9-5.1,6c5.4,1.5,10.4,3,15.4,4.4c2.4,0.7,3.9,2,4.1,4.3c-0.7,2.6-1.3,5.1-1.9,7.5c1.3,0.7,2.4,1.3,3.5,1.9
		c3.5,1.8,6.9,2.6,10.2-0.5c0.8-0.7,2-1.5,3-1.5c5.1-0.1,10.3-1.1,14.4,1.2c6.7,0,12.6,0,17.5,0c3.1-1.4,5.1-2.3,6.4-2.9
		c4.5,0.6,8.3,1.3,12.1,1.4c2.7,0.1,5.4-0.7,8.1-0.8c3.9-0.1,7.8,0.2,11.6-0.1c6.9-0.5,13.2-2.1,16.4-9.3c0.9-1.9,2.9-3.3,4.4-4.8
		c1.8-1.8,3.9-3.3,5.4-5.2c3.5-4.7,7.4-8.4,13.4-10c3.8-1,6.9-2.9,9.4-6.5c2.5-3.6,6.2-6.8,11.8-5c1.5,0.5,3.5-0.2,5.1-0.8
		c4.9-1.7,9.9-1.5,14.5,0.4c3.4,1.4,6.8,1.6,10.4,1.6c2.7,0,5.3,0.4,8,0.6c1.4,2.6,2.6,4.8,3.9,7.1c6.7,0,13.3,0.1,19.9,0
		c1.3,0,2.6-0.7,4.4-1.2c-0.6-3.3-1.1-6.1-1.6-8.9c-0.6-3.4-1.5-6.8-1.6-10.3c-0.1-3,0.7-6,1.1-8.8c-1.2-1-2-1.7-3-2.6
		c-0.4-5.7,2.8-10.3,4.8-15.1c0.2-3.4,0.5-6.7,0.7-10.2c-1.2-0.9-2.3-1.8-3.4-2.6c1.1-2.3,2.8-2.8,5.1-2.1c1.6,1.9,3.1,3.8,4.5,5.4
		c2.3,0.1,3.8,0,4.8-2.1c1.1-2.2,2.5-4.3,4-6.2c1.6-1.9,3.5-3.5,5.3-5.3C1549.2,1350.6,1549.1,1348,1548.3,1345.6z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1330.7,747c0.1-5.2,0.3-10.1-4.8-12.9c-2.8-1.1-6.5,1.2-8.9-2.1c0.7-3.2,1.4-6.1,2.1-9.2
		c-1-1.6-2-3.2-2.9-4.7c0-3.9,0-7.6,0-10.8c-1.2-2.3-2-4.2-3.1-5.9c-1.1-1.7-2.5-3.2-4-4.6c-1.5-1.5-3.2-2.8-4.9-4.2
		c0.3-0.2,0.6-0.4,0.9-0.5c-0.3-2.6-0.5-5.2-0.7-7.5c-6.1-3.6-9.1-8.8-10.9-14.7c-0.9-3-2.3-6.3,0-9.7c2.6-0.8,5.5-1.7,8.7-2.7
		c0.8-2,1.8-3.9,2.4-6c1.6-5.8,3.1-11.6,4.7-17.6c-0.4-2-0.8-4.2-1.2-6.3c-1.8-2.8-4.9-3.5-7.5-5c-2.7-1.5-5-1.5-7.6,0.1
		c-2.3,1.4-4.6,2.9-7.1,3.4c-3.6,0.8-7.3,0.7-11,1.1c-1.6,0.2-3.2,0.4-4.7,0.8c-3,0.9-5.9,2.1-9.2,1.2c-1.3-0.3-2.8,0.3-4.2,0.6
		c-3.6,0.7-7.1,2.1-10,0.6c-5.2,2.7-9.8,5.3-14.6,7.5c-1.7,0.8-3.9,1.2-5.8,0.9c-3.8-0.6-6.7,0.8-9,3.4c-2.9,3.4-7.5,5.5-8.6,10.5
		c-0.1,0.3-0.4,0.5-0.7,0.7c-4.2,3.2-8.1,6.9-14.1,5.6c-1-0.2-2.2,0.2-3.5,0.3c-0.6,3.4-2.1,6.6-3.9,9.1c-1.7,2.5-2.7,4.6-2.2,7.3
		c0.5,2.7,1.3,5.2,3.7,7c1.9,1.4,3.4,3.2,4.8,4.6c-0.7,4.3-1.4,8-2,11.5c1.8,2.4,3.8,4.2,4.9,6.4c1.6,3.2,3.5,5.8,6,8.4
		c3.1,3.4,6.5,4.9,10.7,4.5c2-0.9,2.7-3.3,5.5-3c0.5,3,1.1,5.9,1.7,9.5c3,2.3,1.3,5.5,0.4,8c0.5,2.5,1.6,4.8,1,6.2
		c-1.5,3.7-0.6,6.4,1.9,9c0.6,0.6,0.8,1.7,1.1,2.4c-1,4-0.9,7.7-0.5,11.6c0.4,3.4-0.1,7-2.9,9.9c-3.6,3.7-7.5,4.3-11.6,1.5
		c-2.8-2.2-5.6-4.5-8.4-6.7c-1-0.8-2-1.7-2.6-2.7c-1.3-2.2-2.3-4.5-3-5.9c-3.7-1.9-6.5-3.4-9.8-5.1c-1.2,0-2.9,0-4.8,0
		c-0.3-1-0.9-1.9-0.9-2.7c-0.2-4-3-6-6.1-7.4c-3-1.3-5.6,0.1-7.7,2.4c-3.2,3.3-6.2,6.8-10.2,9.4c-1.2,0.8-2.1,2.4-2.8,3.8
		c-1.9,3.3-3.6,6.7-5.4,10c-1.1,3-1.8,6.3-1.8,9.7c0,3.4,0.1,6.6-1.7,9.8c-1.1,1.8-1.1,4.3-1.5,5.9c-2.4,2.7-4.4,5.1-6.3,7.3
		c0,1.9,0.2,4.6,0.6,7.5c0.1,0.7,0.2,1.3,0.3,2c0.2,1,0.3,2.1,0.5,3.1c0.1,0.7,0.3,1.4,0.4,2.1c0.1,0.4,0.1,0.7,0.2,1
		c1.1,5.2,2.4,10.1,3.5,12.4c-0.9,3-0.5,5.8,0.3,8.4c0.1,0.3,0.2,0.7,0.3,1c0.5,1.3,1,2.6,1.5,3.8c0.4,0.9,0.7,1.9,1,2.8
		c6.3-8.5,10.8-18.9,22.2-23.5c2.2-3.8,6.4-5.6,10.6-6.9c2.3-0.7,5.2,0.2,7.8,0.4c1.4-0.1,2.7-1,4-1.9c4.5-3.2,9.1-4.2,14.4-1.8
		c2.5,1.2,5.4,1.9,8.2,2.1c3,0.2,6-0.5,9-0.5c3.7,0,7-0.6,9.8-3.1c1.7,0,3.3,0,4.9,0c2.8-3.7,6.4-3.2,9.4-2.2c2.3-2.5,4.3-4.6,6-6.4
		c2.1,0,3.6,0,4.9,0c1.3,0.9,2.9,1.5,3.5,2.6c1.9,3.3,5.3,3.9,8.5,5c2.7,0.9,5.6,1.3,8.1,2.5c2.8,1.4,5.4,2.5,8.6,2.5
		c1.3,0,2.8,0.7,4,1.3c2.4,1.3,4.6,2.9,6.9,4.3c1.5-0.3,2.9-0.5,4.5-0.8c1.9,1.4,3.9,2.8,5.7,4.1c0.5,1.5,0.9,2.9,1.3,4.3
		c4.4,0.2,8.5,0.4,12.8,0.7c2.6-0.8,5.5-1.5,8.2-2.7c4.4-2.1,9.6-2.7,13-6.8c1.7-2,3.8-3.7,5.7-5.5c2.9-2.7,5.8-5.4,8.5-7.8
		c0.5-3.1,0.9-5.8,1.4-8.6c-3.2-3.8-1.9-7.7,0.2-11.5c1.5-2.9,1-6.6-1.3-8.8c-1.8-1.8-3.8-3.3-5.7-5c-0.2,0.3-0.3,0.6-0.5,0.9
		c-2.3-0.9-5.2-1.3-6.8-2.9c-1.8-1.8-2.6-4.6-3.5-7.1c-1.3-3.6-2.3-7.2-3.4-10.8C1334.2,750,1332.4,748.5,1330.7,747z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1518.9,1430.4c-0.6,0-1.2-0.1-1.8-0.1c-4.5-0.2-8.9-0.2-13.4-0.6c-1.2-0.1-2.6-1.2-3.5-2.2
		c-1.4-1.6-2.4-3.6-3.6-5.3c-0.4-0.1-0.7-0.2-1.1-0.2c-6.1,0.3-12.2,0.5-17.6-3.3c-1.1-0.8-3.2-0.6-4.7-0.4c-4.5,0.7-9,1.6-13.6,2.5
		c-0.6-0.2-1.3,0.2-1.6,0.7c-3.7,5.8-8.9,9.1-15.6,10.8c-2.6,0.7-5.1,2.9-6.8,5c-2.6,3.4-4.9,6.8-9,8.7c-1,0.5-1.9,1.8-2.3,2.9
		c-1.2,3.8-4.5,5.9-7.3,8.1c-1.8,1.4-4.2,2.1-6.5,2.7c-2.6,0.7-5.3,1.1-8,1.4c-1.7,0.3-3.6,0.8-5.2,0.4c-4.7-1.1-9.2-1.4-13.9,0
		c-2.2,0.6-5-0.4-7.5-0.8c-1.4-0.2-2.7-0.7-4.3-1.2c-3.4,3.1-7.5,3.3-11.7,3.2c-2.2-0.1-4.5,0.3-6.7,0.3c-3.4-0.1-6.8,0.7-10.1-0.5
		c-4-1.5-7.8-0.9-11.6,0.8c-7.2,3.2-16.8,0.8-22.5-5.8c0.4-2.3,0.9-5,1.5-8c-2.9-0.9-5.8-1.8-9-2.8c0,2.8,0.3,5.2-0.1,7.6
		c-0.4,2.3-1.7,4.5-2.4,6.8c-0.4,1.3-0.7,3-0.2,4.2c1.3,3.3,0.9,6.5,0.4,9.6c1.9,1.9,4.4,3.3,5.3,5.5c1.8,4.1,4.9,6.6,8.4,8.8
		c3.6,2.3,6.6,4.9,7.1,9.5c0.1,0.9,0.6,1.8,1,2.7c3,6.4,2.5,8.4-2.5,13.6c-2.3,2.4-4.5,5-6.7,7.5c-1.7,1.9-2,3.9-0.9,6.2
		c2.5,5.1,1.5,9.9-1.3,14.6c-0.8,1.4-1.2,3.2-1.7,4.8c4.1,2.6,7.7,4.8,11.3,7.1c1.7,1.1,3.3,2.5,5.1,3.3c3.9,1.9,7.8,3.6,10.2,7.6
		c0,0.7,0,1.5,0,2.3c0.1,4.8,0.5,9.6,2.4,14.2c1.1,2.6,2.2,5.1,3.5,7.9c1.1-0.3,2.4-0.8,3.8-1.1c1.9-0.4,3.8-1,5.6-0.7
		c4,0.7,7.2,0,9.9-3c0.7-0.8,1.8-1.3,3.3-2.2c1.4,0.3,2.8,0.5,4.2,0.9c3.7,1,6.8,0.2,9.9-2.2c2.6-2.1,4.7-4.6,7-6.9
		c1.8-1.7,4.1-4,6.2-3.9c4.6,0.2,9.7-0.2,13.6,3.5c2,1.9,4.3,2.3,7.1,1.7c4.2-1,8.5-0.9,12.8,0.2c4.8,1.2,9.7,1.7,12.8,2.3
		c6.5-3.2,11.5-5.4,16.2-7.9c3.6-1.9,7.5-3.8,6.9-9c-2.6-2-5.1-4-7.7-6c0-2,0-4.1,0-5.8c3.2-4.5,8.2-4.9,12.5-6.1
		c3.2-0.9,5.5-2.2,6.8-5c5-0.6,7.8-3.8,10.1-7.8c4.2-0.2,7.7-1.8,9.7-5.8c0.6-1.2,2-2,2.9-2.9c10.3,4.1,24.4,4.5,30.5-3.3
		c0-1.6,0.1-3.2,0-4.8c-0.2-4.8-1-9.3-6.6-11c-0.6-0.2-1-0.9-1.5-1.2c-4.4-3-8.9-6-13.4-9.1c1.5-0.6,3.1-1.2,4.7-1.8
		c1.1-2.2,1.9-4.6,3.4-6.3c3.5-3.8,3.6-8,2.1-12.5c-1.5-4.6-3.2-9.1-4.9-14c1.7-1.9,4-3.9,5.5-6.4c1.6-2.5,2.4-5.4,3.6-8.3
		c5.2,0.7,9.8-0.1,11.7-5.6c0-2.9,0-5.8,0-8.4C1525.5,1430.4,1522.2,1430.4,1518.9,1430.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1409.2,1770.3c0-1.8,0-3.6,0-5.4c-2.8-3.1-3.8-6.9-4-11c-0.2-2.7-0.4-5.3-0.5-7.3c-2.1-1.9-3.6-3.3-4.6-4.2
		c-5.1-0.9-9.6-1.7-13.8-2.4c-1.1-1.9-1.9-3.3-2.9-4.9c-2.7-0.4-5.5-0.8-9.1-1.3c-0.7-1.5-1.6-3.5-2.7-5.7
		c-3.2-2.6-7.5-1.3-10.7-1.8c-1.9-2-3.3-3.4-4.6-4.8c-5,1.4-9.7,1.6-15.6,0.7c7.9-2.1,16.1-2.5,16.1-13.2c-2.5-2.3-5.3-4.8-8.4-7.6
		c0-0.8,0-2.2,0-3.6c1.6-0.3,2.9-0.6,3.7-0.7c4.6,3.3,8.9,5.7,14.3,6.2c0.8-1.6,1.5-2.8,2.1-3.9c-0.9-2.2-1.5-4.2-2.7-5.9
		c-1.2-1.8-2.7-3.9-4.5-4.6c-3-1.1-5-2.8-6.8-5.3c-1.6-2.1-3.6-3.9-5.5-5.7c-1.3-1.3-2.8-2.5-4.5-4c-0.1-0.8-0.4-2.2-0.7-3.8
		c-3.8-3.3-7.6-6.7-11-9.7c-0.4-6.2-0.4-11.3-1.1-16.3c-0.8-5.9,2.3-9.9,5.6-15.1c0,2.9-0.3,4.9,0.1,6.8c0.5,2.1,1.5,4.2,2.4,6.2
		c2.4,1.1,4.2,2.8,6.3,4.2c2.2,1.5,4.5,2.8,6.5,4.1c3.1-1.6,5.7-2.9,8.1-4.1c2.1,0.4,4,0.7,5.3,0.9c3.9-2.7,7.3-5.1,10.6-7.5
		c3.3-2.5,4.6-6.1,4.6-10.1c0-3.8-3.4-4.6-6-5.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-1.6-1.3-3.3-2-5.2c3.8,0.4,7.3,0.8,10.8,1.1
		c1.3-1.7,2-3.5,3.3-4.1c3.7-1.7,4.5-5.1,5.4-8c3.8-2.6,7.1-4.9,10.2-7.1c2.1,1.3,4,2.5,5.1,3.1c5.6-2.5,10.3-4.6,15.1-6.7
		c5.3,3.1,10.4-2.2,15.5,0.1c1.6-1,2.9-1.9,4.3-2.8c3.9,3.9,9.1,0.6,13.6,2.5c1.7,3.3,5.1,3.7,8.3,4.5c3.7-1.6,6.1-4.1,6.4-8.3
		c0.1-1.4,0.6-2.8,1.2-4.1c1.8-4,2.1-8.1,0.8-11.9c0.5-6.6,7.4-8,9.4-12.9c0.2-5.5-2.4-9.2-6.8-11.9c-2-1.2-4-2.4-6.1-3.6
		c-2.9,1.7-6,3.5-9.2,5.4c0.8,0.6,1.6,1.2,2.4,1.7c4.5,3.1,6.1,9.5,2.8,13.8c-2.6,3.4-5.4,6.7-9.8,8.2c-4,1.4-8.1,3-11.7,5.2
		c-1.9,1.1-3.7,1.9-5.8,1.6c-5.6-0.6-11.1-1.4-16.7-1.9c-2.8-0.2-5.8-0.1-8.6,0.4c-4.2,0.8-7.8,0.4-11-2.9
		c-4.1-4.1-10.6-3.7-14.6,0.2c-3.1,3-6.4,5.8-9.7,8.5c-2.6,2.1-5.8,3-9,1.8c-2.5-0.9-4.1-0.2-6.2,1.1c-2.2,1.4-4.9,3.1-7.3,3
		c-6.1-0.2-11.7,1.4-17.4,2.6c-3.3,0.7-5.8,2.1-8,4.7c-3.2,3.8-6.9,7.1-12,8.2c-3.6,0.8-7.2,1.2-10.8,2.1c-3.7,0.9-6.7,2.9-7.7,7.1
		c-0.3,1.2-0.9,2.3-1.3,3.3c-3.6,1.7-7,3-9.7,5.7c-2.5,2.5-5.9,3.7-9.6,3.5c-4.1-0.2-7.8,1.1-11,3.4c0,2.6,0.3,5.2-0.1,7.6
		c-1,6.1-4.3,11.5-6.3,17.3c-0.9,2.4-1.7,4.7-2,7.3c-0.4,2.8-1.3,5.7-3.7,7.4c-2.7,1.9-5.7,3.3-8.6,5c0,2.3,0,4.4,0,6.5
		c0,2.2,0,4.5,0,6.7c-0.7,0.7-1.3,1.3-1.8,1.9c-6.9,9-6.1,5.9-2.6,15.6c1,2.9,2.8,4.6,5.5,5.4c2.4,0.6,3.8,2,5,4
		c0.5,1,1.2,1.9,1.9,3c4.8,0.4,9.5,3.7,14.3-0.2c3.1,1.1,6.1,2.1,9.4,3.2c-1.3,1.2-2.2,2-3.1,2.8c-0.4-0.2-0.9-0.4-1.3-0.5
		c-3-1.1-5.8-0.7-8.5,0.9c-0.9,1.8-1.3,2.7-1.2,4.1c0.1,1.1,0.5,2.5,1.1,4.9c0.1,0.4,0.2,0.8,0.3,1.2c1.5,0.4,3.2,0.9,5,1.3
		c5.2,5.3,7.8,12.4,10.9,18.8c3.4,0,6.3,0,9.3,0c0.7-1.7,1.4-3.2,2.3-5.1c1.8,1.2,3.4,2.3,5.1,3.5c3.3-1.2,6.8-2.4,10.6-3.8
		c-0.8,3.3-1.4,5.8-2,8.4c-6.4-1.1-14.9,2.2-16.1,10.5c-2.1,3.6-6.9,4.8-7.1,8.2c0.7,5.4,3.4,6.9,7.2,7.9c3.8,0.9,7.9,1.9,10.9,4.7
		c1.3,1.2,2.7,1.9,4.5,2.2c2.7,0.5,5.2,1.5,5.7,5c0.1,1,1.1,1.9,1.1,1.9c0,3.2,0,5.3,0,8.1c-3.3,3.2-3.4,7.4-0.5,11.7
		c1.6,1.8,3.3,3.6,4.9,5.4c-0.1,0.1-0.2,0.2-0.4,0.3c1.2,0.7,2.5,1.4,3.1,1.8c1.3,2.5,2.3,4.5,3.4,6.7c2.6-0.8,4.6-1.5,6.7-2.1
		c-0.2-4.4-3.1-8.3,0.6-11.8c7.7-1.9,12.2,2.2,15.2,8.2c0.5,2.4,0.6,4.7,1.5,6.7c0.8,1.9,1.9,3.6,2,5.8c0,1.9,1.1,3,3.5,2.2
		c1.6-4.1,2.8-8.4,1.9-13c3.5-5.4,6.6-5.5,11.2-0.8c1.3,1.3,2.9,2.2,4.8,3.6c0.7,1.1,1.7,2.7,2.7,4.3c2,0.4,3.8,0.8,6.3,1.4
		c0-1.6,0.2-2.8-0.1-3.9c-0.4-1.7-1.4-3.2-1.8-4.9c-0.6-2.3-1.8-4.9-1.3-6.9c1-4,0.2-7.1-2.1-10.3c-1-1.4-1.4-3.2-2.1-4.8
		c-0.6-1.3-1-2.9-2.1-3.7c-5.7-4.5-7.8-11.5-12.1-17.2c4.2,0.3,8.3,0,12,2.7c0.9,2.9,1.8,5.9,2.8,9c1.4,0,2.6,0,4.1,0
		c2.4-2.7,4.7-5.5,7.8-9c0.8-0.2,2.6-0.7,5-1.3c-0.4-1.7-0.6-3.4-1.2-4.9c-0.5-1.5-3-1.3-3.1-3.6c-0.1-1.5-1.3-3-1.8-4
		c-3.2,0.2-5.6,0.4-8.4,0.6c0.3-2.2,0.6-3.7,0.8-5.6c-2.8-2-5.6-4-8.9-6.4c3.3-2.4,8.3-1.3,10.4-4.9c1.6,0.3,3,0.5,4.3,0.7
		c1.8-1.5,3.5-2.9,5.7-4.7c3.1,2.1,7,3.7,9.1,6.6c2.3,3.2,5.3,4.4,8.4,6C1406.5,1771.3,1407.9,1771.4,1409.2,1770.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1247.5,1284.7c0.8-3.7,3-7.1,3.6-10.9c0.9-5.5,4.1-8.3,8.9-10.2c8.3-3.3,13.4-9.7,16.6-18.1
		c-6.5-4.5-13.2-8.1-20.1-10.6c-7.4,2.8-14.5,5.4-21.7,8.1c-1.9-1.2-4.4-2-5.4-3.7c-1.9-3.2-4.7-4.7-7.4-6.2c-2.1,0-3.8,0-6.1,0
		c-1.3,1.2-2.8,2.6-4.2,4c-2.5,2.5-5.2,3.9-9,3c-2.1-0.5-4.5-0.1-6.8-0.1c-4.9,2.7-9.9,5.7-13.9,9.7c-2.7,2.8-5.2,5.6-8.9,7.7
		c-3.3-0.4-6.9-0.8-10.3-1.2c-3.2,5.3-7.8,8.4-11.5,11.2c-4.5,0.6-8.1,1.2-12.1,1.8c-1.1,4.1-2.1,8-3.3,12.6
		c-0.6,0.4-1.7,1.2-2.9,2.1c-3.7,3-7.6,3.6-12.5,2.9c-6.1-0.9-12.6-0.9-18.8-0.1c-5.7,0.8-10.1-0.6-14.4-4.2
		c-2.3-1.9-4.5-5.2-8.1-3.5c-0.6,4-1.1,7.6-1.6,11.3c-0.6,4.8-1.7,5.9-6.4,5.7c-1.7-0.1-3.4-0.6-5-1.3c-2.3-1-4.5-0.8-7.1-0.1
		c1.3,1.7,2.3,2.8,3.2,4.1c3.5,5,3.4,9.2-0.1,14.4c-1.1,1.7-2.1,3.5-3.1,5.2c4.8,11.9,1.4,13.6-7.4,20.1c2.7,4.2,5.2,8.4,8,12.4
		c1.6,2.3,3.3,5.2,5.6,6.1c4.7,2,8.4,5.2,12.3,8.2c1.6,1.3,3.6,2.5,4.6,4.2c1.9,3.4,4.9,5.1,8.3,6c2,0.5,3.5,1.4,4.7,2.9
		c1.1,1.3,2.4,2.6,3.6,4c6.1-0.1,12.3-0.6,16.8,4.6c3.3,0.5,6.6,1.8,9.6,0.2c4.5-2.4,9.5-3.5,13.5-7c3.8-3.3,8-6.1,13.3-7
		c1.8-0.3,3.3-2.4,4.9-3.7c1.5-1.3,2.9-2.9,4.5-4c1.7-1.1,3.6-2.2,5.5-2.7c5.8-1.5,10.9-5.5,17.5-4.4c1.4,0.9,2.9,1.8,4.4,2.8
		c6.2-3.5,13-2.9,19.9-3.1c2-5.2,5-8.5,10.8-7.7c3.1-3,6.2-5.8,9.5-9c-1.4-5.8,2.1-11.3,2.4-17c0.3-6.8,6.1-9.8,8.1-14.8
		c-0.7-2.3-1.2-4.1-1.8-5.9c0.3-0.6,0.5-1.2,0.9-1.7C1242.9,1296.7,1246,1291.2,1247.5,1284.7z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1243.6,1403.7c-3.5-2.1-7.1-4.1-10.5-6.3c-1.9-1.2-3.6-2.3-6-2.5c-4-0.3-5.8-2.3-6.9-6.5
		c-1.1-4.4-1.1-9.2-5.2-12.3c-2.9-1.6-6-3-8.5-5.1c-2.6-2.3-4.6-5.2-7.3-8.4c-4.1,1-8.2,2-12.4,3.1c-1-0.8-2.1-1.8-3.5-3
		c-2.1,0.5-4.5,0.9-6.7,1.5c-1.5,0.4-3,1-4.3,1.8c-3.8,2.2-8.6,2.5-11.4,6.4c-2,2.8-5,4.3-8.3,5.2c-1.7,0.5-3.3,1.3-5.9,2.4
		c4.2,1.6,4.9,4.4,4.7,7.4c-0.2,3.5,0.7,6.6,2.5,9.6c0.5,0.8,1,1.7,1.3,2.6c1.6,5.2,4.3,9.7,9.3,13.1c2,0,4.4,0,6.7,0
		c1.1,6-1,8.3-9.5,10.2c3.4,3.9,7.8,1.1,12.3,2.9c0.7,0.7,2.4,2,3.4,3.7c1.3,2.2,1.7,4.5-0.1,7.1c-2.4,3.6-4.9,7.5-3.4,12
		c1.4,4.3,1.1,8.1-1,12c0.9,2.3,0.5,5.2,3.1,6.7c2.9,1.7,5.7,3.3,8.8,5.1c0.9,2.2,1.8,4.5,3,7.4c-2.5,1.3-4.7,2.5-7.4,3.9
		c5.1,4.5,8,9.6,4.4,16.4c-1.3,0.5-2.8,1-4.7,1.6c2.4,3,4,6.3,6.6,8c4.5,2.9,9.6,4.9,14.5,7.3c1.5,0,3.1,0,4.9,0c1,1.2,2,2.4,2.5,3
		c2.1,0.2,3.9-0.1,5.1,0.6c3.4,2,6.5,4.4,9.7,6.6c-0.2,1.4-0.3,2.3-0.5,3.2c-0.8,3.8-3.8,6.1-6,8.9c-3.6,4.7-3.1,9.3,1.6,13.6
		c1.4-0.3,2.9-0.6,4.3-0.8c8.6,6.6,8.3,5.9,11.3,12.1c3.4,0.3,4.6,2.7,4.9,5.9c0.2,2.6,0.7,5.1,1.1,7.6c1.9-1.6,3.4-3.4,3.9-5.4
		c1.6-5.6,4.9-9.3,10.3-11.8c4.6-2.1,8.7-2.7,13.3-0.1c1.7-0.8,4-1.3,5.4-2.7c4-4,7.8-8.1,13.8-8.8c0.3,0,0.5-0.5,0.8-0.6
		c4.6-1.4,9.3-4.4,13.8,0.4c0.1,0.2,0.6,0,0.8,0c1.9-4.9,3.8-9.7,5.8-14.6c-0.8-1.9-1.4-3.8-2.4-5.6c-1.1-2.2-1.4-4.3,0.1-6.3
		c1.7-2.5,3.5-4.9,5.5-7.2c2.6-3.1,6.2-5.4,7.7-9.4c-2.4-0.6-2.4-2.5-2.7-4.2c-0.6-3.7-2.7-6.1-5.9-8.1c-3-1.8-5.7-4.2-8.1-6.7
		c-2.7-2.8-5-6-7.7-9.2c-0.5-4.7-1.1-9.7-1.8-15.8c1.8-4.4,5.7-9.5,3.9-16.4c-0.9-0.4-2-0.9-3.1-1.4c-0.5-1.5-1-3-1.7-4.9
		c1.7-2.5,3.5-5,5-7.2c-3.4-4.5-8.2-4.1-11.9-4.7c-2.8,2.6-5.2,4.7-7.9,7.2c-3-2.4-6.4-2.5-9.9-2.8c-1.8-0.1-4.1-1.1-5.1-2.4
		c-2.6-3.7-6.8-4.6-10.2-6.7c-1.7-1-2.7-1.9-3.1-3.2c0.7-3.9,1.4-7.5,2-10.8C1247.8,1406.6,1245.8,1405.1,1243.6,1403.7z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M178.2,985.5c-8.1,2.8-10.2,7-2.6,15c0.1,1.9-0.3,3.6,0.4,4.6c1.9,3.2,3.2,6.9,6.7,9.4
		c1.9-1.2,3.7-2.3,5.9-3.7c0.2,4.4,2.7,6.5,6.1,8.1c1.7,0.8,3.1,2.1,4.7,3.1c1,0.6,2.2,1.2,3.3,1.6c3.8,1.5,7-0.2,9.8-2
		c0.5,0,0.7,0,0.8,0.1c9.7,3.6,10.4,5,18.3-3.4c2.7-2.9,6.6-4.2,10.9-2.9c3.5,1.1,6.8,0.6,10.1-1.6c2.4-1.6,4.7-4,8.2-3.6
		c0.9,0.1,2-0.7,2.8-1.3c2-1.7,3.8-2.2,6.5-1c4.4,1.8,9.2,3.1,14,2c3.2-0.8,6-0.4,8.6,1.2c2.1,1.3,4,1,5.9-0.1
		c5.3-3,8.4-7.6,9.7-13.5c0.4-2.1,1.1-3.8,3-5.1c3-2,4.7-5.1,5.7-8.6c1.3-4.7,2-9.4,4.6-13.8c2.2-3.7,3.8-8.2,3.1-12.8
		c-0.5-3.2-1.7-6.4-2.7-10.3c0.2-0.5,0.6-1.8,1.4-2.7c3.6-4,4.2-6.1,1.8-10.5c-1.2-2.2-1.5-4.5-1.5-6.9c0-1.6-0.5-3.3-0.8-4.8
		c-1.4-0.2-2.2-0.3-3-0.4c0.1-0.9,0.2-1.7,0.4-2.8c-1.2,0-2.3,0-3.3,0c0.1-1.4-0.5-2.1-1.9-2c-0.3-0.7-0.6-1.3-0.9-2
		c0.3-0.5,0.9-1.1,0.8-1.5c-0.3-2-0.9-3.9-1.3-5.9c-0.4-0.1-0.9-0.2-1.4-0.3c0.3-2.8-2.1-4.3-3.2-6.5c0.3-1.4-0.1-2.2-1.7-2.2
		c-0.1-0.3-0.2-0.6-0.2-0.9c-0.3-0.7-0.6-1.4-0.9-2.1c-1.2,0.8-2.1,1.4-3,2c0.1-1.8-0.1-3.4-2.2-4c0.7-0.7,1.3-1.3,2-2
		c3.6,0,6.9,0.5,7.6,5c0.9,0,1.6,0,2.2,0c1.1,1.5,2.2,3,3.2,4.4c0.2,0.4,0.4,0.9,0.6,1.3c0.4,2.2,0.9,4.5,1.3,6.7
		c-0.6,0.9-0.8,1.7-0.8,2.5c0,1.3,0.9,2.5,2.5,3.4c0.5,0.1,1.1,0.3,1.6,0.5c0.9,0.3,1.8,0.6,2.5,0.3c2.6-0.8,4.6,0.2,6.8,1
		c1-0.3,2.1-0.7,3.2-0.8c1.1-0.2,2.2-0.2,3.1-0.3c4.3-4.1,4.7-4.3,10.4-4.9c1.7-1.3,3.3-2.4,4.8-3.6c4.1-3,5.8-9.9,3.7-14.2
		c-1.1-2.4-1.9-4.9-2.9-7.3c0.2-0.1,0.4-0.2,0.6-0.3c-0.9-0.8-1.8-1.6-2.6-2.3c3.1-6.4,4.4-12.5,0.2-18.5c-2.1-1-4-1.9-5.9-2.8
		c0-3.7,0-6.9,0-9.7c-2.3-2.8-4.5-4.1-7.5-3.9c-1.3,0.1-2.7-0.7-4-1.1c-1-0.3-2.1-0.8-3.1-0.8c-4.7-0.3-8.5,1.6-11.5,5.2
		c-1.6,2-2.8,4.3-4.3,6.2c-1.4,1.7-2.9,3.4-4.7,4.6c-1.3,0.9-2.8,1.7-4.2,2.4c-1.4,0.7-2.9,1.5-4.3,2.2c-0.4-0.4-0.9-0.7-1.3-1.1
		c-0.6,0.9-1.2,1.7-1.7,2.6c-0.6,0.4-1.2,0.8-1.9,1.2v0c-0.6,0.1-1.2,0.2-2.3,0.4c0.7,0.8,1,1.2,1.3,1.6c0,0.3,0,0.6-0.1,1
		c-1.5-0.1-2,0.7-1.8,2.1c-0.6,0.6-1.3,1.2-1.9,1.9v0c-1.2-0.2-2.4-0.3-4.1-0.6c-0.5,0.9-1.2,2.1-1.8,3.3c-1.2,0.6-2.5,1.2-3.7,1.8
		c0.4,1,0.6,1.5,0.9,2.1c0.4,0.3,0.7,0.7,1.1,1c0.3,0,0.5,0,0.8,0.2c0.2,1.8,0.5,3.5,0.7,5.5c-0.5,0.4-1.1,0.8-1.7,1.2
		c-0.6-0.7-1.2-1.4-1.9-2.1c-0.1-1.9-1-3-3.1-2.6c-1-1.7-2-3.4-2.9-5.1c-0.1-0.3,0-0.6,0.1-0.9c2.5,0.2,2-1.6,2-3.1l0.3,0l0.3,0
		c2.2,0.5,3.3-0.4,3.4-2.6c0.3,0,0.6-0.1,1-0.1c0.1,0.2,0.2,0.4,0.4,0.6c0.2-0.2,0.3-0.5,0.5-0.7c0.6,0,1.3,0.2,1.8,0.1
		c0.2,0,0.3,0,0.4-0.1c0.3-0.1,0.5-0.3,0.6-0.6c0.1-0.3,0.2-0.6,0.2-1c0.9-0.4,1.8-0.8,2.6-1.2c3.6-0.9,3.9-3.8,4.1-6.8
		c1-0.6,2-1.2,2.9-1.8c2.7,1,4.5,0.4,5.8-2l0,0c1.7-0.4,3.4-0.6,4.9-1.2c0.4-0.1,0.4-1.3,0.6-2l0.2,0.2l0-0.1c0,0-0.1-0.1-0.1-0.1
		l0,0c1-0.2,1.8-0.5,2.3-0.8c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.5,0.7-0.9c0.4-0.8,0.5-2,0.6-4c1.4-1.7,2.8-3.5,3.9-4.9
		c-1.3-3.7-2.5-6.9-3.5-9.7c-3.7-1.1-6.9-1.9-10.1-3c-2-0.7-3.8-0.8-5.4,0.5c-2.6,2.1-5.2,4.2-8.4,6.7c-0.6,0-2.2,0.4-3.5-0.1
		c-3.7-1.4-6.9-0.5-9.8,1.7c-3,2.3-4.9,5.5-4.8,9.5c0,1.1-0.2,2.2-0.2,2.9c-3.2,1.2-6,2.2-8.6,3.2c-1,2.3-3.6,3.8-2.4,6.9
		c2,2.1,4.1,4.4,6.4,6.8c-0.1,1.3-0.2,2.7-0.3,4.2c-3.5,1.6-6.8,3.1-10.1,4.6c-0.4,0.2-1,0.3-1.4,0.2c-3.9-1.1-8.4-0.8-10.2-5.7
		c-0.5-1.3-1.6-2.4-2.5-3.8c-4.1-1.5-8.3-3.1-13.5-5c-0.4,0-1.8,0-2.2,0c-2.6,1.3-4.2,2.1-5.9,3c-1.4,0.7-2.9,1.4-4.2,2.3
		c-2.9,2.2-4.8,5.1-4.8,8.9c0,2.8,0.3,5.5,0.5,8.3c-0.4,0.1-0.7,0.3-1.1,0.4c1.3,1.7,2.4,3.8,4.1,5.1c1.6,1.3,3.8,1.9,6,3
		c-0.5,1-0.9,1.8-1.5,3c-2.2-0.3-4.4-0.7-6.6-1c-2.5,2.1-4.9,4.4-7.6,6.4c-6.4,4.7-7.1,9-2.7,15.6c1.3,0.1,2.6,0.1,3.9,0.2
		c1.2,0.1,2.4,0.4,3.3,0.5c0.7,3.1,1.2,5.7,1.9,8.4c3.2,0.3,5.9,0.8,8.6,0.7c3.7-0.1,6.9,0.8,9.7,3c2.2,0,4.3,0,6.4,0
		c-1.7,1.1-3.5,1.5-5.2,1.9c-4.9,1.2-9.8,3.2-11.7,8c-2.1,5-4.9,8.8-9.8,11.2c-4.2-1-6.1,0.3-9.4,6.4c-2.3,1.9-4.4,4-6.4,6.2
		c-1,1.1-1.7,2.6-2.6,4.1c-3.6-2.2-6.9,0.3-10.4,1c-3.6,0.7-7.4,1-9.7,4.3c0.7,1.4,1.4,2.7,1.9,3.7C174,982.8,176,984,178.2,985.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M858.5,1351.4c4.6-10.8,13.9-12.3,23.9-12.8c3.8-3.7,8.6-3.2,13.4-2.2c1.7,0.4,3.4,0.8,6.2,1.5
		c1.5,3.6,3.4,8,5.1,11.9c2.3,0.9,3.9,1.5,5.5,2.1c2.6-1,5.1-0.8,7.8,0.2c3.8,1.3,7.6,2.7,11.5,3c4,0.3,7.9,0.8,11.7,2.3
		c1.3,0.5,2.8,0.6,4.2,0.7c1.7,0.2,3.3,0.2,4.9,1.3c1.7,1.1,4.2,1.4,6.3,1.2c4-0.2,7.9-1.1,11.8-1.5c1.1-0.1,2.2,0.2,3.3,0.5
		c2.1,0.7,4.2,1.6,6.3,2.2c4,1.2,8.1,1.9,11.8-0.6c2-2.2,3.9-4.8,6.5-6.2c4.7-2.5,9.8-4.3,14.2-6.3c4.9,1.5,9.4,2.9,13.7,4.2
		c3.6-3.2,7-6.1,10.4-9c2.6-0.6,5.3-1.2,7.9-1.8c2.5-4,2.6-10.1,8.9-10.5c0.6-1.1,0.9-2.2,1.6-3c1.2-1.4,1-2.8,0.5-4.5
		c-0.9-2.7-1.4-5.5-1.9-7.8c2.4-3.3,4.3-5.9,6-8.3c-0.6-4.3-2.4-7-5.1-9.5c-1.5-1.3-4.2-3.7-1.4-6.9c0.6-0.2,1.6-0.4,2.3-0.9
		c4.3-3.5,8.7-3,13.1-0.2c0.9,0.6,2.3,0.6,4.1,1c0-5.5,0-10.3,0-15.4c-3.7-4.3-7.3-8.6-10.4-12.1c-2-6.6,1.1-11.4,2.8-17
		c-3-2-5.8-3.9-8.8-5.9c-0.9,4.4-5.4,1.5-7.3,3.8c-1.8-0.8-3.6-1.3-5-2.1c-2.8-1.7-5.8-1.9-8.7-0.8c-5.1,1.8-10,1.2-13.9-2.3
		c-4.3-3.9-8.9-5-14.3-3.6c-5.2,1.3-10.2,2.6-11.3,9.2c-0.2,1.3-1.7,2.7-2.9,3.4c-1.6,1-3.6,1.2-5.4,1.9c-3.5,1.4-7.5,0.7-10.6,4.3
		c-2.7,3.1-7.3,3.7-11.4,0.8c-1.7-1.2-3.7-1.8-5.6-2.6c-0.9,1.5-1.6,2.7-2,3.5c-4.7,2.8-10.2,1.9-13.7,5.4c-1.1,2.9-2,5.4-3.1,8.4
		c-1.5,0.5-3.1,1.4-4.7,1.4c-5.7-0.1-9.2,2.6-11.4,7.6c2.4,4.2,4.8,8.3,7.2,12.4c-0.7,2-1.4,3.7-2,5.6c0.9,1.2,1.8,2.5,2.8,3.8
		c0,2.1,0.1,4.1,0,6.1c-0.1,1.6-0.3,3.2-0.8,4.7c-0.6,2.2-2.1,3.6-4.5,3.8c-2.3,0.1-3.9-1.1-4.9-3.1c-0.9-1.7-1.8-3.3-2.6-4.8
		c-3.9,0.8-7.5,1.6-10.5,2.2c-1.9-1.7-3.2-2.9-4.1-3.8c-2.9,1.6-5.6,3-9,4.8c-2.5,3.7-5.1,4.5-10.9,3.7c-2.3-0.3-4.9,0-7,0.8
		c-4.5,1.9-8.7,4.5-13.1,6.5c-2.1,0.9-4.5,1-6.6,1.4c-4.3-8-6.3-9.1-14.3-8.5c-1.4,2.4-2.6,5-4.2,7.4c-3.6,5.4-8.7,6.3-14,2.6
		c-0.6-0.4-1.3-0.8-2-1.3c-2.8-2.1-5.6-4.2-8.4-6.3c-1.1-0.8-2.1-1.6-3-0.5c0.6,2.8,1.7,5.3,1.4,7.6c-0.3,2.4-2,4.6-3,6.7
		c1.1,2.7,2,5,3,7.4c4.1,0.2,7.4,1.5,9.3,4.9c6.4,0.4,13-3.5,18.6,1.9C848.7,1345.3,852.9,1349.8,858.5,1351.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1032.7,1145.8c-2.4,0.1-4.8,0-7.1,0c-1.1-1.4-1.9-2.5-3.1-4c-2.1-0.2-4.4-0.5-6.8-0.8
		c-1.6-0.2-3.5-0.2-4.6-1.1c-3.3-2.8-7.2-3.8-11.2-4.8c-0.4-0.1-0.8-0.5-1.3-0.9c-1-2.2-2-4.5-3.1-6.8c-5.8,1.1-10,4-11.9,9.4
		c-6.8,0.4-8.2-0.9-10.2-9.3c-1,0.1-2,0.2-3,0.3c-0.1,2.7-0.3,5.1-0.4,7.9c-4.9,1.8-9.8,3.6-14.6,5.5c-1.5,0.6-2.9,1.7-4.3,2.5
		c-4.9,2.6-9.7,5.4-14.7,7.7c-1.9,0.9-3.2,1.6-4.2,3.5c-0.9,2-2.5,3.6-3.6,5.2c-1.9,0.3-3.3,0.5-4.7,0.7c-1.5,0.3-3.1,0.6-4,0.8
		c-1.8,2.4-2.9,5.2-4.9,6.2c-2.4,1.3-5.5,1.2-7,1.5c-3.1,1.6-5.3,2.7-7.1,3.7c-2.3-1-3.7-2.7-6.4-2.8c0.4,1,0.6,1.8,0.9,1.9
		c3.4,1,5.5,3.6,7.8,6c0.8,0.8,2.2,1.2,3.4,1.6c3.7,1.4,5.2,3.5,4.3,7.1c-1.2,4.8-1.8,9.6,0.9,14.4c4.6,1.7,3.8,7.2,6.7,10.4
		c6,0.8,7.7,5.9,9.9,10.5c6.4,0.6,9.9,4.8,12.6,9.4c8.6,3.1,14.7,8.6,17.9,15.7c3.2,2,5.6,3.5,8,5.1c2.5,1.6,5.1,1.2,7.7,1
		c0.5-1.6,0.8-2.9,1.3-4.6c6.3,0.2,12.2-0.6,17.3-4.1c0.3-7.1,3.1-8.5,8.6-10.8c1-0.4,2.1-0.8,3.1-1.2c7-2.2,13.5-2,19.2,3.4
		c2.6,2.5,5.4,3.4,9.1,2c5.4-2.1,10.7-1.5,15,2.5c3.8-0.8,7.3-1.6,11-2.4c1.8,0.8,3.6,1.6,5.6,2.5c4.2-4.7,7.6-10.4,14.4-12.3
		c3.7,2.3,6.9,0.4,9.3-1.3c3.1-0.7,5.3-1.2,7.2-1.7c0.6-2.7,0.7-4.9,1.5-6.8c1.5-3.7,3.4-7.2,5-10.3c2.5-1.8,4.5-3.3,5.9-4.3
		c0.8-2.4,1.5-4,1.9-5.7c0.9-3.8,3.5-5.4,7.2-5.9c1.5-0.2,2.9-1.4,4.9-2.4c-1.9-4-3.6-7.4-5.1-10.6c-5.8-2.4-13.1-1.4-16.1-7.9
		c-3.3-0.5-6.3-1.8-8.6-1.1c-3.8,1.3-6.4-0.6-9.1-1.4c-0.6-0.6-1-1.3-1.3-1.9c-1-2.7,1.3-5.5,0.3-8c-1.6-1.5-3.4-1.7-5.4-1.7
		c-0.3,0-0.6,0-0.9,0c-5.1,0.1-9.9-0.7-13.5-4.6c-1.7,0-3.3,0-4.9,0c2.8,5.9,2.8,5.9,2.4,10.3c-1.5,1.3-3.2,2.8-4.9,4.3
		c-4.3,3.9-4.9,3.8-8.6-0.7c-1.9-2.3-3.3-5.1-6.2-6.2c-3.5-1.3-5.5-3.6-6.1-7.4c1.6-2.8,3.2-5.7,4.8-8.7
		C1036.3,1142.9,1034.8,1145.7,1032.7,1145.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1324.3,855.4c0.5-0.4,0.9-1,1.2-1.5c0.9-1.9,0.4-4.3-1.2-5.7c-2.3-1.9-4.8-3.7-7.6-5.9c0.6-2.4,1.3-4.8,2-7.5
		c-1.8-1.8-4.5-2.9-7.2-1.9c-3.1,1.2-5.6,0.2-8.1-0.7c-1-2.6-2-4.9-3-7.3c-5,0.6-9.2-0.3-12.9-3.3c-1.1-0.9-2.9-1.2-4.5-1.5
		c-3.1-0.6-6.3-0.8-8.8-3.2c-1-0.9-2.6-1.2-4-1.4c-4.8-0.7-9.3-2.1-12.8-5.7c-1.1-1.1-2.6-1.7-4.1-2.7c-2,2.3-3.9,4.4-5.7,6.5
		c-0.1-0.3-0.3-0.5-0.4-0.8c-4.8,0.9-9.6,1.9-14.8,2.9c-2.3,2.3-5.5,2.8-9,2.6c-3.2-0.2-6.4-0.5-9.3,1.5c-0.9,0.6-2.6,0.5-3.7,0.1
		c-3-0.9-5.9-2.1-8.9-3.2c0-0.1,0.1-0.3,0.1-0.4c-2.1,0-4.2,0-6.6,0c-1.5,1-3.2,2.2-4.9,3.4c-2.1,1.4-4.2,1.8-6.8,0.8
		c-4.6-1.8-8.7-0.5-11.7,3.4c-1,1.3-1.9,2.5-2.9,3.9c-3.1-0.2-5.3,0.7-6.9,3.4c-1.7,2.8-4.3,5.1-6.3,7.7c-3.4,4.5-6.2,9.3-5.9,15.3
		c0.9,1.2,1.8,2.3,2.6,3.2c-2.2,4.5,0.6,7.7,2.7,10.6c2.3,3.2,2.8,6.1,1.8,9.7c-0.3,1,0.2,2.2,0.4,3.2c5.9-1.8,10.8-2.9,16.1,0.7
		c2.5,1.7,5.8,3,9.5,2.6c4.3-0.4,8.7,0.2,13.1-0.1c6.3-0.4,11.9,1.7,17.5,4.7c0,2.3,0,4.4,0,6.6c-1,1.2-1.9,2.3-2.2,2.8
		c0,5.3,0,9.9,0,14.7c0.9,1.4,2,3.2,3.2,5.1c2.3-0.1,4.6-0.5,7-0.4c4.8,0.3,8.8,2.6,12.1,6c4.6,4.7,8.9,9.8,13.4,14.6
		c1.7,1.8,3.7,3.2,5.6,4.9c9.2-0.7,9.2-0.7,12.2-3.5c6,0.8,12-7.6,14.2-13.5c7.8-1.4,13.1-8.5,11.5-15.9c4.2,0.7,7.1,3.3,10,5.5
		c2.3,0.6,3.9-0.2,4.7-1.9c1.3-2.7,3.4-5.5,3.3-8.3c0-4-1.6-8-2.7-12c-0.2-0.6-1.2-1-2.2-1.7c0.2-2.2,0.7-4.6,0.7-6.9
		c0-4.4,3.1-8.5,0.3-12.9c2.7-1.6,5.2-3,7.6-4.5C1316.9,864.4,1320,859.2,1324.3,855.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1169.3,1502.2c2.2-4.8,6.7-5.3,11-5.7c2.1-2.6,0.8-4.4-0.9-6.1c-2.3-2.3-4.5-4.6-6.7-6.8
		c1.3-3.9,3.6-6.1,8.8-6.5c-5.2-3.3-10.2-5.3-12.6-10.2c-2.4-5.1-0.6-10,1-14.8c-0.4-0.9-0.6-1.6-1-2.4c-2-4.5-1.6-8.6,1.7-12.5
		c1.2-1.5,1.7-3.6,2.3-4.9c-2.1-4.2-5.3-3-8-3.3c-2.5,3.1-5.3,4.8-9.5,3.1c-2.6-1-5.6-0.7-8.4-1.2c-1.5-0.3-3.2-0.8-4.3-1.7
		c-2.3-1.9-4.7-2.6-7.9-2.1c-4.5,3.9-9.4,3.5-14.4,0.3c-2.7-1.7-5.7-1.7-8.8-1.1c-2,0.4-4.3,0.9-6.1,0.3c-4-1.3-7.5-0.5-10.9,1.2
		c-1.8,1.5-3.5,2.9-5.2,4.3c-5.5-2.7-10.3-5-15.1-7.4c-1.7,0.7-3.1,1.3-3.9,1.6c-2.3,2.6-5,4.3-5.8,6.7c-1.3,3.8-4.1,5-7.8,6.6
		c-4.4-2.9-8.9-6-13.3-8.9c-4.6,5.1-4.6,11.2-0.7,15.8c1.3,1.5,1.9,3.5,2.8,5.3c-0.2,0.2-0.4,0.3-0.6,0.5c1.5,0.9,3,1.7,4.4,2.8
		c5.4,4,10.3,8.3,9.9,15.9c-0.2,3.1,1.2,5.5,3.8,7.4c6.8,4.9,13.4,9.9,15.2,18.6c1.6,0.8,2.9,1.5,4.1,2.2c5.3,3.2,10.5,6.4,15.8,9.6
		c0.9,0.6,2.1,1.3,2.5,2.2c2,5.1,6,8.5,9.8,12.2c1.2,1.2,2.1,2.8,3,4.3c1.5,2.5,2.7,5.1,4.2,7.6c3.7,6.1,10.8,9,14.3,15.3
		c0.5,0.8,1.8,1.2,2.8,1.7c1.6,0.7,3.4,1.1,4.9,1.9c3.3,1.8,7.3,3,8.4,7.4c2.3-3.8,5.6-7.1,1.2-11.2c0.4-1.6,0.7-2.9,1.1-4.3
		c-1.1-2.6-2.8-5-2.8-7.9c0-0.2,0-0.4,0-0.7c0-0.4,0.1-0.9,0.3-1.4c1-1.1,1.9-2.2,2.7-3.1c0-2.6,0-4.8,0-7.6c1.4-1.3,3-2.8,4.6-4.3
		c0.7-0.7,1.6-1.3,1.8-2.1c0.6-2.6,2.8-3.3,4.7-4.5c0.9-0.5,1.8-1.1,2.9-1.8c-0.9-2.2-1.7-4-2.4-5.8
		C1162.8,1502.6,1166.2,1502.7,1169.3,1502.2z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1111.6,1534.4c-2.7-3.8-4.5-7.1-7-9.6c-2.8-2.7-5.5-5.3-7.4-8.8c-0.9-1.6-2.3-3.1-3.9-4.1
		c-5.3-3.3-10.7-6.4-16.1-9.4c-2.2-1.2-3.6-2.7-4.5-5.2c-2.3-6.2-6.9-10.9-12.5-14c-3.1-1.7-4.5-4.1-5.5-6.8c-0.8-2-0.7-4.4-1.1-6.5
		c-0.4-2.5-0.4-5-3.2-6.6c-1.1-0.6-1.6-2.3-2.3-3.3c-4.4-1.1-7.3-3.5-9-7.5c-0.6-1.4-1.4-2.8-2.2-4.1c-3.7-5.8-4.7-11.6-1.2-17.9
		c4-7.3,11.3-6.8,15.3-2c1.8,2.3,4,3.5,7.2,4c0.9-4.9,5.9-6.9,8.3-11.6c3.3-0.8,6.8-1.6,9-2.2c5.3,2.5,9.3,4.3,12.9,6
		c2.7-2.2,5.5-3.6,8.3-4.3c3.6-0.8,7.3-0.4,11.2,1.3c2.3-1.4,4.7-2.1,7.3-2c1.1,0,2.2,0.2,3.4,0.6c0.8,0.2,1.5,0.5,2.3,0.9
		c1.6,0.7,3.2,1.7,4.9,2.9c1.5-0.5,3.4-1.2,5.4-1.7c4-1,8-2.4,11.9,0c1.2,0.7,2.5,1.5,3.7,2.2c0-0.2,0.1-0.3,0.1-0.5
		c4,0.9,7.9,1.7,12.6,2.7c-2.2-2.5-2.8-4.7-1.4-7.6c1.7-0.6,3.7-1.2,5.7-1.9c-2.4-1.8-4.7-3.5-6.9-5.4c-1.5-1.3-3.3-2.6-3.9-4.3
		c-2-6.4-6.3-12.3-5.5-19.5c0.3-2.5-0.8-4.3-3-4.8c-5,2.4-9.9,4.5-14.5,7.1c-5.8,3.4-11.6,2.4-17-0.1c-3.8-1.8-7.4-2.9-11.5-2.5
		c-1.6,0.1-3.2,0-5,0c-1.9-2.1-3.8-4.1-6.3-6.9c-5.1-1.7-11-4.1-13.9-10.3c-6-4-11.8-7.8-17.3-11.5c-2.8,2-5.6,3.9-8.3,5.8
		c-2.3,1.7-4.7,3.3-6.9,5c-3,2.3-5.5,5.4-9.8,5.4c-0.9,0-1.8,0.9-2.9,1.5c1.5,2.2,2.8,4,4,5.9c1.7,2.7,1.8,7.6,0,9.5
		c-2.5,2.6-5.4,4.8-8.5,7.6c-0.5,5-0.4,10.9-2.6,16.1c-5.2,3.7-9.5,1.1-13.5-1.1c-8.2,5-12.2,4.2-17.6-3.9c-1.7,1.2-3.5,2.4-5.2,3.7
		c-3.2,2.6-6.7,4.2-10.9,4.1c-3-0.1-5.9,1.3-8.9,0c-0.9-0.3-2.3,0.1-3.2,0.5c-4.1,1.8-8,1.8-11.7-1.6c-0.3,3.2,0.6,5.4,2.3,7.5
		c2.6,3.1,5.1,6.4,4.6,9.6c2.1,5.8,3.8,10.4,5.7,15.4c1.1,0.7,2.5,1.6,4.1,2.6c2.7-0.9,5.4-1.8,7.7-2.6c1.1-3,2.4-5.3,2.7-7.8
		c0.6-5.5,1.1-10.8,4.9-14.2c2.6-1.3,4.6-2.3,6.6-3.3c1.6,2.3,4.9,3.1,6.2,7.3c-2.4-1.2-4-1.9-5.7-2.7c-4.1,7,2.4,9.5,5.5,13.6
		c1.3-0.9,2.6-1.8,4.3-3c-1.3-2.5-2.6-4.8-3.8-7.2c0.3-0.2,0.6-0.5,0.8-0.7c2.1,1.2,4.2,2.5,6.8,4c0,6.1,0,12.1,0,18.5
		c3.1,3.3,6.4,6.8,9.8,10.5c-1,0.9-1.8,1.5-2.3,2c-0.4,3.4,2.2,4.3,3.9,6.1c1.1-0.7,2-1.2,2.9-1.8c-0.2-1.2-0.4-2.2-0.6-3.1
		c0.3-0.1,0.6-0.3,0.9-0.4c1.1,2.2,2.2,4.3,3.5,6.7c-2,0.2-3.7,0.3-5.2,0.5c-2.2,1.9-3.1,4-2.3,6.5c2.6,4.4,6.1,7.7,11.3,8.8
		c0.3,0.1,0.6,0.3,0.8,0.5c3.6,2.6,7.2,5.2,10.8,7.6c1.6,1.1,3.3,2,5.1,2.6c3.2,0.9,5,3.4,5.4,6.1c0.4,3.4,2.6,5.2,4.4,7.9
		c9.5-4.1,17.7,0,26,3.5c0,0,0,0,0,0c0,0,0,0,0,0l-0.1-0.1c5.9,0.5,10.7,3.6,14.5,7.6c2.2,2.4,4.5,3.7,7.3,4.8
		c2.1,0.8,4.1,1.8,6.1,2.6C1108.5,1534.3,1109.5,1534.2,1111.6,1534.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1152.4,1195.6c0-1-0.1-1.9-0.1-2.7c-3.9-3.7-5.8-3.4-8,1.1c-0.3,0.6-0.4,1.2-0.7,1.8
		c-1.6,2.9-3.5,5.2-7.3,4.6c-0.6-0.1-1.4,0.5-2.1,0.7c-1.5,0.4-2.9,0.8-3.6,0.9c-2.9-2.5-5.2-4.4-7.2-6.2c-2.8,0.7-4.9,1.3-7.5,2
		c-1.5,5.6-3.7,11.1-9.9,13.8c-1.7,4.3-4,8.5-4.8,12.9c-0.9,4.7-3.8,5.8-7.5,6.7c-1.7,0.4-3.3,1-4.9,1.7c-1.6,0.7-3.2,1.5-4.7,2.2
		c-4.2-2.2-7.2-0.6-9.7,2.6c-1.6,2-3.1,4-4.7,6c0.6,4.5,0.4,8.7-2.1,12.6c-0.9,1.4-0.4,3.7-0.6,5c4.1,4.6,7.7,8.6,11.2,12.5
		c3-0.2,5.7-1.7,8.2,0.3c1.7,1.4,3.6,2.6,5.2,4.2c3,3,6.3,3.4,10.4,2.7c4.8-0.9,9.6-2.2,14.7-0.7c3,0.9,6.3,0.9,9.3,1.3
		c3.9-2.9,6.3-6,7-10.8c0.6-4.2,2.3-8.1,7.9-8c2.7,0,5.4-0.7,8.5-1.2c3.7-3.6,7.6-7.4,11.4-11.1c2.1,0,3.9,0,5.6,0
		c0.6,0,1.3,0.2,1.9,0.3c4.1,1,5.4,0.6,7.8-2.6c1.2-1.5,2.3-3.1,3.8-4.3c3.1-2.5,6.4-4.8,9.7-7.2c3-2.2,6.5-2.4,10-2.4
		c2,0,4.1,0.5,5.9,0.7c7.6-11.5,20.9-11,30,0.9c5.7-2.3,11.5-4.6,17-6.8c2.1-3.9-1.1-6.7-1.2-9.3c3.7-5.8,9.6-9.7,9.3-17.7
		c-3.3-0.8-6.3-1.8-9.4-2.1c-2.3-0.2-4.3-0.6-6.3-1.9c-5-3.1-9.6-6.9-15.7-7.8c-0.9-0.1-1.9-0.4-2.6-1c-1.7-1.3-3.2-1.2-4.8-0.1
		c-3.3,2.3-6.7,2.1-10.4,0.5c-2.4-1-5.1-1.3-7.7-1.9c-3.6,4-6.1,8.9-12,8.1c-1.2-1-2.3-1.9-3.1-2.6c-2.5,0-4.6,0-6.1,0
		c-4.8,4.3-9.2,8.2-13.7,12c-3.9,3.3-4.7,3.2-9.2,0.1c-0.3-1.5-0.7-3-1.1-4.6c-2.8,0.2-5.1-0.3-6.1-3.1
		C1152.4,1197.2,1152.4,1196.4,1152.4,1195.6z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M829.9,1362.1c3.5,1.5,6.5,0.5,9.3-1.7c0.7-8.7-0.9-14.4-4.4-14.9c-1.1,0.7-2.3,1.4-3.2,2c-1.5,0-2.6,0-3.7,0
		c-3.1,0.1-6,0-7.9-3.2c-0.6-1-2.5-1.3-3.9-1.6c-1.2-0.3-2.5-0.2-3.7-0.3c-1.3-2.3-2.6-4.3-3.5-6.4c-0.9-2.2-1.3-4.5-1.8-6.3
		c3-4.7,3-4.7,3.1-6.8c-1.1-2-1.9-3.5-2.8-4.9c-0.6-1-1.3-2.6-2.2-2.9c-4.7-1.4-7-5.7-11.1-8.2c-0.9,0.9-2,1.8-2.8,2.6
		c-2.4,0-4.5,0-6.5,0c-3-2.4-5.9-4.8-9-7.4c-2.3,2-4.2,3.6-6,5.3c-3.1,2.8-7,2.9-10.2,0.2c-5.8,3.4-11.8,5.2-17.5,0.6
		c-1.2,2.1-2.4,4.2-3.6,6.2c-2.2,0-3.8,0-5.2,0c-3.2,4.5-5.5,5.3-10.8,4.1c-1.9-0.5-3.8-0.9-5.1,1.2c0.7,2.6,1.3,5.1,2,8
		c-1.1,0.9-2,1.5-2.8,2.3c-4.3,4.1-8.6,8.2-12.8,12.5c-2.3,2.3-4.4,5-7.6,6.3c-1.2,0.5-2.1,1.9-2.7,2.4c-0.7,3.1-0.7,5.9-1.9,8
		c-1.3,2.2-3.7,3.7-5.5,5.4c0.6,5.7-0.2,10.6-4.4,14.4c-0.5,0.4-0.2,1.8-0.2,2.4c5.1,0.6,7.8-2.4,10.1-6.1c0.9-1.5,1.9-3.1,3.3-4.1
		c3.4-2.3,6.1-6.5,11.8-5.1c0.6,2,1.3,4.1,1.8,6.4c0.5,2.3,0.1,5.1,1.2,7c2.3,3.9,1.4,8.1,2,12.2c0.6,0.7,1.3,1.4,1.9,2.2
		c2.7,3.3,5,4.1,8,1.7c5.1-4.1,10.3-4.6,16-1.8c1.1,0.5,2.4,0.8,3.3,1.2c1.3-0.4,2.4-0.6,3.5-1.1c5.4-2.4,10.3-5.7,12.2-11.4
		c1.3-3.8,2.5-7.4,4.8-10.6c1.7-0.4,3.4-0.8,5.7-1.4c1.3,3.3,2.5,6.4,3.8,9.7c6.2,1.7,8,8.4,12.5,11.3c1.2,2.5,2.1,4.5,3.6,7.5
		c1.6-2.8,3-4.4,3.6-6.3c1-3.3,2.3-6.8,2.1-10.2c-0.4-7,6.4-10.4,7.5-16.6c0.1-0.3,1.1-0.5,1.9-0.8c4.7,1.6,5.2,6.9,8.3,10.4
		c3.4-1.1,6.9-2.1,9.9,0.4c3.6-0.7,6.9-1.3,10.4-1.9c-1.3-4.1-7.2-6.2-4.1-11.7C827.7,1362.2,829,1361.7,829.9,1362.1z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M764.8,840.9c2.2,9.1,4.4,17.8,6.7,27c-0.6,1.4-3.2,2.7-0.9,5.2c-3.9,2.6-3.5,6.9-4.7,10
		c2.5,7.4,6.6,12.9,13.7,15.9c-1,1.7-1.7,2.9-2.4,4.1c-0.6,1.3-1.2,2.6-1.6,3.5c0.3,2.4,0.6,4,0.6,5.5c0.1,2.4,0,4.8-0.1,7.2
		c-0.1,2.9,0.8,5.3,3.4,7.2c4-2.2,8.4-3.6,12.8-2.6c3.5,0.7,7,1.8,10.5,2.6c3.3,0.8,6,0,8.5-2c1.4-1.1,2.5-2.4,4.1-3.8
		c-1.1-4.5-2.1-9.2-3.3-13.9c2.9-2.2,5.2-5.2,5.5-8.6c0.3-3.3-0.9-6.7-1.5-10.3c3.9-1.7,7.5-3.5,9.7-7.3c0.7-1.1,2.7-1.4,4.3-2.2
		c0.2-0.4,0.3-1.5,0.8-1.7c4.7-1.8,4.1-6.2,4.1-9.7c0-2,0-3.9,0.6-5.6c1.6-0.9,3.1-2.4,4.6-2.3c3.6,0.1,6-2,8.2-3.7
		c1.8-3.5,1.6-6.7,0.8-8.4c-5.5-6.5-11-9.9-18.8-8.2c1.6-3.7,2.8-6.8,3.9-9.3c-0.5-5.2-5.3-8.7-3.5-14.1c5.2,1.9,7.6-1.6,10.2-4.8
		c-0.4-2-1-3.8-1.3-5.7c-0.9-5.5-1.4-10.8,2.5-15.9c1.9-2.6,4.8-5.8,3.4-9.9c-2.1-3.8-5.7-4.8-8.9-5.4c-3.9,2.5-7.3,4.6-10.5,6.6
		c-1,1.7-1.8,3.3-2.6,4.8c-2.6,0.4-5.1,1.1-6.6,2.7c-2,2.1-3.9,4.8-4.7,7.5c-0.8,2.5-2.4,3.6-4.1,5c-1.4,1.2-3.1,2.1-5.2,3.6
		c-1.9,0-4.5,0-7.1,0c-1.9,2.2-3.9,4-5.3,6.2c-1.4,2.2-2.2,4.9-3.3,7.4c-6.3-3.9-8.1-4-12.8-1.5c-2.1,4.8-0.2,9.7-0.3,14.6
		C771.1,834,767.9,837.5,764.8,840.9z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M628.9,1094.2c1.7,1.8,3.4,3.6,5.8,6.1c1,0.2,3.2,1.3,5.1,0.8c3.2-0.8,6.2-2.5,9.4-3.8c2.3,1.3,4.7,2.6,6,3.3
		c3.4-0.6,6-1.1,8.6-1.6c1.1,1.5,1.9,2.9,3,4c2.2,2.2,4.2,5,7.8,5c4.3,0,7.2,2.2,9.4,5.4c1.2,4.7-1.7,8.2-2.8,12.3
		c4.2,3.1,2.8,7.8,3.2,12.2c2,0,3.6,0,5.4,0c0.9-3.7,0.4-6.9-2.2-9.5c-1.5-1.5-2-3.2-1.2-5.2c2.5-2.2,4.9-4.3,7.6-6.6
		c0-2,0-4.1,0-6.3c0-1.6-0.2-3.3,0.3-4.8c1.1-3.1,0.7-5.9-0.5-8.8c-1.6-3.7-2.6-7.6-1.4-11.6c1.3-1.4,2.5-2.6,3.3-3.5
		c4.6-2.4,8.5-0.2,13.2,0.5c1.7-1.7,3.4-3.3,4.9-5.1c1.6-1.8,3.8-3.7,4.1-5.8c0.5-2.8,1.8-4.3,3.6-5.6c2.1-0.4,4-0.8,5.7-1.1
		c0.6-1.2,1.1-2.2,1.4-2.8c-0.8-4.3-4.3-5.8-6.9-8c-4.3-3.7-4.8-7-1.7-12c3.2-1.3,6.3-2.5,9.5-3.8c1.5-5.1,2.8-10.1,4.4-15.1
		c0.9-2.8,0.8-5.3-0.3-7.9c-1-2.5-1.8-5-3-8.5c-3.6-1-7.2-4-12.4-4.3c-6.2-0.3-12.1,1.8-18.3,1.5c-4.8-0.2-9.7,1.1-14.6,1.7
		c-1.4,2.5-3,4.6-5.2,5.8c-3.9,2.1-6.3,5.1-7.7,9.4c0.3,0.7,0.7,1.6,1.1,2.5c0.4,1,0.9,2,1.3,3.1c0.6,0.4,1.3,1,2,1.2
		c0.7,0.2,1.6,0.2,2.4,0.2c4.6,0.4,5.5,1.6,5.8,6.3c0.2,2.7,0.7,5.4,1.5,7.9c0.9,2.5,2.4,4.7,3.5,6.9c-1.1,1.9-1.3,4.2-4.3,4.4
		c-1.6-2.5-3.4-5.2-5.4-8.2c-3.9,1.3-6.4,5.4-10.6,5.6c-0.9-2.9-1.6-5.5-2.4-8.3c1.3-1.8,2.7-3.7,4.3-5.8c0-1,0.2-2.1,0-3.2
		c-0.3-1.4-0.9-2.7-1.4-4.1c-1.1-0.2-2.1-0.5-3.5-0.8c0.2-1.5,0.2-3,0.4-4.3c0.9-4.3-0.5-6.5-4.7-6.8c-1.1-0.1-2.2,0.1-3.4,0.2
		c-2.3,2.9-4.6,5.8-6.8,8.7c-2.3,3.1-4,6.5-3.7,10.6c0.4,4.6-0.6,8.8-3.5,12.7c-4.5,6.1-11,10.5-14.7,17.3c-0.1,0.3-0.4,0.5-0.7,0.7
		c-5.1,3.7-5.6,7.5-2,12.4C625.5,1084.7,625.2,1089.7,628.9,1094.2z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1042.8,1790.8c-3.3,1-7,0.6-10.7,0.8c-1.7,2.4-4.6,2.8-7.3,3.6c-2,0.6-4,1.1-5.9,1.8
		c-3.1,1.2-6.1,2.6-9.3,3.7c-0.8,0.3-2,0.4-2.8,0.1c-6.4-1.9-11.8-0.1-17.4,4.2c-1.9-1.3-3.7-2.6-6.1-4.2c-2.3,0.3-5.1,0.6-7.9,1
		c-2.2,0.4-3.8-0.4-5.4-2c-3.6-3.5-8.6-5-13-7.4c-0.7-0.4-1.9,0-2.8,0.1c-2.8,2.9-6.9,4.4-7.6,9c-0.1,0.8-1.9,1.4-2.4,1.7
		c-4.4-2-8.1-3.6-12.1-5.4c-4.3,4.7-4.3,9.6-2.5,14.6c-1.5,3.5-2.9,6.7-4.1,9.5c0.7,4.1,2.8,6.1,6.2,7.3c2.7,1,5.2,2.4,8.8,4.1
		c1.9-0.2,4.9-0.5,7.9-1c2.2-0.3,3.9,0.2,5.6,1.7c1.9,1.6,4.2,3.2,6.6,3.7c3.1,0.6,5.5,1.8,7.7,4c0.8,0.8,1.7,1.4,2.7,2
		c2.7,1.6,5.6,2.9,8.1,4.8c5.7,4.4,12,5.3,19,4.1c4.5,2.4,9.1,4.9,13.6,7.3c0.6,2.2,1.2,4.4,1.5,5.6c2.7,2.1,4.7,3.5,6.6,5.1
		c2.2,1.9,4.5,3.8,7.7,3.2c5.1-1,10.2-2.1,15.7-3.3c1.3-1.5,2.7-3.5,4.3-5.2c3.1-3.1,3.8-6.7,2.7-10.9c-0.3-1.2-0.3-2.5-0.4-3.8
		c-0.3-2.5-0.7-5-1.1-7.4c-3.4-1.5-6.5-2.7-9.8-4.2c0.2-5.1-0.2-10.5,0.9-15.5c1.1-4.7,2.7-9.4,5.9-13.4c1.6-2,3.1-4.3,3.6-6.7
		c0.7-3.3,1.8-6,4.1-8.4c2-2.1,1.7-3.2-0.5-6.6C1049.3,1788.7,1046.1,1789.9,1042.8,1790.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M581.8,1122.5c0.6,1.7,1.1,3,1.5,4.4c1,3.4,3.3,5.4,6.9,5.4c1.9,0,3.8-0.6,6.5-1c1.4,1.2,3.3,2.7,5.2,4.3
		c0,2.8,0,5.2,0,7.3c4.9,2.6,9.3,5.4,14,7.4c2.7,1.1,4.2,2.6,4.7,5.2c3,0.3,5.3,1,7.1,2.3c2.9,2.1,4.2,5.5,4.2,10.1
		c0,1.7-0.2,3.6-0.6,5.6v0c0,0,0,0,0,0c1.8,0.5,3.6,1,5.4,1.5c0.6,0.2,1.2,0.3,1.9,0.5c1.1-3.3,2.2-6.8,7.2-7.2
		c3.3,2.4,6,5.7,6.4,10.5c0.2,2.8,0.6,5.7,0.9,7.7c2.2,2,3.5,3.8,5.3,4.7c4.3,2.2,7.4,5.4,10.2,9.2c0.7,1,2.4,1.6,3.7,1.8
		c2,0.3,4,0,6.4-0.1c-0.6-5.5-1.1-10.4-1.6-15.4c1.3-1.6,2.3-3.1,2.6-4.7c0.8-4.8,2.6-9.1,6.3-12.5c0.9-0.2,2.3-0.2,3-0.8
		c3.1-3,6.5-2.9,10.8-1.1c0.4-2.1,0.6-3.9,0.8-5.2c-3.7-4.6-3.7-4.6-4-13.7c-2.1-1.5-4.3-3-6.4-4.6c-7.1-0.2-7.7-0.4-10.9-4
		c0-2.6,0-5.3,0-8.1c-0.9-1.1-1.9-2.2-2.5-2.9c-1.6-5.2,1.5-9.1,2.6-13.8c-3.9-1-7.6-1.9-12-3c-1.7-1.9-4-4.5-6.3-7.1
		c-1.5,1.2-2.7,2.1-4.4,3.5c-2.2-1.4-4.4-2.9-7-4.6c-1,0.4-2.5,0.6-3.6,1.3c-2.2,1.6-4.7,1.7-7.3,2c-3.3,0.3-5.8-1.6-8.8-1.9
		c-3.7-0.4-7.1-1.3-10.5-2.9c-3.2-1.6-6.5-2.2-9.2,0.7c-1.3-1.4-2.3-2.6-3.2-3.6c-2,0-3.6,0-5.2,0c-1.3,1.5-2.5,3-4,4.7
		c-2.6,0-5.5,0-7.2,0c-3.2,1.9-5.5,3.3-8.5,5.1C579.3,1113.1,584.1,1118.3,581.8,1122.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M815.1,1672c0.2-3.5-1.1-6.2-2.6-8c-0.5-4.8-0.9-8.8-1.3-12.5c-2.1-1.1-3.8-2-5.4-2.9c-1.6-4.2-4-7.6-8.1-9.3
		c-10.4,5.9-14.6,6.1-16.4,12.1c-2.9,1-6.2,1.7-9.1,3.1c-2.9,1.5-5.4,3.7-8.6,6.1c-1.2-2-2.1-3.4-2.8-4.9c-1.2-2.5-2.8-4.3-5.7-3.9
		c-2.9,1.5-4.9,4.2-4.3,6.9c0.7,3.1-1,5.2-1.2,8c0.5,1.5,0.7,3.8,1.9,4.6c3.5,2.3,4.9,5.9,6.4,8.9c-0.6,2.6-1.7,4.9-1.7,7.2
		c0,3.8,1,7.5,0,11.3c-0.1,0.6-0.2,1.6,0.1,1.9c2.5,2.1,1.4,5.1,1.9,8c1.8,1.5,3.7,3.1,6,4.9c-2.3,3.2-4.3,5.8-6.2,8.6
		c-2.7,4.1-4.9,8.6-3.8,13.5c0.8,3.7,0.9,6.8-0.7,10.2c-1.5-0.1-3.4-1.6-4.2,1c2.7,4,5.4,8,8.5,12.5c0.4,0,1.6,0,2.9,0
		c0.1-1.7,0.2-3.1,0.2-4.8c1.3,0.1,2.3,0.1,3.6,0.2c-0.2,6.6,3.7,9.6,8.1,11.1c3.1-1.4,5.3-2.5,7.7-3.3c2.9-1,5.2-2.5,6-5.6
		c0.8-3.3,1.6-6.6,2.6-10.6c1.4,0.9,2.8,1.6,4,2.5c4,3.1,8.2,4,12.6,0.8c0.5-0.4,1-0.7,1.8-1.3c0.6-2.6,2.2-5.6,1.7-8.1
		c-1.4-7.2,0-14,1.4-20.8c0.7-3.3,2.9-6.4,2.8-10c-0.2-5.2-0.4-10.5-0.6-16.6c1.7-2.5,3.9-5.7,6.2-9.2c0.7-2.5-0.2-4.8-1.7-7
		C816.2,1675.2,815,1673.5,815.1,1672z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1239.9,1684.7c-1.1-4-0.2-8,3.6-10.6c2.5-1.7,4.9-3.5,7.8-5.5c0.1-0.4,0.7-1.3,0.7-2.2
		c0.3-6.7,4.1-12.3,6.5-18.2c1.8-4.4,3.7-8.2,0.4-13.3c-3.5-1.6-6.9-4-9.7-7.5c-3.4-4.3-7.7-7.6-10.7-12.6
		c-2.6-4.3-3.5-8.2-2.5-12.6c0.9-3.7,0.9-7.1-0.3-10.8c-0.5-1.7-0.4-3.7-0.3-5.5c0.3-5.6,0.4-11.2,0-15.9c-2.4-1.7-4.6-2.4-5.3-3.8
		c-1.8-3.9-5.3-5.8-8.2-8.4c-3.9,0.7-7.4,0.4-10.1-3.4c-1.4,1.2-2.6,2.1-3.8,3.1c-3.2,2.5-6.6,1.6-8.1-2c-0.3-0.8-0.5-1.6-0.9-2.8
		c-3.9,4.3-5.4,8.8-5.1,14c0.3,5.6,0.7,11.2-2.7,16.3c-0.4,0.7-0.1,1.9-0.1,3c1.3,1,2.7,2.1,3.7,2.9c0,2.3,0.4,3.9-0.1,5.1
		c-1.8,4.3-0.4,7.8,2,10.5c-1.1,2.7-2,4.9-2.8,6.9c0.2,0.9,0.3,1.3,0.5,1.8c0.8,2.7,2.3,5.2,0.7,8.3c-0.6,1.2,0.3,3.6,1.2,4.9
		c2.2,2.7,2.9,5.6,2.3,8.9c-0.9,1.3-1.7,2.7-2.7,4.1c0.3,1.1,0.5,2.1,0.8,3.1c-0.7,1.5-1.4,2.9-2.2,4.8c2.4,3.8,4.9,7.6,7.2,11.1
		c-0.5,1.4-1.2,2.4-1,3.1c0.7,2.7,1.7,5.4,2.6,8.1c1.4,0.8,2.7,1.7,4.1,2.5c4.3,2.4,8.4,5.2,11.7,8.9c2.8,3.2,5.3,6.8,7.6,10.4
		c1.9,3,4.2,5.1,8,5.9c1-1.5,2-3,3.2-4.5C1239.8,1690.2,1240.8,1688,1239.9,1684.7z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1329.6,1567.4c-3.9-1.8-7.7-3.6-11.5-5.4c-1.3-1.5-2.6-3.5-4.3-4c-3.5-1-6.1-3.1-7.9-4.9
		c-2.9-0.4-5.4,0-6.9-1.1c-3.1-2.3-5.7-2.1-8.7-0.1c-1,0.7-2.4,0.9-3.6,1.2c-3.8,0.9-6.6,3.3-9.4,6c-3.4,3.2-7.2,5.5-12.8,3.9
		c-7.3-2.1-15.1,3.1-17.5,10.3c-0.7,2-1.6,3.8-3.2,5.2c-2,1.7-3.4,3.7-3.3,6.6c0,1.2-0.6,2.4-0.9,3.5c2.2,4,3.9,7.8,2.1,12.4
		c-1.4,3.6-1,7.1,1.1,10.5c1.5,2.4,3,4.9,4.4,7.2c3.5,2.5,7.1,4.7,8.3,8.4c2.8,1.8,5,3.3,7.3,4.8c4.4-3.5,9.2-5,14.7-5.2
		c2-0.1,4.2-1.3,6-2.4c3.1-1.9,5.9-4.1,9.6-6.7c1.5-4.4,4.1-7.8,8.5-9.4c2.6-1,5.5-1.5,8.3-1.7c7.2-0.4,12.7-3.8,17.3-9.1
		c1.8-2.1,4.1-3.8,6.5-6c0.2-1,0.6-2.4,0.8-3.4C1328.3,1581.9,1330.2,1574.5,1329.6,1567.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M954.6,1380.1c2.8,3.8,1.5,6.9,0.9,9.9c0.8,1.4,1.5,2.6,2.4,4.2c-0.4,2.1-0.8,4.4-1.2,6.7
		c1.6,1.1,3.1,2.2,4.3,3.1c2.1,4.5-2,6.1-3.6,8.7c1.7,2.6,4,1.8,5.7,1c2.6-1.3,5-0.9,7.8-0.7c5.1,0.3,10,0,14.5-4.3
		c5.5-5.4,14-4.7,15.2,2.7c0,0.3,0.4,0.5,1,1.3c2.8-1.3,5.7-2.7,8.6-4.1c1.5,0.7,3,1.4,4.4,2c1.5,0.7,3,1.3,4.8,2.1
		c0.2-2.9-0.1-5.3,0.6-7.3c0.7-2.1,1-4,1.3-6.2c0.2-1.7,2.2-3.3,3.5-4.8c2.2-2.4,5.8-3.4,6.5-6.4c-2.4-4.2-4.3-7.4-5.9-10.1
		c1.2-4.5,3.1-7,7.3-7.5c3.2-0.4,5.9-2.2,8.1-4.7c1.9-2.1,4.1-3.7,7-4.9c3-1.2,5.3-3.8,8.5-6.2c-2.7-3.4-5-6.3-6.7-8.5
		c-9.4,0.9-15.7,5.8-21.1,12.7c-4.3-1.2-8.4-2.1-12.3-3.6c-2.6-1-4.4-0.8-6.6,0.8c-2.1,1.6-4.2,3.3-7.3,3.3c-1.3,0-3,1.4-3.7,2.7
		c-2.6,4.7-7,5.7-11.7,5.9c-5.6,0.3-10.6-1.8-15-3.6c-5.3,0.8-10,1.4-14.5,2c-1.2,2-2.2,4-3.5,5.8c-1.3,1.9-2.6,3.7-1.6,6.3
		C953.1,1379.1,954.3,1379.9,954.6,1380.1z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1183.3,889.7c-2.2,0.4-4.8-0.2-7.1-0.9c-3.2-0.9-6.2-2.3-10.3-3.9c-3.4,0.7-7.8,1.5-12.4,2.5
		c-0.3,2.3-0.5,4.7-0.8,7.1c-0.9,0.1-1.9,0-2.8,0.3c-1,0.3-2,1-3,1.5c-8.2-1.9-8.5-2.4-11-0.7c-3.1,2.1-7.6,1.5-9.7,5.3
		c-1.8,3.3-3.4,6.8-5.2,10.5c1.3,1.6,1.3,3.9,4.2,4.7c2.2,0.6,4.1,2.4,6,3.8c0.5,0.4,0.6,1.3,0.9,2.1c-3.3,1.4-6.3,2.7-9.5,4
		c9.3,2.6,17.8-0.8,26.5-1.7c5.9-0.6,11.4-0.6,17,0.9c2.3,0.6,4.3,0.3,6.5-0.5c4-1.5,8.1-2.7,12.2-3.9c2.1-0.6,4.3-1.2,6.4-1.7
		c1.8-0.4,3.7-1.1,5.6-1.1c3.1-0.1,6.3,0.2,9.5,0.3c-0.7-1.5-1.6-3.2-2.4-4.8c0.5-2.7,1-5.4,1.6-8.5c-4.3-4.6-0.2-8.5,1.8-12.8
		C1199.5,888.4,1191.6,888.2,1183.3,889.7z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1187.5,1562.3c1.8-6.2,4-12.2,10.4-15.9c1.8,0,3.6,0,5.7,0c0.9,1.4,1.9,2.8,3.3,4.9c0.7-1.8,1.4-3.2,1.7-4.6
		c1-5.6,2.7-10.8,7.7-14.4c0.7-0.5,0.9-1.5,1.3-2.2c-3.2-3.3-6.3-5.7-10.9-2.9c-1.5-1.4-2.9-2.7-4.2-4c-5.2-1.8-10.1-4.4-15.2-6.5
		c-5.5-2.4-7-8.6-11.2-11.9c-2.3,1.7-4.3,3.2-6.4,4.8c0.9,2.4,2.3,5-0.2,7.1c-2,1.7-4.5,2.9-6.8,4.3c0.1,0.1,0.2,0.2,0.4,0.2
		c-1.7,1.9-3.4,3.8-5.8,6.5c0.1,3.6,0.2,8.3-2.6,11.2c0.7,4.2,0.9,7.6,1.8,10.7c1.3,4.3,0.9,8.1-1.3,12c-0.6,1.1-0.6,2.5-1,4.3
		c2.8,0,4.8,0,6.6,0c3.2,4.7,4.2,5.1,13,5.9c1,2.6,1.5,5.8,3.3,8c1.9,2.3,5,3.6,7.7,5.4c0.5-2.5,1-4.6,1.5-6.8c1-4.5,3.1-8.8,1-13.6
		C1187.1,1564,1187.2,1563,1187.5,1562.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1525.1,1873.8c-3,2.2-6,4.8-9.5,6.7c-3.8,2-8,3.1-12,4.6c-2.8-7.5-4.3-8.6-10.6-8c-1.3,1.1-2.6,2.3-3.7,3.4
		c-4.4,0.3-15.5-1.2-19.1-2.2c-1.4-0.4-3.5-0.6-4.6,0.1c-3.6,2.3-7.5,2.6-11.5,2.2c-1.2-0.1-2.5,0.2-3.6,0.3
		c-0.8,1.1-1.5,1.9-1.8,2.3c-4.7,0.5-8.8,0.9-12.7,1.3c-3.6-8.1-5-8.6-10.7-3.4c-3.1,0-6.6,0-10.9,0c-0.2-0.1-1.1-0.7-2-1.2
		c-2,0.9-3.9,1.7-6,2.6c0.6,2.4,1.2,4.6,1.9,7.3c-2,1.9-2.2,4.4-1.2,7.4c0.4,0.3,1,0.6,1.5,1c3.9,3.2,8.1,5,13.3,3
		c1.1-0.4,2.5,0,3.8,0.2c1.6,0.2,3.1,0.5,4.7,0.7c2.5,0.4,4.9,0.8,8.5,1.4c1.2-0.5,3.6-1.4,5.4-2.1c4.3,0.3,8.1,0.6,11.9,0.9
		c1.1,1.6,2.1,3,3.1,4.5c3.6,0.7,7.1,1.4,9.7,1.9c2.6-1.4,4.4-2.3,6.2-3.3c3.1-1.8,7.1-1.8,9.6-5c0.4-0.5,2.1-0.1,3.1-0.1
		c1.5,0.1,4,0.8,4.5,0.2c1.4-2.1,3.2,0.2,4.7-1.1c1.2-1.1,3.2-1.8,4.8-1.9c1.2-0.1,2.4-0.3,3.5-0.6c3.3-1,6.2-2.9,9.3-4.4
		c4.3,0.9,9.5-1.1,12.2-4.8c0.4-0.5,0.7-1.1,1-1.7c0.8-1.8,1.2-4.2,0.7-6C1527.6,1878,1528.6,1874.9,1525.1,1873.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M805.6,1551.8c-1.1-1.5-2.7-2.6-4.4-4.2c-2.3,5-1.3,10.9-5.5,13.6c-2.6-0.4-4.8-1.5-6.4-0.9
		c-3.1,1.2-6.2,2.9-8.6,5.1c-2.7,2.5-5.7,4.1-9.1,5.6c-3.2,1.4-6.1,3.8-5.8,7.9c0.2,4.4,0.8,8.8,1.3,13.7c0.7,0.7,1.6,1.8,2.5,2.8
		c0,1.6,0,3,0,4.6c0.9,1,1.9,2,2.8,3.1c-0.3,0.9-0.6,1.6-0.8,2.2c1.3,2.3,2.5,4.6,3.3,5.9c-0.2,2.8-0.4,4.6-0.5,6.3
		c0.9,0.6,1.4,1,1.9,1.4c0.6,0.4,1.3,0.9,1.8,1.2c0.4,4.9,0.5,4.9,3.4,8.4c1.3,1.5,2.2,3.5,3.6,5.8c0.6,0.2,1.5,0.6,2.4,0.9
		c4.5,1.2,10-2.3,11.1-6.8c0.8-3.2,2.2-6.2,3.2-9.3c0.7-2.3,1.7-4.6,1.6-6.9c-0.2-4.8,0.3-9.3,3.8-12.6c0-5.1-0.4-9.8,1.8-14.5
		c1.4-3,1-6.4-0.6-9.5c-3-5.5-3.4-11.5-1.6-17.4C807.6,1555.4,806.9,1553.6,805.6,1551.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M851,898c1.1,1.2,1.8,2.4,2.8,3.1c2.8,2.2,4.1,6.2,8,7.1c4.7,1.1,7.6,3.9,9.6,8.2c1.1,2.4,2.7,4.3,4.9,5.1
		c0.3,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.8,0.2,1.2,0.2c0,0,0.1,0,0.1,0c0.8,0.1,1.6,0,2.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.4,0.3-0.9,0.7-1.3,1c-1.3,1-2.6,2.1-3.7,2.9c-1.7-0.5-2.8-1.1-3.8-1c-4.6,0.4-9,0-12.8-2.9c-0.2-0.1-0.6,0-1.2,0.1
		c-1,1.1-2.2,2.4-3.1,3.4c0,2.6,0,4.7,0,7.4c4.4,2.6,8.9,5.4,13.9,8.3c2.2-0.5,4.8-1,7.7-1.6c1.2,0.5,2.6,1.1,4,1.7
		c7.3-2.8,11.3-7.9,11.8-16.2c-0.9-1.2-2.1-2.8-3.1-4.1c-2.2,0-3.8,0-5.4,0l0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0-0.1,0
		c0.3-0.6,0.5-1.2,0.8-1.9c0.3-0.6,0.5-1.3,0.8-1.9c6.5-0.3,6.4-0.4,8.3-6.7c0.4-1.5,1.4-2.9,2.5-4c1.5-1.4,2.6-2.9,2.1-5
		c-0.5-2.3-1.2-4.6-1.8-6.8c-0.5-1.6-1-3.2-1.5-4.6c7.9-5.3,10-18.5,1.4-30.3c-4.2,0.8-8.6,1.6-13.6,2.6c-0.8,2.7-2.7,5.8-2.3,8.6
		c0.5,3.8-1,6.3-3.2,9.8c0-6.7-5.3-9.4-7.4-14.4c-2.8,0.7-5.3,1.2-7.6,2c-1.3,0.5-2.5,1.5-3.3,2.6c-2.4,3.1-4.5,6.4-7.1,10.1
		C854.9,886.5,850.4,892.3,851,898z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M550.7,1702.7c-0.6-4.5-0.9-9.3-6.4-11.2c-3.5,1-6.8,1.8-9.8,4.5c-2.6,2.4-5.9,4-9.5,5.2
		c-2.6,0.8-4.9,2.7-7.3,4.3c-0.8,0.5-1.3,1.7-2.1,1.9c-3.1,0.9-3.8,3.5-4.9,6.2c0.9,1.1,1.8,2.1,3,3.5c3.2,0,6.7,0,10.3,0
		c1.7,2.2,3.4,4.5,5.6,7.4c0.6,0.1,2,0.2,3.4,0.4c0.2-0.2,0.5-0.4,0.7-0.6c1.5,2.2,2.9,4.3,4,5.8c13.2-1.1,18.2-9.3,20.6-20.4
		C557.7,1705.5,554.2,1704.2,550.7,1702.7z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1429.9,1754.4c2-2.7,2.6-5.6,0.5-8.1c-1.9-2.3-4.3-4.3-8-2.8c-2.3,0.9-4.5,0.3-6-2.3c-1-1.8-2.7-3.1-3.8-4.3
		c-1.2-5.4-2.2-10.3-3.2-14.5c-3.3-1.8-5.8-3.2-8.5-4.7c-3.2,1.1-6.5,2.3-9.5,3.4c-2.3-2.6-5.2-3.5-8.3-4.3
		c-2.7-0.7-6.2-0.3-7.5-3.3c-1.8-4-5.1-5.3-9.3-6.6c-2.7,2.2-5.6,4.6-8.2,6.7c0,2.4,0,4.3,0,6.9c2.2-0.1,4.2-0.2,6.1-0.4
		c6.8,3.8,13.7,7.6,20.4,11.3c0.4,1.7,0.6,2.9,1,4.7c2.9,0.4,5.8,1.1,8.6,1c2.9-0.1,5.8-1.1,8.6-1.6c3.4,8,8.2,14.5,16.1,18.2
		c0.9,0.4,2,1,2.7,0.8C1424.3,1753.2,1426.9,1754.9,1429.9,1754.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1078.3,751.2c-1.7-0.2-4-1.2-5.6-0.5c-3.3,1.5-6.6,3.4-9.2,5.9c-3.2,3-5.9,6.7-8.5,10.3
		c-1.6,2.2-3.1,4.6-3.9,7.1c-0.6,1.8-0.8,4.5,0.1,5.9c1.1,1.5,1.3,2.8,1.4,4.3c0.4,6.3,0.7,12.6,1,18.5c1.4,2.7,2.6,5,4,7.6
		c1.3,0.1,2.7,0.2,4,0.3c3.9-4.1,7-8.2,7.4-13.9c0-0.8,0.2-1.7,0.7-2.3c2.7-3.3,2.9-7.6,4.6-11.3c2-4.5,2.5-9,1.3-13.9
		c-0.6-2.3-0.7-4.7-1-6.7C1076.9,759,1079.7,756.1,1078.3,751.2z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M919.8,205c2.8-0.7,5.3-0.7,7.8,1.2c-0.3,1.2-0.6,2.4-0.9,3.5c0.8,1.5,1.5,2.9,2.6,4.9
		c1.8-0.4,3.7-0.9,5.6-1.4c0.7-0.2,1.5-0.8,2.2-0.8c2.5,0.2,4.1-1.2,6-2.5c3.5-2.2,5.1-6,7.4-9.1c0.7-1,0.1-3,0.1-4
		c-1.3-1.5-2.3-2.5-3.1-3.4c-7.2-1.4-7.2-1.4-10.5,2.8c-0.9-0.4-1.8-0.9-2.8-1.4c1.1-1.7,2-3.2,2.3-3.8c0.7-5.2,1.3-9.4,1.9-13.6
		c0.2,0,0.4,0,0.5,0.1c-0.4-1.8-0.9-3.6-1.4-5.7c-1.8,0.5-3.4,0.9-4.6,1.2c-4.5,4.9-7.9,10-9.7,16c-0.1,0.4-0.5,0.8-0.9,1.1
		c-3.8,3.2-7.7,6.4-11.6,9.6c-0.2-0.2-0.5-0.3-0.7-0.5c-0.4,1.8-0.7,3.5-1.2,5.5C912.9,206.1,916.3,205.9,919.8,205z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M844,897.2c-3.8-2.1-7.7-3.8-9.4-8.1c-10.4-0.4-16.1,6.1-15.3,17.3c3.7,3.9,7.6,8.1,11.9,12.6
		c1.2,0.1,3.3-0.3,5,0.4c3,1.2,5.2,0.3,7.6-1.4c1.2-0.8,2.5-1.4,3.8-2.1c3.3-1.9,4.6-5.6,3.8-9.4C850.5,902.1,847.6,899.2,844,897.2
		z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1154,709.6c-2.2-0.2-4,0.8-5.3,2.3c-1.4,6.2,1.2,11,4.2,15.7c1.4,0.6,2.7,1.1,4.1,1.6c0.1,2,0.2,3.9,0.3,5.7
		c4.2,2.4,6,1.8,7.5-2.1c0.5-1.2,0.7-2.5,1.3-3.5c1.4-2.1,2-4.2,0.7-6.5c6.3-5.2,12.4-10.1,18.2-14.9c0-2.4,0-4.3,0-6.1
		c-4.1-3-8.2-3.5-12.4-1.4c-4.4,2.2-8.8,4.5-13,7.1C1157.9,708.8,1156.3,709.8,1154,709.6z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M357.8,716.3c3.3-0.5,6.1-0.9,9.2-1.3c1.9-3,3.5-6.7,6.1-9.3c3.6-3.5,4.8-8.1,6.9-12.3c1.1-2,1.2-3.9-0.5-5.9
		c-1.7,0-3.4,0-5.1,0c0.1,0.2,0.2,0.4,0.3,0.5c-5,2.6-9.9,5.2-14.7,7.8c-4.1-2.5-4.1-2.5-6.8,0.5c0,6.1,0,11.7,0,17.6
		c-0.8,1.5-1.8,3.4-2.8,5.4c1.1,0.9,1.8,1.4,2.6,2C355.3,720.3,358,720.1,357.8,716.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M367,727.8c-3.1,0.7-6.8,1-10.1,2.4c-2.2,1-3.8,3.4-5.5,5.4c-1.6,2-0.4,4,0.8,5.7c0.6,0.9,1.7,1.4,2.3,2.2
		c1.4,2,3,4,3.9,6.3c1.1,2.4,1.5,5.1,2.1,7.2c3.2,1.7,6,2.5,8.2,2.5c1.6,0,3-0.5,4.1-1.5c0.8-0.7,1.5-1.7,2-2.9
		c-0.9-0.8-1.9-1.4-2.7-2.2c-0.3-0.3-0.6-0.5-0.8-0.8c-1.1-1.3-2-2.8-2.9-4.1c0.9-2.1,1.7-4,2.5-5.9
		C373.6,736.1,372.6,732.3,367,727.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M694.6,1182.3c-0.4-3.3-1.1-6.6-1.6-9.8c-2.2,0.7-4.2,1.3-6.2,2c-0.7,3.4-1.4,7-2.3,10.4
		c-0.4,1.3-1.4,2.4-2.4,3.9c0.5,4.1,1,8.5,1.6,12.9c3.1,1.6,8.6,2.4,13.9,1.9c4-3.3,4.9-7.9,5-12.4c-1.7-1.4-2.8-2.7-4.3-3.4
		C695.7,1186.8,694.9,1184.9,694.6,1182.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1022.5,805.6c1.2-5.6,2.1-11.2,3-16.8c0.2-1-0.2-2.1-0.5-4.1c-1.5,0.9-2.9,1.3-3.3,2.2
		c-1.3,2.5-4.4,4.5-3.4,7.9c0.7,2.3,0.3,4.4-0.8,6.4c-0.4,0.8-0.6,1.8-0.8,2.7c-0.8,3.4-1.4,6.8-2.3,10.2
		c-1.4,5.6-3.6,11.2-2.4,16.9c0.6,3.1,0.1,5.9,0,8.9c-0.1,1.4-0.8,2.8-1,4.2c-0.3,1.7,0.5,2.4,2.2,2c4-5.6,5.2-11.9,4.5-18.6
		c-0.1-1.1-0.2-2.3,0.1-3.4C1019.3,818,1021.1,811.8,1022.5,805.6z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1175.2,678.9c-3.3-0.8-6.3-1.7-9.5-2.3c-2.2-0.4-4.5,0-6.3,1.7c-1.3,1.3-2.5,2.7-4.2,4.5
		c-0.4,5.9,4.6,8.7,8.5,12.2c2.6,0,5,0,7.1,0c1.6-1.3,2.9-2.4,4.6-3.8C1175.3,687.4,1178.4,683.3,1175.2,678.9z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M527.5,631.7c1.9,0.8,3.5,0.4,4.7-1.2c1.2-1.7,2.7-3.3,3.2-5.2c1.1-4.2,1.6-8.6,2.5-13.1
		c-1.4-0.6-2.8-1.3-4.5-2.1c2.2-1.8,4.2-3.6,6-5.1c2-0.1,3.6-0.2,5.3-0.3c1.3-2.4,3.4-4.5,1-7.2c-2.9-1.2-4.3,0.4-5.3,2.5
		c-3.2-0.3-5.6,0.7-8.3,2.2c-2.8,1.6-5.6,2.4-4.2,6.3c1.5,1.2,2.9,2.2,4.7,3.6c-1.8,2.2-3.5,4.1-5.2,6.2c0,2.1,0,4.3,0,6.6
		C527.2,627.1,525.4,629.2,527.5,631.7z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1059.2,77.5c0-1.1,0-2.3,0-3.5c-1.5-1.4-2.9-2.8-4.3-4c-3.5,0.2-5.1,1-8.2,3.4c-2.2,1.7-4.7,3.1-7.2,4.4
		c-2.8,1.4-4.8,3.9-4.4,6.2c0.4,2.2,3.2,4.1,6.2,4.4c2.8,0.3,4.8-1.4,7.1-2.4c2.3-0.9,4.1-3.1,6.9-2.6
		C1055.1,80.5,1058.4,79.9,1059.2,77.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M335.4,750.2c0-0.4,0.4-0.8,0.6-1.3c3.6-7.1,7.2-14.3,11-21.8c-2.7-1.5-2.5-5.7-6.3-6.9
		c-1.4,2.1-2.8,4.3-4.1,6.4c-0.5-0.3-1.1-0.6-1.6-0.9c0.7,2.5,1.4,5,2.3,8.4c-0.4,0.7-1.2,2-2,3.3c-0.9,1.6-2.3,3.1-2.6,4.9
		c-0.8,4.5-2.5,8.5-6.2,11.6c0.5,1.5,1,2.9,1.4,4.2C332.5,756.9,335.4,754.8,335.4,750.2z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M465.1,683.1c0.1,1.7,0.2,3.3,0.3,5.3c2.3,1.2,4.7,2.4,7,3.6c4.1-2.3,5.3-5.2,4.1-10c-0.7-2.9-1.4-5.8-2.2-9
		c-3-2.9-4.2-3.5-7.6-3.8c-1.4,3.3-6,5-5.2,9.9C462.6,680.3,463.9,681.7,465.1,683.1z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M359.3,815.4c-1.2,1.5-2.3,2.8-2.8,3.4c-4.7-0.3-8.4-0.5-11-0.6c-5.9,4.7-2,9.5-2.3,14
		c4.4,2.1,6.7,1.6,9.3-2.1c0.5-0.6,1-1.3,1.3-2c1.8-4.2,5.8-5.3,9.5-7c0-1.8,0-3.7,0-5.7C361.6,815.4,360.4,815.4,359.3,815.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M576.6,1690.9c-1,1.2-1.9,2.5-2.8,3.6c1.2,6.5,3.2,7.9,12.3,9c4.2,3.7,4.7,3.7,7.9-0.3
		C593.9,1695,586.4,1689.2,576.6,1690.9z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M477.3,1728.4c-3.1,1.5-6.4,3.1-9.7,4.8c-1,0.5-1.8,1.3-2.6,2c-3.4,3-3.5,4.6-0.9,8.4c2.3,0.5,4.5,0.9,6.5,1.3
		c8.7-4.3,12.3-8.9,11.3-14.7C480.6,1729.6,479.1,1729.1,477.3,1728.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1230.7,1703c-2.9-2-4-4.4-3.9-7.2c-1.9-2.3-3.5-4.2-5.3-6.3c-1.8,0.2-3.6,0.4-5.4,0.6
		c-0.9,1.7-1.7,3.1-2.5,4.6c1.2,1.5,2.1,3.4,3.6,4.3c2.8,1.7,4.4,4.4,6.4,6.8c2.3,2.8,5.1,5.5,9.6,5.1
		C1234.6,1707.2,1233,1704.5,1230.7,1703z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M966.4,898.2c-1.2,1.6-2.3,2.9-3.1,4c2.7,10.8,5.2,12.7,16.7,12.8c0.8-0.7,1.8-1.5,2.7-2.3
		c-0.2-1.7-0.5-3.2-0.8-5.3C976.1,906.4,971.9,901.5,966.4,898.2z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M377.3,924.6c3.9-3.1,6.9-6.4,8.8-10.4c0.6-1.3,1.1-2.6,1.5-4c0.4-1.7-0.4-3.1-1.8-4.1c-1.5-1.1-3-0.9-4.3,0.4
		c-1.2,1.2-2.6,2.2-3.5,3.6c-2.7,3.6-5.1,7.4-8,11.6c0.9,1,1.9,2.2,2.6,3C374.4,924.6,375.9,924.6,377.3,924.6z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1273.1,1769.5c-4.2-3.8-7.9-7.9-14.9-7.8c-0.8,0.3-2.7,1.1-4.8,2c-0.3,6.8,1.5,8.8,7.3,9.3
		c3.7,0.3,7.4,1.2,11.2,1.8C1274.1,1773.4,1273.5,1771.3,1273.1,1769.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M366.7,961c1.3,2.3,2.5,4.5,4,7.2c1.5,0.9,3.5,2.1,5.8,3.5c1.9-1.3,3.7-2.5,4.8-3.3c1.3-4-0.1-6.6-1.7-9
		c-1.2-1.8-2.7-3.3-4.5-5.3c-1,0-2.6,0-4.2,0C368.1,955.6,367.5,958.5,366.7,961z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1460.4,1767.9c-2.9-3-3.5-3.3-9.3-3.7c-1.3-2.4-2.3-4.9-3.9-7c-1.6-2.1-3.8-3.6-5.4-5.2
		c-2.5-0.5-4.6-0.9-6.7-1.3c-3,5.5-0.9,8.3,7.2,9c4.3,5.3,8.9,10.8,17,11.3C1459.6,1769.8,1460,1768.8,1460.4,1767.9z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1428.6,1646.6c2,3.3,2,3.3,7.4,6.2c1.8-0.1,3.7-0.3,5.8-0.5c1.4-2.1,2.9-4.3,4.3-6.4c-0.6-1.3-1-2.4-1.5-3.5
		C1438,1640.1,1432.3,1640.3,1428.6,1646.6z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M620.6,1098.9c3.6,1.1,4.9,0.3,6.4-3.7c-4.3-3-8.6-6.1-13.3-9.4c-2.1-0.5-3.8,0.6-5.2,3.2
		c0.5,1.7,1,3.7,1.7,6.2C613.9,1096.5,617.2,1097.8,620.6,1098.9z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1381.1,1642.9c-1.9,2-5.4-1.5-6.8,2.2c2.6,6.3,5.4,9.4,12.5,13.7c1.1-1,2.1-2,2.7-2.5
		C1391.2,1648.6,1384.4,1647.3,1381.1,1642.9z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1400.8,1610c-0.1,1.4-0.2,2.8-0.3,4.1c1.9,2.3,3.6,4.4,5.3,6.5c2.5-0.8,4.7-1.4,7.5-2.3
		c0.6-1.4,1.1-2.7,1.8-4.4c-1.6-2.2-3.2-4.5-5.1-7.1C1406.9,1607.9,1404,1608.9,1400.8,1610z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M923.5,218.6c0-0.6,0.1-0.9,0.1-1.3c0-0.5-0.1-0.9-0.2-1.4c-5.2-2.8-10.1-1.4-12.6,3.9
		c-1.3,2.7-2.5,5.4-3.7,8.2c0.6,1,1.1,1.9,2.1,3.6C914.4,227.5,917.9,221.9,923.5,218.6z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1086.8,636c0.3-1.5,0.6-3.1,0.9-4.8c0.9-0.7,1.7-1.4,2.8-2.2c-1.7-5.1-3.9-6.2-8.9-4.9
		c0.3,1.6,0.5,3.2,0.8,4.9c-3.1,0.3-6.7-0.5-7.6,3.6C1077.7,636.3,1081.8,637.5,1086.8,636z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1474.4,1784.8c-6.3,1.8-6.4,8.3-4.1,13.8c1.7-0.2,3.4-0.3,5.3-0.5C1481.8,1792.3,1481.3,1788,1474.4,1784.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M989.4,1445.3c0-0.9-1-1.9-1.4-2.7c-1.1,0-1.8,0-2.6,0c-3,4.2-1.6,8.2,1,11.5c2,2.6,3,5.1,3.1,8.2
		c0,1.5,0.3,2.9,2.4,3.1c0.4-0.4,1.1-0.8,1.2-1.3c0.5-3,0.9-5.9-0.4-8.9C991.2,1452,989.3,1449,989.4,1445.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1417.5,1709.9c2.6,0,5.2,0,7.8,0c0.5-1.3,1-2.4,1.5-3.5c-1.1-1.6-2.1-3.1-3.1-4.6c-3.3-0.9-6.2-4.3-10.2-1.1
		C1412,1704.9,1415,1707.2,1417.5,1709.9z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1391.6,1633.4c-2.1-1.3-4.5-2.3-7.2-3.6c-0.5,1.1-1.1,2.3-1.8,3.8c5.5,4.2,9.5,10,18.4,9.5
		c-0.7-1.9-0.8-3.5-1.7-4.3C1396.9,1636.7,1394.2,1635,1391.6,1633.4z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1267.9,1781.6c-0.9,1.7-1.7,3.3-2.3,4.6c3.8,3.7,7.2,7,10.5,10.2c5.1-2.7,2.6-6.2,2-9.4
		C1274.8,1785.2,1271.6,1783.5,1267.9,1781.6z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1449.2,1622c0.9-1.8,2-3.9,3-5.9c-1.1-0.7-1.5-1.2-2.1-1.5c-4.4-2.5-5.3-2.3-9,1.4c-0.7,0.7-1.8,1-2.7,1.5
		C1439.1,1623.4,1442.7,1624.9,1449.2,1622z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1360.1,1655.3c3.3,2.8,6.9,4.3,10.9,5c2.9,0.5,4.2-0.5,5-4c-4.7-2.2-9.3-4.4-13.9-6.6
		C1359.9,1651.6,1359.9,1651.6,1360.1,1655.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M893.2,236.2c2.4-1.5,4.9-3.1,7.8-4.9c-0.1-0.3-0.4-1.3-0.7-2.2c0.8-1.6,1.6-3.1,2.6-5c-2.4,0-3.9,0-5.8,0
		c-0.9,0.7-2,1.6-3.3,2.6c0.1,0.8,0.3,1.7,0.5,2.6c-3.3,1.5-3.2,4.6-3.5,7.7c1.4,0.6,2.7,1.1,4,1.6
		C894.2,237.9,893.7,237,893.2,236.2z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1093.1,1531.5c-1.2-1.1-2-2.1-2.9-2.6c-5-2.5-10.6-1.9-15.8-2.9c-0.7-0.1-1.7,0.9-3.2,1.9
		c5.1,2.9,10.1,3.7,14.7,5.5C1087.8,1534,1090.2,1533.6,1093.1,1531.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1257.8,1745.5c0.6,0.8,0.9,2,1.3,3c1.5,0.4,2.8,0.7,4.5,1.1c0.7-1.3,1.5-2.7,2.1-3.8c-2.2-6.1-2.2-6.1-7-9
		c-1.2,2.3-4.2,3.3-3.4,6.7C1256.1,1744.1,1257.1,1744.6,1257.8,1745.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1010.8,1480.5c-0.4-0.9-1.4-1.5-2.1-2.3c-3.6,2.9-0.4,5.4-0.5,7.8c4.6,3.6,8.1,8.1,13.9,9.8
		c0.7-2.3-0.4-3.4-1.8-4.3C1016.2,1488.7,1012.9,1485.1,1010.8,1480.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1381,1850.2c-1-0.6-2-1.1-2.8-1.6c-1.6,0.6-2.9,1.2-4.2,1.7c-0.4,1.2-0.8,2.4-1.2,3.7c1,1.9,1.2,4.6,4.7,5.4
		c1.1-0.7,2.5-1.7,3.8-2.5c0-1.3,0-2.3,0-3.2C1381.1,1852.5,1381,1851.4,1381,1850.2z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1069.1,1520.9c4.3,4.1,10.5,5.2,15.3,2.4c-1.6-1.5-3.2-2.9-4.7-4.3c-2.4,0-4.8,0-7.2,0
		C1071,1519,1069.5,1519,1069.1,1520.9z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1465.3,1796.2c0.2-6.2,0.2-6.2-3.1-7.7c-1.6,1.7-2.9,3.1-4.5,4.8c0.5,1.5,1,3.1,1.4,4.5
		C1462,1798.7,1463.9,1798,1465.3,1796.2z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1437.8,1819.3c0.5-3.2-0.3-6.3-2.1-6.3c-0.5,1-1.2,2.2-1.9,3.4c-1,0.2-2.1,0.4-3.4,0.6
		c-0.7,1.5-1.4,3-2.2,4.8c1.4,0.7,2.3,1.1,2.5,1.2C1433.3,1821.6,1435.3,1820.5,1437.8,1819.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1095.1,1540.5c2.8,1,5,0.4,7.5-0.8c-5.4-2.6-10.7-2.7-19.7-0.7c0,0.4,0,0.7,0,1.1c1.7,0,3.3,0.1,5,0.1
		C1090.3,1540.3,1093,1539.8,1095.1,1540.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1424.6,1770.3c-1.2-0.7-2.6-1.4-4.8-2.5c-0.9,2.9-1.6,5.1-2.3,7.2c1.3,1.2,2.2,2.1,3.3,3.1
		C1423.4,1776,1424.9,1773.9,1424.6,1770.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M470.7,1750.3c-0.6,2-1.2,4.2-2,6.9c5.1-0.1,7.5-1.7,8.9-5.9C475.5,1750.5,473.6,1749,470.7,1750.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M837,1558c-2.9-0.3-5.9-0.1-8.9-0.1c-0.1,0.4-0.3,0.9-0.4,1.3c1.8,1.2,3.5,2.4,4.7,3.2c2.7,0,4.5,0,6.5,0
		C839.7,1560.2,839.6,1558.3,837,1558z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1495.8,1796.6c-4.9,0.8-7.6,3.8-7.8,8.9C1492.8,1804.7,1496.1,1800.8,1495.8,1796.6z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M843.7,930c-0.4,1.5,0.1,2.7,1.5,3.5c4-2.9,4.6-6.9,4.7-11.4C846.4,923.9,844.5,926.5,843.7,930z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M503.5,1557.5c0.2-1.5,0.3-2.8,0.5-4.2c-3.4-0.7-6.4-2.3-9.4,0C497.1,1555.5,499.5,1558,503.5,1557.5z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1447.6,1780.8c0.9-0.8,1.9-1.7,2.5-2.2c-0.3-2.5-0.4-3.9-0.7-5.9c-1.8,0.4-3.3,0.7-5.4,1.1
		C1445.6,1776.4,1444,1779.3,1447.6,1780.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1427.5,1784.3c0.7-2.2,0-3.1-2.8-4.4c-2.7,1.5-1.9,3.9-1.8,6.1C1425.2,1787.1,1426.8,1786.6,1427.5,1784.3z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M856,945.8c1.8,4.8,2.6,5.2,6.3,2.9C860.7,944.3,859.1,943.5,856,945.8z"
        />
        <path
          style={{ fill: colors.mapFill }}
          d="M1384.5,1771.4c-0.4,0.8-0.8,1.7-1.5,3.1c1.8,0.9,3.3,1.7,4.9,2.5c0.8-1.4,1.4-2.3,1.9-3.1
		C1388.6,1771.7,1387.1,1770.7,1384.5,1771.4z"
        />
        <polygon
          style={{ fill: colors.mapFill }}
          points="320.4,917.4 320,917.5 320.3,917.7"
        />
      </g>
    </svg>
  </div>
);
